<template>
  <div class="about-container">
    <div class="inner">
      <div class="title-area">
        <div class="title">
          <span v-for="(line, _i) in content.title" :key="_i">{{ line }}</span>
        </div>
        <div class="underliner" />
      </div>
      <div class="description-area">
        <article>
          {{ content.description }}
        </article>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .about-container {
    padding: 120px 0;
    background-image: linear-gradient(-25deg, #54BCDF, #FAE191);
    color: #fff;
  }
  .about-container .inner {
    max-width: 1100px;
    margin: auto;
    display: flex;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .about-container .inner > * {
    flex: 1;
  }
  .about-container .inner .title-area {
    padding: 0 25px 0 0;
  }
  .about-container .inner .title-area .title {
    font-weight: 900;
    fill: #fff;
    text-align: right;
    font-size: 4em;
  }
  @media screen and (max-width: 600px) {
    .about-container .inner {
      display: block;
    }
    .about-container .inner .title-area .title {
      font-size: 3.5em;

    }
  }
  @media screen and (max-width: 4540px) {
    .about-container .inner .title-area .title {
      font-size: 3em;

    }
  }
  .about-container .inner .title-area .title > span {
    display: block;
    line-height: 115%;
  }
  .about-container .inner .title-area .underliner {
    float: right;
    height: 6px;
    width: 105px;
    background-color: #fff;
    margin-top: 15px;
  }
  .about-container .inner .description-area {
    font-size: 1.65em;
    padding: 0 0 0 25px;
    letter-spacing: 0.02em;
    line-height: 140%;
  }
  @media screen and (max-width: 600px) {
    .about-container .inner .description-area {
      margin: 80px 0 0 0;
      text-align: right;
      font-size: 1.55em;
    }
  }
  @media screen and (max-width: 450px) {
    .about-container .inner .description-area {
      font-size: 1.5em;
    }
  }
</style>

<script>
  import content from '@/../content/purpose.yaml'
  export default {
    name: "AboutContainer",
    data() {
      return {
        content: content['about-message']
      }
    }
  }
</script>
