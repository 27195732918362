<template>
  <div class="about-us-container">
    <div class="header">
      <div class="title">
        <span>{{ content.title }}</span>
      </div>
      <div class="underliner" />
    </div>
    <div class="body">
      <div class="title-image">
        <img :src="content['image-1']" alt="About us">
      </div>
      <div class="article-grid">
        <div class="grid-1">
          <svg style="transform: translateX(-80px);" width="100%" height="100%" viewBox="0 0 579 593" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/">
            <g style="fill: #DADADA;">
              <path d="M320.721,592.265c-61.293,0 -116.35,-12.651 -165.171,-37.952c-48.821,-25.301 -86.951,-60.402 -114.391,-105.303c-27.439,-44.901 -41.159,-95.86 -41.159,-152.877c-0,-57.018 13.72,-107.977 41.159,-152.878c27.44,-44.9 65.57,-80.002 114.391,-105.303c48.821,-25.301 103.878,-37.952 165.171,-37.952c60.581,0 113.5,12.651 158.757,37.952c45.258,25.301 78.221,61.472 98.889,108.511l-129.357,69.489c-29.934,-52.741 -73.053,-79.111 -129.358,-79.111c-43.475,0 -79.467,14.254 -107.976,42.763c-28.509,28.508 -42.763,67.351 -42.763,116.529c0,49.177 14.254,88.02 42.763,116.528c28.509,28.509 64.501,42.763 107.976,42.763c57.017,0 100.136,-26.37 129.358,-79.111l129.357,70.559c-20.668,45.613 -53.631,81.249 -98.889,106.907c-45.257,25.657 -98.176,38.486 -158.757,38.486Z" />
            </g>
          </svg>
        </div>
        <div class="grid-2">
          <article>
            <p v-for="(paragraph, _i) in content.description" :key="_i">
              {{ paragraph }}
            </p>
          </article>
        </div>
        <div class="grid-3">
          <div class="simple-image">
            <img :src="content['image-2']" alt="About us">
          </div>
        </div>
        <div class="grid-4">
          <div class="fixed-image image-3">
            <div class="fixed-image-wrapper" :style="{ 'background-image': `url(${ content['image-3'] })` }" />
          </div>
        </div>
        <div class="grid-5">
          <article>
            <p v-for="(paragraph, _i) in content['description-2']" :key="_i">
              <span v-if="paragraph.markdown" v-html="marked(paragraph.text)"></span>
              <span v-else>
                {{ paragraph.text }}
              </span>
            </p>
          </article>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
  .about-us-container {

  }
  .about-us-container .header {
    display: inline-block;
    width: 100%;
  }
  .about-us-container .underliner {
    height: 60px;
    background-image: linear-gradient(to left, #F0BDC6, #FAE191);
    float: left;
    border-radius: 0 15px 15px 0;
    width: calc((100% - 1000px) / 2 + 370px);
    transform: translateY(-28px);
    z-index: 0;
    position: relative;
  }
  @media screen and (max-width: 1000px) {
    .about-us-container .underliner {
      width: calc(390px);
      max-width: calc(100% - 50px);

    }
  }
  .about-us-container .header .title {
    max-width: 1000px;
    margin: auto;
    padding: 0 0 0 50px;
    text-align: left;
    z-index: 1;
    position: relative;
    font-weight: 900;
    color: #4D4D4D;
    font-size: 4em;

  }

  @media screen and (max-width: 450px) {
    .about-us-container .header .title {
      font-size: 3.6em;
      padding-left: 30px;
    }
  }
  @media screen and (max-width: 400px) {
    .about-us-container .header .title {
      font-size: 3.3em;
      padding-left: 30px;
    }
  }
  @media screen and (max-width: 350px) {
    .about-us-container .header .title {
      font-size: 3em;
      padding-left: 20px;
    }
  }
  @media screen and (max-width: 300px) {
    .about-us-container .header .title {
      font-size: 2.7em;
      padding-left: 20px;
    }
  }
  .about-us-container .header .title > span {
    display: block;
  }
</style>

<style scoped>
  .about-us-container .body {
    max-width: 1000px;
    margin: auto;
  }
  .about-us-container .body .title-image {
    max-width: 100%;
  }
  .about-us-container .body .title-image > img {
    width: 100%;
  }

  .about-us-container .body .article-grid {
    display: grid;
    width: 100%;
    font-size: 1.2em;
    grid-template-columns: auto 150px 50%;
    grid-auto-rows: minmax(min-content, max-content) 60px 100px auto 120px minmax(min-content, max-content) minmax(min-content, max-content);
  }


  .about-us-container .body .article-grid .grid-1 {
    grid-column: 1 / span 2;
    grid-row: 1 / span 3;
    position: relative;
  }
  .about-us-container .body .article-grid .grid-1 > svg {
    width: 500px;
    height: auto;
    position: absolute;
    bottom: 0;
  }
  .about-us-container .body .article-grid .grid-2 {
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
    z-index: 3;
  }
  @media screen and (max-width: 1000px) {
    .about-us-container .body .article-grid .grid-2 {
      grid-column: 2 / span 2;
    }
  }
  @media screen and (max-width: 550px) {
    .about-us-container .body .article-grid .grid-2 {
      grid-column: 1 / span 3;
    }
  }
  .about-us-container .body .article-grid .grid-2 > article {
    padding: 30px 100px 50px 30px;
  }
  @media screen and (max-width: 550px) {
    .about-us-container .body .article-grid .grid-2 > article {
      padding-right: 30px;
    }
  }
  .about-us-container .body .article-grid .grid-3 {
    grid-column: 2 / span 2;
    grid-row: 2 / span 3;
    z-index: 2;
    padding: 0 20px;
  }
  @media screen and (max-width: 550px) {
    .about-us-container .body .article-grid .grid-3 {
      grid-column: 1 / span 3;
    }
  }
  .about-us-container .body .article-grid .grid-4 {
    grid-column: 1 / span 2;
    grid-row: 6 / span 1;
    box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 30px;
  }
  @media screen and (max-width: 500px) {
    .about-us-container .body .article-grid .grid-4 {
      grid-column: 1 / span 3;
    }
  }
  .about-us-container .body .article-grid .grid-4 > * {
    max-width: 385px;
    margin: auto;
  }
  .about-us-container .body .article-grid .grid-5 {
    grid-column: 3 / span 1;
    grid-row: 6 / span 1;
    padding: 30px 30px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 500px) {
    .about-us-container .body .article-grid .grid-5 {
      grid-row: 7 / span 1;
      grid-column: 1 / span 3;
      padding: 30px 50px;
    }
  }

  .about-us-container .body .article-grid .simple-image > img {
    width: 100%;
    border-radius: 15px;
  }
  @media screen and (max-width: 550px) {
    .about-us-container .body .article-grid .simple-image {
      position: relative;
      left: -20%;
    }
  }
  .about-us-container .body .article-grid .fixed-image {
    width: 100%;
    height: 100%;
  }
  .about-us-container .body .article-grid .fixed-image .fixed-image-wrapper {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
  }


  .about-us-container .body .article-grid .image-3 .fixed-image-wrapper {
    height: 600px;
  }
  @media screen and (max-width: 500px) {
    .about-us-container .body .article-grid .image-3 .fixed-image-wrapper {
      height: 500px;
    }

  }
</style>


<script>
  import marked from 'marked'
  import content from '@/../content/connect.yaml'


  export default {
    name: "AboutUs",
    methods: {
      marked
    },
    data() {
      return {
        content
      }
    }
  }
</script>
