<template>
  <div class="story-wrapper" ref="wrapper">
    <div class="story-cover">
      <div class="cover-item cover-left">
        <div class="image">
          <img :src="content.background" alt="Robots">
        </div>
        <div class="gradient">
          <div class="title">
            <span class="bold">{{ content.title[0] }}</span>
            <span>{{ content.title[1] }}</span>
          </div>
        </div>
      </div>
      <div class="cover-item cover-right">

      </div>
    </div>
    <div class="story-content" ref="storyContentContainer">
      <div class="cover-space" :style="{ width: offsetLeft + 'px' }" />
      <div class="content content-politics" ref="content">


        <div class="inner">

          <div class="part part-fixed">
            <div class="image-display">
              <div class="image-container">
                <iframe class="video-frame" :src="content['item-1'].video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="about-container">
                <div class="description">
                  <p>
                    {{ content['item-1']['info-1'] }}
                  </p>
                </div>
                <div class="info">
                  <div class="value">
                    {{ content['item-1']['info-2'].streams }}
                  </div>
                  <div class="label">
                    {{ content['item-1']['info-2'].label }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="part flex flex-h">
            <article class="text-top article-contrary">
              <p v-html="content['paragraph-1']"></p>
            </article>
            <article class="text-bottom article-contrary">
              {{ content['paragraph-2'] }}
            </article>
          </div>

          <div class="part">
            <div class="portrait-display">
              <div class="portrait-inner">
                <div class="portrait-bg"></div>
                <div class="video-wrapper">
                  <iframe src="https://player.vimeo.com/video/545499961" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>


            </div>
          </div>


          <div class="part part-4">
            <div class="title-area">
              <div class="title-text">
                <span>
                  <strong>{{ content['more-label'][0] }}</strong>
                </span>
                <span>
                  {{ content['more-label'][1] }}
                </span>
              </div>
            </div>
          </div>

          <div class="part">
            <div class="gallery-symmetric gallery-quadro gallery-videos">
              <div class="item">
                <iframe class="video-frame" :src="content.videos[0]" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="item">
                <iframe class="video-frame" :src="content.videos[1]" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="item">
                <iframe class="video-frame" :src="content.videos[2]" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="item">
                <iframe class="video-frame" :src="content.videos[3]" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>

        </div>

      </div>
      <div class="cover-space" :style="{ width: offsetRight + 'px' }" />
    </div>
  </div>
</template>


<style scoped>
  .content.content-politics {
    width: 4300px;

  }
  @media screen and (max-width: 600px) {
    .content.content-politics {
      width: calc(300vw + 100vw + 2 * 400px - 0px);
    }
  }
  @media screen and (max-width: 350px) {
    .content.content-politics {
      width: calc(300vw + 100vw + 2 * 400px + 60px);
    }
  }
  @media screen and (max-width: 500px) {
    .content.content-politics {

    }
  }
  @media screen and (max-width: 450px) {
    .content.content-politics {

    }
  }
  @media screen and (max-width: 375px) {
    .content.content-politics {

    }
  }
  .content .part article {
    max-width: 400px;
  }
  @media screen and (max-width: 500px) {
    .content .part article {
      max-width: 300px;
    }
  }

  .picture-1 > img {
    max-height: 300px;
  }
  @media screen and (max-height: 700px) {
    .picture-1 > img {
      max-height: 200px;
    }
  }
  @media screen and (max-width: 600px) {
    .picture-1 > img {
      max-height: 200px;
      max-width: 320px;
    }
  }


</style>

<style scoped>
  @import "./story.css";


  .gradient {
    background-image: linear-gradient(rgba(250, 225, 145, 0.7), rgba(240, 147, 161, 0.7)) !important;
  }




  .portrait-display {
    padding: 30px 0 0 50px;
    box-sizing: border-box;

  }
  .portrait-display .portrait-inner {
    position: relative;
    --height: 700px;
    --ratio: 0.79;
    width: calc(var(--height) * var(--ratio));
    height: var(--height);
  }
  .portrait-display .portrait-inner .portrait-bg {
    background-image: linear-gradient(#FAE191, #F0BDC6);
    border-radius: 38px;
    width: 100%;
    height: 100%;
    position: absolute;
    transform-origin: right bottom;
    transform: rotate(-4deg) translate(-5px, -5px) scale(1.075);
  }
  .portrait-display .portrait-inner .portrait-bg {

  }
  .portrait-display .portrait-inner .video-wrapper {
    width: 100%;
    position: absolute;
    height: 100%;
    border-radius: 40px;
    overflow: hidden;
    background-color: #0ff;

  }
  .portrait-display .portrait-inner .video-wrapper > iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  @media screen and (max-height: 800px) {
    .portrait-display .portrait-inner {
      --height: calc(100vh - 100px);
      --ratio: 0.79;
      height: var(--height);
      width: calc(var(--height) * var(--ratio));
    }
  }
</style>


<style scoped>
  .part-4 {
    display: inline-block;
    height: 100vh !important;
    padding-top: 0;
    padding-bottom: 0;
  }
  .part .title-area {
    height: 100%;
    background-image: linear-gradient(-135deg, #FAE191, #F0BDC6);
    position: relative;
    width: 800px;
  }
  @media screen and (max-width: 600px) {
    .part .title-area {
      width: calc(100vw - 50px);
    }
  }
  .part .title-area .title-text {
    position: absolute;
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    font-size: 4em;
    font-weight: 500;
    color: #fff;
    left: 50px;
    bottom: 100px;
  }
  @media screen and (max-width: 600px) {
    .part .title-area .title-text {
      font-size: 3em;
    }
  }
  .part .title-area .title-text > span {
    display: block;

  }
  .part .title-area .title-text > span strong {
    font-weight: 900;
  }


  .gallery-videos {
    margin-left: -400px;
  }
  @media screen and (max-width: 600px) {
    .gallery-videos {
      margin-left: calc(-100vw + 200px);
    }

  }
</style>

<style scoped>
  .gallery-symmetric {

  }
  .gallery-symmetric.gallery-quadro {
    display: grid;
    --width: 600px;
    --ratio: 0.5;
    --height: calc(var(--width) * var(--ratio));
    --space: 20px;
    grid-template-columns: var(--width) var(--space) var(--width);
    grid-template-rows: var(--height) var(--space) var(--height);
  }
  .gallery-symmetric.gallery-quadro .item > * {
    width: 100%;
    height: 100%;
    grid-column-end: span 1;
    grid-row-end: span 1;
  }
  .gallery-symmetric.gallery-quadro .item:nth-child(1) {
    grid-column-start: 1;
    grid-row-start: 1;
  }
  .gallery-symmetric.gallery-quadro .item:nth-child(2) {
    grid-column-start: 3;
    grid-row-start: 1;
  }
  .gallery-symmetric.gallery-quadro .item:nth-child(3) {
    grid-column-start: 1;
    grid-row-start: 3;
  }
  .gallery-symmetric.gallery-quadro .item:nth-child(4) {
    grid-column-start: 3;
    grid-row-start: 3;
  }


  @media screen and (max-width: 600px) {
    .gallery-symmetric.gallery-quadro {
      display: grid;
      --width: 400px;
      --space: 15px;
    }
  }
  @media screen and (max-width: 450px) {
    .gallery-symmetric.gallery-quadro {
      display: grid;
      --width: 350px;
      --space: 15px;
    }
  }

</style>

<script>
  import content from '@/../content/story/story-politics.yaml'


  function bindBounds(app, refId, storageKey) {
    app[storageKey][refId] = app.$refs[refId].getBoundingClientRect();
    window.addEventListener("resize", () => {
      if (app.$refs[refId]) {
        app[storageKey][refId] = app.$refs[refId].getBoundingClientRect();
      }
    });
  }

  export default {
    name: "StoryMovies",
    computed: {
      showFirstCover() {
        return this.scroll - this.offsetRight >= this.endPos;
      },
      startPos() {
        return this.offsetLeft;
      },
      endPos() {
        const offset = this.bounds.content ? (this.bounds.content.width - this.bounds.wrapper.width) : 0;
        return -offset - this.offsetRight;
      }
    },
    mounted() {
      bindBounds(this, 'wrapper', 'bounds');
      bindBounds(this, 'content', 'bounds');
    },
    data() {
      return {
        content,
        bounds: {},
        offsetLeft: window.innerWidth < 600 ? 250 : 400,
        offsetRight: 0
      }
    }
  }
</script>
