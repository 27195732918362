<template>
  <div class="wrapper" @mousedown="applyClick">
    <slot></slot>
  </div>
</template>


<style scoped>

</style>

<script>
  export default {
    name: "TransitionModifier",
    props: {
      name: String,
      keyName: String,
      timeout: Number
    },
    setup() {

    },
    created() {

    },
    methods: {
      applyClick() {
        if (this.name != "null") {
          this.$root[this.keyName] = this.name;
        }
        else {
          this.$root[this.keyName] = null;
        }

        if (this.timeout > 0) {
          setTimeout(() => {
            this.$root[this.keyName] = null;
          }, this.timeout);
        }
      }
    },
    data() {
      return {

      }
    }
  }
</script>
