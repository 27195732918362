<template>
  <div class="mobile-nav-main" :class="{ fullscreen }" @touchmove="$event.preventDefault();">
    <div class="nav-wrapper" :class="{ show: showNav }">
      <div class="inner">
        <ul class="nav-list" ref="navList">
          <li v-for="(item, index) in nav" :key="index" :class="{ active: activeNavDescriptor == item }">
            <div class="label">
              <router-link :to="item.href">{{ item.label }}</router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="nav-btns">
      <div class="btn" @click="showNav = !showNav">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 40" xml:space="preserve">
          <g style="fill: none; stroke: #fff; stroke-width: 3px; stroke-linecap: round;" :class="{ close: showNav }">
            <g>
              <g style="transform-origin: 20px 9px;">
                <line x1="3" x2="37" y1="9" y2="9" />
              </g>
            </g>
            <g>
              <g style="transform-origin: 20px 20px;">
                <line x1="3" x2="37" y1="20" y2="20" />
              </g>

            </g>
            <g>
              <g style="transform-origin: 20px 31px;">
                <line x1="3" x2="37" y1="31" y2="31" />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .mobile-nav-main {
    width: 100%;
    display: grid;
    height: 100%;
    grid-template-columns: auto 100px;
    grid-template-rows: 100px auto;
  }
  .mobile-nav-main.fullscreen {
    height: 100vh;
  }
  .mobile-nav-main .nav-wrapper {
    grid-row: 1 / span 1;
    grid-column: 1 / span 2;
    z-index: 0;

  }
  .mobile-nav-main.fullscreen .nav-wrapper {
    grid-row: 1 / span 2;
    z-index: 44;
  }
  .mobile-nav-main .nav-wrapper.show {
    z-index: 44;
    grid-row: 1 / span 2;
  }
  .mobile-nav-main .nav-wrapper .inner {
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 1);
    clip-path: circle(0vw at calc(100% - 55px) 50px);
    transition: all 0.75s;
  }
  .mobile-nav-main .nav-wrapper.show .inner {
    clip-path: circle(max(50vh, 130vw, 130vh) at calc(100% - 55px) 50px);
    opacity: 1;
  }
  /*.mobile-nav-main .nav-wrapper.show .inner {
    clip-path: circle(250px at calc(55px) 50px);
    opacity: 1;
  }*/
  .mobile-nav-main .nav-btns {
    height: 100%;
    font-size: 0;
    grid-row: 1 / span 1;
    grid-column: 2 / span 1;
    text-align: right;
    padding: 25px 30px 0 0;
    z-index: 55;
    box-sizing: border-box;
  }
  .mobile-nav-main .nav-btns .btn {
    width: 40px;
    box-sizing: border-box;
    display: inline-block;
    cursor: pointer;
    height: 40px;
  }
  .nav-btns .btn > svg > g > g > g,
  .nav-btns .btn > svg > g > g {
    transition: all 0.5s;
    transform: scale(1) translate(0, 0);
  }
  .nav-btns .btn > svg g.close path {

  }
  .nav-btns .btn > svg g.close g:nth-child(1) > g {
    /*transform: rotate(-45deg) translate(0, 13px);*/
    transform: rotate(-45deg);
  }
  .nav-btns .btn > svg g.close g:nth-child(1) {
    transform: translateY(10px);
  }
  .nav-btns .btn > svg g.close g:nth-child(2) > g {
    opacity: 0;
    transform: scale(0);
  }
  .nav-btns .btn > svg g.close g:nth-child(3) > g {
    /*transform: rotate(45deg) translate(0, -13px);*/
    transform: rotate(45deg);
  }
  .nav-btns .btn > svg g.close g:nth-child(3) {
    transform: translateY(-12px);
  }


  .nav-list {
    margin: 0;
    padding: 0;
    list-style: none;
    color: #fff;
    padding: 40px;
  }
  .nav-list li {
    font-weight: 300;
    margin: 20px 0;
    font-size: 2em;
  }
  .nav-list li.active {
    font-weight: 600;
  }
  .nav-list a {
    color: inherit;
    text-decoration: inherit;

  }
</style>

<script>
  export default {
    name: "MobileNavigation",
    props: ['nav'],
    computed: {
      activeNavDescriptor() {
        return this.nav.find(record => record.name.includes(this.$route.name));
      },
    },
    data() {
      return {
        fullscreen: false,
        showNav: false
      }
    },
    watch: {
      showNav(val) {
        if (val) {
          this.fullscreen = true;
        }
        else {
          setTimeout(() => {
            this.fullscreen = false;
          }, 500);
        }
      },
      $route() {
        // console.log(this.$route);
        this.showNav = false;
      }
    }
  }
</script>
