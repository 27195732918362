<template>
  <div class="flynice-banner">
    <div class="inner">
      <div class="header">
        <div class="info-link">
          {{ content.subtitle }}
        </div>
      </div>
      <div class="banner-content">
        <div class="area-left">
          <img :src="content.image" alt="Airplane">
        </div>
        <div class="area-right">
          <div class="area-inner">
            <div class="title">
              {{ content.text }}
            </div>
            <div class="btn-container">
              <router-link :to="content.button.href">
                <button class="btn">
                  {{ content.button.label }}
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="info-label">
          *Spaßangebot
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .flynice-banner {
    padding: 60px 0 30px;
    background-image: linear-gradient(to left, #58C8ED, #A4E1F5);
  }
  .flynice-banner .inner {
    max-width: 1200px;
    margin: auto;
  }
  .flynice-banner .inner .header {
    text-align: right;
    padding: 0 20px;
  }
  .flynice-banner .inner .header .info-link {
    font-size: 2.7em;
    color: #fff;
    font-weight: 800;
  }
  .flynice-banner .inner .banner-content {
    background-color: transparent;
    color: #fff;
    display: flex;
  }
  @media screen and (max-width: 500px) {
    .flynice-banner .inner .banner-content {
      display: block;
    }
  }
  .flynice-banner .inner .banner-content .area-left {
    flex: 1;
    padding: 0 0 0 70px;
    text-align: left;
  }
  @media screen and (max-width: 500px) {
    .flynice-banner .inner .banner-content .area-left {
      text-align: center;
    }
  }
  .flynice-banner .inner .banner-content .area-left:before {
    height: 100%;
    width: 0;
    content: "";
  }
  .flynice-banner .inner .banner-content .area-left:before,
  .flynice-banner .inner .banner-content .area-left > img {
    display: inline-block;
    vertical-align: middle;
  }
  .flynice-banner .inner .banner-content .area-left > img {
    width: 300px;
    transform: rotate(60deg);
    display: inline-block;
    margin: 0 50px 0 0;
  }

  @media screen and (max-width: 800px) {
    .flynice-banner .inner .banner-content .area-left {
      flex: none;
      padding: 0 0 0 30px;

    }
    .flynice-banner .inner .banner-content .area-left > img {
      width: 250px;
    }
  }
  @media screen and (max-width: 700px) {
    .flynice-banner .inner .banner-content .area-left > img {
      width: 150px;
    }
  }
  .flynice-banner .inner .banner-content .area-right {
    vertical-align: middle;
    display: inline-block;
    max-width: 500px;
    padding: 0 80px 0 0;
    flex: 1;
  }
  @media screen and (max-width: 500px) {
    .flynice-banner .inner .banner-content .area-right {
      padding: 0 40px;
    }
  }
  .flynice-banner .inner .banner-content .area-right:before {
    content: "";
    width: 0;
    height: 100%;
  }
  .flynice-banner .inner .banner-content .area-right:before,
  .flynice-banner .inner .banner-content .area-right .area-inner {
    display: inline-block;
    vertical-align: middle;
  }
  .flynice-banner .inner .banner-content .area-right .area-inner {


  }

  .flynice-banner .inner .banner-content .area-right .title {
    font-size: 2em;
    font-weight: 700;
    text-align: center;
  }
  @media screen and (max-width: 750px) {
    .flynice-banner .inner .banner-content .area-right .title {
      font-size: 1.8em;

    }
  }
  @media screen and (max-width: 650px) {
    .flynice-banner .inner .banner-content .area-right .title {
      font-size: 1.6em;

    }
  }
  .flynice-banner .inner .banner-content .area-right .btn-container {

  }
  .flynice-banner .inner .footer {
    color: #fff;
    text-align: right;
    font-weight: 500;
    padding: 30px 20px;
  }
  .flynice-banner .inner .footer .info-label {

  }
  .btn-container {
    text-align: center;
    margin: 40px 0 0 0;
  }
  .btn {
    display: inline-block;
    color: #58C8ED;
    font-weight: 800;
    font-family: 'Montserrat';
    font-size: 2.5rem;
    background-color: #fff;
    outline: none;
    border: none;
    border-radius: 60px;
    padding: 15px 10px;
    width: 430px;
  }
  .btn {
    transition: all 0.3s;
    transform: scale(1);
  }
  .btn:hover {
    transform: scale(1.05);
  }
  @media screen and (max-width: 850px) {
    .btn {
      font-size: 2rem;
      width: calc(100% - 20px);
    }
  }
  @media screen and (max-width: 750px) {
    .btn {
      font-size: 1.6rem;
    }
  }
  @media screen and (max-width: 650px) {
    .btn {
      font-size: 1.3rem;
    }
  }
</style>

<script>
  import content from '@/../content/purpose.yaml'
  export default {
    name: "FlyNiceBanner",
    data() {
      return {
        content: content['flynice']
      }
    }
  }
</script>
