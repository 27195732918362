export default class TimelineScrolling {
  minState = 0;
  constructor(tl, start, end, min = 0, goBack = true) {
    this.tl = tl;
    this.start = start;
    this.end = end;
    this.min = min;
    this.goBack = goBack;



    window.addEventListener("scroll", () => {
      this.update();
    });
    this.update();
  }
  update() {
    const state = this.tl.duration * this.progress;
    if (state > this.minState) {
      this.minState = state;
    }
    if (this.goBack) {
      this.tl.seek(state);
    }
    else {
      this.tl.seek(Math.max(this.minState, state));
    }
  }
  get progress() {
    return this.min, Math.max(0, Math.min(1, (Math.max(this.min, window.scrollY) - this.start) / (this.end - this.start)));
  }
}
