<template>
  <div class="cover-wrapper" data-name="work" style="z-index: 4;">
    <div class="graphic-inner">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="30 -10 100 100">

        <rect style="display: none; fill-opacity: 0.5;" x="30" y="0" width="100%" height="100%" fill="red"/>


        <g>
          <image x="35" y="15" :href="planetsGraphic" width="85"/>

          <image x="49" y="6" :href="birdRedGraphic" width="14"/>
          <image x="96" y="14" :href="birdYellowGraphic" width="10"/>
        </g>



        <svg x="67" y="-7" width="55" viewBox="0 0 879 136" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:serif="http://www.serif.com/">
          <g>
            <path d="M64.282,135.331c-12.339,0 -23.384,-2.388 -33.136,-7.164c-9.752,-4.777 -17.381,-11.477 -22.887,-20.101c-5.506,-8.624 -8.259,-18.376 -8.259,-29.255c0,-10.88 2.753,-20.599 8.259,-29.156c5.506,-8.558 13.135,-15.225 22.887,-20.002c9.752,-4.776 20.797,-7.164 33.136,-7.164c13.268,-0 24.612,2.852 34.032,8.558c9.42,5.705 15.922,13.599 19.504,23.683l-34.828,17.115c-4.246,-9.553 -10.548,-14.329 -18.907,-14.329c-5.307,-0 -9.718,1.857 -13.234,5.572c-3.516,3.715 -5.274,8.956 -5.274,15.723c-0,6.899 1.758,12.206 5.274,15.921c3.516,3.715 7.927,5.572 13.234,5.572c8.359,0 14.661,-4.776 18.907,-14.329l34.828,17.116c-3.582,10.083 -10.084,17.977 -19.504,23.683c-9.42,5.705 -20.764,8.557 -34.032,8.557Z" style="fill:#fff;fill-rule:nonzero;" />
            <path d="M184.688,135.331c-12.074,0 -22.921,-2.421 -32.54,-7.264c-9.619,-4.842 -17.148,-11.543 -22.588,-20.1c-5.44,-8.558 -8.16,-18.277 -8.16,-29.156c0,-10.88 2.72,-20.599 8.16,-29.156c5.44,-8.558 12.969,-15.225 22.588,-20.002c9.619,-4.776 20.466,-7.164 32.54,-7.164c12.206,-0 23.119,2.388 32.738,7.164c9.619,4.777 17.115,11.444 22.489,20.002c5.373,8.557 8.06,18.276 8.06,29.156c-0,10.879 -2.687,20.598 -8.06,29.156c-5.374,8.557 -12.87,15.258 -22.489,20.1c-9.619,4.843 -20.532,7.264 -32.738,7.264Zm-0,-35.027c5.174,0 9.42,-1.89 12.737,-5.672c3.317,-3.781 4.975,-9.055 4.975,-15.821c-0,-6.767 -1.658,-12.008 -4.975,-15.723c-3.317,-3.715 -7.563,-5.572 -12.737,-5.572c-5.175,-0 -9.421,1.857 -12.738,5.572c-3.317,3.715 -4.975,8.956 -4.975,15.723c0,6.766 1.658,12.04 4.975,15.821c3.317,3.782 7.563,5.672 12.738,5.672Z" style="fill:#fff;fill-rule:nonzero;" />
            <path d="M337.731,22.489c13.401,-0 24.181,4.047 32.341,12.14c8.159,8.093 12.239,20.3 12.239,36.619l0,62.093l-44.978,0l0,-54.331c0,-12.472 -4.444,-18.708 -13.334,-18.708c-5.042,0 -9.121,1.758 -12.239,5.274c-3.118,3.516 -4.677,9.055 -4.677,16.618l-0,51.147l-44.978,0l0,-108.862l42.789,0l-0,10.747c4.245,-4.246 9.154,-7.43 14.727,-9.553c5.572,-2.123 11.609,-3.184 18.11,-3.184Z" style="fill:#fff;fill-rule:nonzero;" />
            <path d="M477.441,22.489c13.401,-0 24.181,4.047 32.34,12.14c8.16,8.093 12.24,20.3 12.24,36.619l-0,62.093l-44.978,0l0,-54.331c0,-12.472 -4.445,-18.708 -13.334,-18.708c-5.042,0 -9.122,1.758 -12.24,5.274c-3.118,3.516 -4.676,9.055 -4.676,16.618l-0,51.147l-44.978,0l-0,-108.862l42.788,0l0,10.747c4.246,-4.246 9.155,-7.43 14.728,-9.553c5.572,-2.123 11.609,-3.184 18.11,-3.184Z" style="fill:#fff;fill-rule:nonzero;" />
            <path d="M659.342,78.811c0,0.663 -0.199,4.179 -0.597,10.548l-75.626,-0c1.592,4.113 4.246,7.264 7.961,9.453c3.715,2.189 8.358,3.284 13.931,3.284c4.776,-0 8.79,-0.597 12.04,-1.792c3.251,-1.194 6.8,-3.25 10.648,-6.169l23.484,23.683c-10.615,11.676 -26.47,17.513 -47.565,17.513c-13.135,0 -24.678,-2.421 -34.629,-7.264c-9.951,-4.842 -17.646,-11.576 -23.086,-20.2c-5.44,-8.624 -8.16,-18.309 -8.16,-29.056c0,-10.88 2.687,-20.599 8.06,-29.156c5.374,-8.558 12.737,-15.225 22.091,-20.002c9.354,-4.776 19.869,-7.164 31.544,-7.164c11.013,-0 21.063,2.189 30.151,6.567c9.089,4.379 16.32,10.814 21.693,19.305c5.374,8.491 8.06,18.641 8.06,30.45Zm-59.506,-25.674c-4.643,0 -8.491,1.261 -11.543,3.782c-3.051,2.521 -5.041,6.103 -5.97,10.747l35.027,-0c-0.929,-4.511 -2.919,-8.06 -5.971,-10.648c-3.051,-2.587 -6.899,-3.881 -11.543,-3.881Z" style="fill:#fff;fill-rule:nonzero;" />
            <path d="M731.983,135.331c-12.339,0 -23.384,-2.388 -33.136,-7.164c-9.752,-4.777 -17.381,-11.477 -22.887,-20.101c-5.506,-8.624 -8.259,-18.376 -8.259,-29.255c0,-10.88 2.753,-20.599 8.259,-29.156c5.506,-8.558 13.135,-15.225 22.887,-20.002c9.752,-4.776 20.797,-7.164 33.136,-7.164c13.268,-0 24.612,2.852 34.032,8.558c9.42,5.705 15.922,13.599 19.504,23.683l-34.828,17.115c-4.246,-9.553 -10.548,-14.329 -18.907,-14.329c-5.307,-0 -9.718,1.857 -13.234,5.572c-3.516,3.715 -5.274,8.956 -5.274,15.723c-0,6.899 1.758,12.206 5.274,15.921c3.516,3.715 7.927,5.572 13.234,5.572c8.359,0 14.661,-4.776 18.907,-14.329l34.828,17.116c-3.582,10.083 -10.084,17.977 -19.504,23.683c-9.42,5.705 -20.764,8.557 -34.032,8.557Z" style="fill:#fff;fill-rule:nonzero;" />
            <path d="M878.062,129.56c-6.767,3.848 -15.988,5.771 -27.664,5.771c-15.258,0 -27,-3.681 -35.226,-11.045c-8.226,-7.364 -12.339,-18.409 -12.339,-33.136l0,-27.863l-14.926,0l0,-32.837l14.926,-0l0,-30.45l44.978,-0l0,30.45l22.29,-0l0,32.837l-22.29,0l0,27.465c0,3.317 0.863,5.904 2.587,7.761c1.725,1.858 3.981,2.787 6.767,2.787c3.848,-0 7.231,-0.996 10.15,-2.986l10.747,31.246Z" style="fill:#fff;fill-rule:nonzero;" />
          </g>
        </svg>




      </svg>
    </div>
  </div>
</template>


<style scoped>
  .cover-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .cover-wrapper .graphic-inner {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .cover-wrapper .graphic-inner > svg {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
  }

</style>

<script>
  import PlanetsGraphic from '@/assets/cover/planets.png';
  import BirdRedGraphic from '@/assets/cover/bird_red.png';
  import BirdYellowGraphic from '@/assets/cover/bird_yellow.png';


  export default {
    name: "CoverConnect",
    data() {
      return {
        planetsGraphic: PlanetsGraphic,
        birdRedGraphic: BirdRedGraphic,
        birdYellowGraphic: BirdYellowGraphic,
      };
    }
  }
</script>
