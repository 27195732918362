<template>
  <div class="story-wrapper" ref="wrapper">
    <div class="story-cover">
      <div class="cover-item cover-left">
        <div class="image">
          <img :src="content.background" alt="Robots">
        </div>
        <div class="gradient">
          <div class="title">
            <span class="bold">{{ content.title[0] }}</span>
            <span>{{ content.title[1] }}</span>
          </div>
        </div>
      </div>
      <div class="cover-item cover-right">

      </div>
    </div>
    <div class="story-content" ref="storyContentContainer">
      <div class="cover-space" :style="{ width: offsetLeft + 'px' }" />
      <div class="content content-aufwand" ref="content">


        <div class="part">
          <article>
            <p v-for="(paragraph, _i) in content['paragraph-1'].split('\n')" :key="_i">
              {{ paragraph }}
            </p>
          </article>
        </div>

        <div class="part part-fixed flex flex-h">
          <div class="flex flex-w list">
            <div class="picture picture-1">
              <iframe :src="content['item-1'].video" class="video-frame video-frame-doubled"></iframe>
            </div>
            <div class="description">
              <div class="description-inner">
                <div class="title">
                  <span v-for="(line, _i) in content['item-1']['info-1'].split('\n')" :key="_i">
                    {{ line }}
                    <br />
                  </span>
                </div>
                <div class="info-main">
                  {{ content['item-1']['info-2'].streams }}
                </div>
                <div class="info-label">
                  {{ content['item-1']['info-1'].label }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-w list">
            <div class="picture picture-1">
              <iframe :src="content['item-2'].video" class="video-frame video-frame-doubled"></iframe>
            </div>
            <div class="description">
              <div class="description-inner">
                <div class="title">
                  <span v-for="(line, _i) in content['item-2']['info-1'].split('\n')" :key="_i">
                    {{ line }}
                    <br />
                  </span>
                </div>
                <div class="info-main">
                  {{ content['item-1']['info-2'].streams }}
                </div>
                <div class="info-label">
                  {{ content['item-1']['info-1'].label }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="part">
          <article class="article-2">
            <p>
              {{ content['paragraph-2'] }}
            </p>
          </article>
        </div>

        <div class="part" style="padding-left: 10px;">
          <div class="gallery">
            <div class="item">
              <img :src="content['image-1']" alt="">
            </div>
            <div class="item">
              <img :src="content['image-2']" alt="">
            </div>
          </div>
        </div>

        <div class="part" style="padding-left: 10px;">
          <article>
            <p>
              {{ content['paragraph-3'] }}
            </p>
            <div class="bottom-line" />
          </article>
        </div>

      </div>
      <div class="cover-space" :style="{ width: offsetRight + 'px' }" />
    </div>
  </div>
</template>


<style scoped>
  .content.content-aufwand {
    width: 3500px;

  }
  @media screen and (max-width: 600px) {
    .content.content-aufwand {
      width: calc(500vw);
    }
  }
  @media screen and (max-width: 530px) {
    .content.content-aufwand {
      width: calc(100vw + 600px + 100vw + 100vw + 600px - 150px);
    }
  }
  .content .part article {
    max-width: 400px;
  }
  @media screen and (max-width: 500px) {
    .content .part article {
      max-width: 300px;
    }
  }

  .picture-1 > img {
    max-height: 300px;
  }
  @media screen and (max-height: 700px) {
    .picture-1 > img {
      max-height: 200px;
    }
  }
  @media screen and (max-width: 600px) {
    .picture-1 > img {
      max-height: 200px;
      max-width: 320px;
    }
  }



  .gallery {
    position: relative;
  }
  .gallery .item {
    display: inline-block;
  }
  .gallery .item > img {
    max-height: 300px;
  }
  .gallery .item:nth-child(1) > img {
    margin: 0 0 100px 0;
  }
  .gallery .item:nth-child(2) > img {
    margin: 100px 0 0 -150px;
  }


  @media screen and (max-width: 600px) {
    .gallery .item {
      display: block;
      margin: auto;
    }
    .gallery .item > img {
      max-height: 200px;
    }
    .gallery .item:nth-child(1) > img {
      margin: 0 0 10px 0;
    }
    .gallery .item:nth-child(2) > img {
      margin: 10px 0 0 0px;
    }
  }
</style>

<style scoped>
  @import "./story.css";
</style>

<script>
  import content from '@/../content/story/story-movies.yaml'



  function bindBounds(app, refId, storageKey) {
    app[storageKey][refId] = app.$refs[refId].getBoundingClientRect();
    window.addEventListener("resize", () => {
      if (app.$refs[refId]) {
        app[storageKey][refId] = app.$refs[refId].getBoundingClientRect();
      }
    });
  }

  export default {
    name: "StoryMovies",
    computed: {
      showFirstCover() {
        return this.scroll - this.offsetRight >= this.endPos;
      },
      startPos() {
        return this.offsetLeft;
      },
      endPos() {
        const offset = this.bounds.content ? (this.bounds.content.width - this.bounds.wrapper.width) : 0;
        return -offset - this.offsetRight;
      }
    },
    mounted() {
      bindBounds(this, 'wrapper', 'bounds');
      bindBounds(this, 'content', 'bounds');
    },
    data() {
      return {
        content,
        bounds: {},
        offsetLeft: window.innerWidth < 600 ? 250 : 400,
        offsetRight: 0
      }
    }
  }
</script>
