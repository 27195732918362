<template>
  <div class="page-container">
    <AboutUs />
    <Team />
    <SupportMessage />

    <PageFooter :offset="-60" :background="footer.background" :logo-fill-color="footer['logo-fill-color']" :hide-contact="footer['hide-contact']">
      <template v-slot:title>
        {{ footer.title }}
      </template>
      <template v-slot:description>
        <span v-html="footer.description"></span>
      </template>
    </PageFooter>
  </div>
</template>

<style scoped>
  @import "../page.css";
  .page-container {
    padding: 60px 0 0 !important;
  }
</style>

<script>
  import AboutUs from './AboutUs.vue';
  import Team from './Team.vue';
  import SupportMessage from './SupportMessage.vue';

  import content from '@/../content/connect.yaml'

  import PageFooter from '@/components/PageFooter.vue';

  export default {
    name: "ConnectPage",
    data() {
      return {
        footer: content.footer
      }
    },
    components: {
      AboutUs,
      Team,
      SupportMessage,
      PageFooter
    }
  }
</script>
