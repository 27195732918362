<template>
  <div class="header-wrapper" ref="headerWrapper" :class="{ mounted, small: headerIsSmall(), 'cover-mobile-fullscreen': $route.meta.coverMobileFullscreen }">
    <div class="cover">
      <Cover :noImage="headerIsSmall()" />
    </div>
    <div class="nav-area" v-if="!navIsMobile" >
      <PageNavigation :nav="nav" />
    </div>
    <div class="mobile-logo-area" v-if="false">
      <div class="logo-mobile">
        <svg viewBox="100 0 60 48">
          <path style="fill: rgba(255, 255, 255, 0.5);" d="M152.855 0H111.145C109.408 0 108 1.45201 108 3.24315V44.7569C108 46.548 109.408 48 111.145 48H152.855C154.592 48 156 46.548 156 44.7569V3.24315C156 1.45201 154.592 0 152.855 0Z" />
          <path style="fill: #fff;" d="M126.037 36.3492C123.807 35.2013 121.947 33.4515 120.671 31.3005C119.371 29.0789 118.705 26.5464 118.746 23.9761C118.703 21.4122 119.369 18.8859 120.671 16.6722C121.947 14.538 123.796 12.7993 126.01 11.6508C128.413 10.4133 131.089 9.79047 133.795 9.83846C136.302 9.78265 138.783 10.3518 141.012 11.4941C143.033 12.5548 144.664 14.2243 145.672 16.2634L140.669 19.1659C139.946 17.9617 138.915 16.9696 137.681 16.2907C136.072 15.4793 134.251 15.1806 132.466 15.4354C130.68 15.6902 129.017 16.4861 127.703 17.7147C126.891 18.5453 126.26 19.5325 125.847 20.6153C125.435 21.6981 125.25 22.8536 125.304 24.0102C125.25 25.1635 125.432 26.3158 125.839 27.397C126.247 28.4781 126.872 29.4655 127.675 30.2989C128.999 31.5225 130.671 32.31 132.461 32.5539C134.252 32.7978 136.075 32.4861 137.681 31.6616C138.916 30.9872 139.948 29.9969 140.669 28.7932L145.672 31.6888C144.628 33.7204 142.982 35.3849 140.957 36.4582C138.753 37.6196 136.289 38.2035 133.795 38.1547C131.099 38.2026 128.432 37.582 126.037 36.3492Z" />
        </svg>
      </div>
    </div>
    <div class="header-btns">
      <div :class="{ visible: $route.meta.videoWithAutoplay }" style="float: left; width: 30px; height: 30px;" class="btn btn-sound" @click="autoplaySound = !autoplaySound;">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480" xml:space="preserve">
          <g style="fill: #fff;">
            <path d="M278.944,17.577c-5.568-2.656-12.128-1.952-16.928,1.92L106.368,144.009H32c-17.632,0-32,14.368-32,32v128 c0,17.664,14.368,32,32,32h74.368l155.616,124.512c2.912,2.304,6.464,3.488,10.016,3.488c2.368,0,4.736-0.544,6.944-1.6 c5.536-2.656,9.056-8.256,9.056-14.4v-416C288,25.865,284.48,20.265,278.944,17.577z"/>
            <path d="M368.992,126.857c-6.304-6.208-16.416-6.112-22.624,0.128c-6.208,6.304-6.144,16.416,0.128,22.656 C370.688,173.513,384,205.609,384,240.009s-13.312,66.496-37.504,90.368c-6.272,6.176-6.336,16.32-0.128,22.624 c3.136,3.168,7.264,4.736,11.36,4.736c4.064,0,8.128-1.536,11.264-4.64C399.328,323.241,416,283.049,416,240.009 S399.328,156.777,368.992,126.857z"/>
            <path d="M414.144,81.769c-6.304-6.24-16.416-6.176-22.656,0.096c-6.208,6.272-6.144,16.416,0.096,22.624 C427.968,140.553,448,188.681,448,240.009s-20.032,99.424-56.416,135.488c-6.24,6.24-6.304,16.384-0.096,22.656 c3.168,3.136,7.264,4.704,11.36,4.704c4.064,0,8.16-1.536,11.296-4.64C456.64,356.137,480,299.945,480,240.009 S456.64,123.881,414.144,81.769z"/>
          </g>
          <g>
            <path d="M 25,455 L 455,25" :style="{ 'stroke-dashoffset': autoplaySound ? 615 : 0 }" style="transition: all 0.5s; stroke-dasharray: 615; fill: none; stroke: #fff; stroke-width: 50px; stroke-linecap: round;" />
          </g>
        </svg>
      </div>
      <button class="btn-play" v-if="false" @click="playPauseVideoManually">
        <div class="icon">
          <svg v-if="headerVideoPlaying" width="100%" height="100%" viewBox="0 0 12 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-650.000000, -955.000000)">
                <g transform="translate(100.000000, 852.000000)">
                  <g transform="translate(544.000000, 98.000000)">
                    <g>
                      <rect id="Rectangle-Copy-67" x="0" y="0" width="24" height="24"></rect>
                      <path d="M8,19 C9.1,19 10,18.1 10,17 L10,7 C10,5.9 9.1,5 8,5 C6.9,5 6,5.9 6,7 L6,17 C6,18.1 6.9,19 8,19 Z M14,7 L14,17 C14,18.1 14.9,19 16,19 C17.1,19 18,18.1 18,17 L18,7 C18,5.9 17.1,5 16,5 C14.9,5 14,5.9 14,7 Z"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <svg v-else width="100%" height="100%" viewBox="0 0 11 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-753.000000, -955.000000)">
                <g transform="translate(100.000000, 852.000000)">
                  <g transform="translate(646.000000, 98.000000)">
                    <g>
                      <rect id="Rectangle-Copy-50" x="0" y="0" width="24" height="24"></rect>
                      <path d="M7,6.82 L7,17.18 C7,17.97 7.87,18.45 8.54,18.02 L16.68,12.84 C17.3,12.45 17.3,11.55 16.68,11.15 L8.54,5.98 C7.87,5.55 7,6.03 7,6.82 Z" id="🔹Icon-Color" fill="#1D1D1D"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <label>{{ headerVideoPlaying ? 'Pause' : 'Play' }} Showreel</label>
      </button>
    </div>
    <div class="bubble">
      <div class="bubble-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 550 108" ref="bubbleWrapper" style="" :style="{ left: bubbleDrag }">
          <g>
            <svg x="0" y="-10" width="594" height="374" viewBox="0 0 594 374" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path ref="bubblePath" v-bind:d="'M 583.37 125.316 C 587.959 230.426 408.289 356.545 303.178 361.134C198.068 365.724 8.95898 230.426 4.36977 125.316 C 189.37 ' + (125.316 +        0) + ' 179.26 ' + (17.4049 +        0) + ' 284.37 ' + (12.8157 +       0) + ' C 387.68 ' + (12.8157 +        0) + ' 378.37 ' + (125.316 +        0) + ' ' + (583.37 -        0) + ' 125.316Z'" class="bubble-path" />
            </svg>
          </g>
        </svg>
      </div>
    </div>
    <div class="offset-space" />
  </div>
  <div class="mobile-nav-container" v-if="showMobileNav">
    <MobileNavigation :nav="nav" />
  </div>
</template>


<style scoped>
  .mobile-nav-container {
    height: 200px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: visible;
  }

  .header-wrapper {
    width: 100%;
    overflow: hidden;
    /*
      Schaffe Platz um einen Overflow zu packen
    */
    --coverRatio: 0.5;
    --offsetY: 100px;
    display: grid;
    transition: all 0s;
    grid-template-columns: 100%;
    grid-template-rows: [top] 100px [cover] calc(100vw * 0.36) [bubble] 160px [overflow] 11vw;
  }
  .header-wrapper.small {
    display: none;
    height: calc(260px);
    grid-template-rows: [top] 100px [cover] 0px [bubble] 160px [overflow] 0px !important;
  }
  @media screen and (max-width: 1000px) {
    .header-wrapper {
    }
  }
  @media screen and (max-width: 600px) {
    .header-wrapper {
      grid-template-rows: [top] 100px [cover] calc(100vw * 0.4) [bubble] 160px [overflow] 11vw;
    }
    .header-wrapper.small {
      min-height: auto;
    }
    .header-wrapper.cover-mobile-fullscreen {
      grid-template-rows: [top] 100px [cover] calc(100vh - 320px) [bubble] 160px [overflow] 11vw;
    }
  }
  .header-wrapper .nav-area {
    grid-column: 1 / span 1;
    grid-row: top / span 1;
    z-index: 6;
  }
  .header-wrapper .mobile-nav-area {
    grid-column: left / span 1;
    grid-row: top / span 3;
    z-index: 6;
  }
  .header-wrapper .cover {
    grid-column: 1 / span 1;
    grid-row: top / 5;
    overflow: visible;

  }
  .header-wrapper .header-btns {
    grid-column: 1 / span 1;
    grid-row: bubble / span 1;
    z-index: 3;
    position: relative;
  }
  .header-wrapper .bubble {
    grid-column: 1 / span 1;
    grid-row: bubble / span 1;
    z-index: 2;
  }
  .header-wrapper .offset-space {
    grid-column: 1 / span 1;
    grid-row: overflow / span 1;
    z-index: 2;
    background-color: #fff;
  }
  .header-wrapper .header-btns .btn {
    position: absolute;
    bottom: 40px;
    left: 40px;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0.3));
    cursor: pointer;
    opacity: 0;
    transition: all 0.5s;
    transform: scale(0);
  }
  .header-wrapper .header-btns .btn-play {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    outline: none;
    border: none;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    background-color: #fff;
    font-weight: 500;
    font-family: 'Montserrat';
    font-size: 1em;
    color: #444;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
  }
  .header-wrapper .header-btns .btn-play .icon {
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px 0 0;

  }
  .header-wrapper .header-btns .btn-play .icon > svg {

  }
  .header-wrapper .header-btns .btn-play .icon > svg path {
    fill: #444;
  }
  .header-wrapper .header-btns .btn-play > label {
    display: inline-block;
    vertical-align: middle;
  }
  @media screen and (max-width: 800px) {
    .header-wrapper .header-btns .btn {
      bottom: 60px;
    }
  }
  @media screen and (max-width: 600px) {
    .header-wrapper .header-btns .btn {
      bottom: 80px;
    }
  }
  .header-wrapper .header-btns .btn.visible {
    opacity: 1;
    transform: scale(1);
  }
  .header-wrapper .mobile-logo-area {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;

    z-index: 1;
  }
  .header-wrapper .logo-mobile {
    width: 50px;
    height: 50px;
    padding: 15px 0 0 15px;
  }
  .header-wrapper .logo-mobile > svg {
    width: 100%;
    height: 100%;
  }


  .bubble {
    overflow-x: hidden;
  }
  .bubble .bubble-wrapper {
    transform-origin: 50% 100%;
    /*transform: scale(1.3);*/
  }
  .bubble .bubble-wrapper > svg {
    position: relative;
    height: 80px;
    top: 80px;
    transform-origin: 50% 100%;
    transform: translateX(-50%) scale(1);
    color: #f00;

  }
  .mounted .bubble .bubble-wrapper > svg {
    transition: left 1s;
  }

  .bubble-path {
    fill: #fff;
  }
</style>

<script>
  import { useRoute } from 'vue-router';
  import anime from 'animejs';
  import { ref } from 'vue'

  import content from '@/../content/header.yaml'

  import PageNavigation from './PageNavigation.vue'
  import MobileNavigation from './MobileNavigation.vue'
  import Cover from './Cover.vue'



  export default {
    name: "PageHeader",
    computed: {
      bubbleDrag() {
        return this.$route?.meta?.bubble?.pos || "50%";
      },
      bubbleD() {
        return this.$route?.meta?.bubble?.d || "";
      },
      bubbleScale() {
        return this.$route?.meta?.bubble?.scale || 1;
      }
    },
    methods: {
      playPauseVideoManually() {
        this.$root.events.emit("play-pause-header-video");
        if (this.headerVideoPlaying) this.autoplaySound = true;
      },
      headerIsSmall() {
        return this.$route?.meta?.smallHeader || false;
      },
      setNavItemRef(el) {
        this.navItems.push(el);
      },
      toggleMobileNav() {
        this.navPopupOpen = !this.navPopupOpen;
      }
    },
    data() {
      return {
        mounted: false,
        navPopupOpen: false,
        navIsMobile: false,
        nav: content['navigation'],
        autoplaySound: false,
        showMobileNav: false,
        headerVideoPlaying: false
      };
    },
    components: {
      Cover,
      PageNavigation,
      MobileNavigation
    },
    mounted() {
      setTimeout(() => {
        this.mounted = true;
      }, 500);
    },
    created() {
      this.navIsMobile = window.innerWidth <= 900;
      window.addEventListener("resize", () => {
        this.navIsMobile = window.innerWidth <= 900;
        this.showMobileNav = this.navIsMobile && !this.$route.meta.smallHeader;
      });
      setInterval(() => {
        this.navIsMobile = window.innerWidth <= 900;
        this.showMobileNav = this.navIsMobile && !this.$route.meta.smallHeader;

      }, 500);

      this.$root.events.on('header-video-state', ({ playing }) => {
        this.headerVideoPlaying = playing;
      })
    },
    setup() {
      const route = useRoute();
      const open = ref(true);

      route;

      return {
        open
      };
    },
    watch: {
      autoplaySound() {
        this.$root.updateSoundSettings(this.autoplaySound);
      },
      $route(to, from) {
        anime; to; from;

        this.navPopupOpen = false;

        const duration = (from?.path || "/") != "/" ? 1000 : 0;

        anime({
          targets: this.$refs.bubblePath,
          d: to.meta.bubble.d,
          easing: 'easeOutQuad',
          duration,
          loop: false
        });

        anime({
          targets: this.$refs.bubbleWrapper,
          translateX: '-50%',
          scale: to.meta.bubble.scale,
          easing: 'easeOutQuad',
          duration: duration,
          loop: false
        });



      }
    }
  }
</script>
