<template>
  <div class="cover-wrapper">
    <!--<video class="fullscreen-video" src="https://www.w3schools.com/html/mov_bbb.mp4" autoplay ></video>-->
    <div class="emblem-layer">
      <div class="inner">
        {{ 'c' }}
      </div>
    </div>

  </div>
</template>


<style scoped>
  .cover-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    z-index: 1;
  }
  .cover-wrapper > img {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    max-width: calc(100vh - 400px);
  }
  .fullscreen-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .btn-play {
    display: none;
  }
  @media screen and (max-width: 600px) {
    .cover-wrapper > img {
      max-width: 100vw !important;
    }
    .btn-play {
      display: block;
      position: absolute;
      left: 50%;
      bottom: 170px;
      transform: translate(-50%, 0%);
      z-index: 300;
    }
  }
  .emblem-layer {
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 20px;
    width: 200px;
    height: 200px;
    transform: translate(-50%, calc(-50% - 50px));
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
  }
  .emblem-layer .inner {
    position: absolute;
    display: inline-block;
    font-size: 14em;
    font-weight: 700;
    color: #fff;
    left: calc(50% - 68px);
    top: calc(-55px);
    font-family: 'Montserrat';
  }
  @media screen and (max-width: 700px) {
    .emblem-layer {
      width: 200px;
      height: 200px;
    }
    .emblem-layer .inner {
      font-size: 13.33em;
      left: calc(50% - 66px);
      top: calc(-46.66px);
    }
  }


</style>

<script>
  import SettingsSVG from '@/assets/settings.svg'

  export default {
    name: "CoverCreate",
    data() {
      return {
        settingImage: SettingsSVG
      };
    },
    mounted() {

    }
  }
</script>
