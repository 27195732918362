<template>
  <div class="cover-wrapper" data-name="work" style="z-index: 0;">
    <div class="graphic-inner">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="30 0 130 100">

        <rect style="display: none;" x="30" y="0" width="100%" height="100%" fill="red"/>

        <g>
          <image x="92" y="10" :href="earthGraphic" width="80"/>

          <image x="89" y="61" :href="mondGraphic" width="15"/>
        </g>

        <svg x="33" y="-20" width="70" viewBox="0 0 532 131" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/">
          <path d="M526.9,0l-526.9,31.304l4.261,71.715l526.9,-31.304l-4.261,-71.715Z" style="fill:#8ad4ed;" />
          <g>
            <path d="M58.916,44.575c5.476,0 10.515,1.312 15.117,3.936c4.601,2.624 8.252,6.408 10.952,11.352c2.7,4.943 4.05,10.648 4.05,17.112c-0,6.465 -1.35,12.15 -4.05,17.056c-2.7,4.906 -6.351,8.671 -10.952,11.294c-4.602,2.624 -9.641,3.936 -15.117,3.936c-6.921,0 -12.207,-1.787 -15.857,-5.362l-0,26.354l-25.784,0l0,-84.537l24.529,0l-0,5.134c3.726,-4.183 9.431,-6.275 17.112,-6.275Zm-6.16,44.607c2.966,0 5.4,-1.064 7.301,-3.194c1.902,-2.13 2.852,-5.134 2.852,-9.013c0,-3.879 -0.95,-6.902 -2.852,-9.069c-1.901,-2.168 -4.335,-3.252 -7.301,-3.252c-2.966,0 -5.4,1.084 -7.302,3.252c-1.901,2.167 -2.852,5.19 -2.852,9.069c0,3.879 0.951,6.883 2.852,9.013c1.902,2.13 4.336,3.194 7.302,3.194Z" style="fill:#fff;fill-rule:nonzero;" />
            <path d="M165.358,45.716l-0,62.405l-24.529,-0l0,-5.933c-2.281,2.358 -4.943,4.126 -7.986,5.305c-3.042,1.179 -6.274,1.768 -9.697,1.768c-7.91,0 -14.26,-2.414 -19.052,-7.244c-4.792,-4.83 -7.187,-12.112 -7.187,-21.847l-0,-34.454l25.783,0l-0,30.004c-0,4.26 0.684,7.302 2.053,9.127c1.369,1.826 3.385,2.738 6.047,2.738c2.51,0 4.601,-1.007 6.275,-3.023c1.673,-2.015 2.509,-5.191 2.509,-9.526l0,-29.32l25.784,0Z" style="fill:#fff;fill-rule:nonzero;" />
            <path d="M201.294,52.447c4.564,-5.248 11.295,-7.872 20.193,-7.872l0,22.817c-2.053,-0.304 -3.955,-0.456 -5.704,-0.456c-8.822,-0 -13.234,4.487 -13.234,13.462l0,27.723l-25.783,-0l0,-62.405l24.528,0l0,6.731Z" style="fill:#fff;fill-rule:nonzero;" />
            <path
              d="M270.43,44.575c5.476,0 10.515,1.312 15.116,3.936c4.602,2.624 8.252,6.408 10.952,11.352c2.7,4.943 4.05,10.648 4.05,17.112c0,6.465 -1.35,12.15 -4.05,17.056c-2.7,4.906 -6.35,8.671 -10.952,11.294c-4.601,2.624 -9.64,3.936 -15.116,3.936c-6.921,0 -12.207,-1.787 -15.858,-5.362l0,26.354l-25.783,0l-0,-84.537l24.528,0l0,5.134c3.727,-4.183 9.431,-6.275 17.113,-6.275Zm-6.161,44.607c2.966,0 5.4,-1.064 7.302,-3.194c1.901,-2.13 2.852,-5.134 2.852,-9.013c-0,-3.879 -0.951,-6.902 -2.852,-9.069c-1.902,-2.168 -4.336,-3.252 -7.302,-3.252c-2.966,0 -5.4,1.084 -7.301,3.252c-1.902,2.167 -2.852,5.19 -2.852,9.069c-0,3.879 0.95,6.883 2.852,9.013c1.901,2.13 4.335,3.194 7.301,3.194Z"
              style="fill:#fff;fill-rule:nonzero;" />
            <path d="M341.619,109.261c-6.921,0 -13.139,-1.388 -18.653,-4.164c-5.514,-2.776 -9.83,-6.617 -12.949,-11.522c-3.118,-4.906 -4.677,-10.477 -4.677,-16.714c-0,-6.236 1.559,-11.808 4.677,-16.713c3.119,-4.906 7.435,-8.728 12.949,-11.466c5.514,-2.738 11.732,-4.107 18.653,-4.107c6.997,0 13.253,1.369 18.767,4.107c5.514,2.738 9.811,6.56 12.891,11.466c3.081,4.905 4.621,10.477 4.621,16.713c-0,6.237 -1.54,11.808 -4.621,16.714c-3.08,4.905 -7.377,8.746 -12.891,11.522c-5.514,2.776 -11.77,4.164 -18.767,4.164Zm-0,-20.079c2.966,0 5.4,-1.083 7.301,-3.251c1.902,-2.168 2.852,-5.191 2.852,-9.07c0,-3.879 -0.95,-6.883 -2.852,-9.012c-1.901,-2.13 -4.335,-3.195 -7.301,-3.195c-2.966,0 -5.4,1.065 -7.302,3.195c-1.901,2.129 -2.852,5.133 -2.852,9.012c0,3.879 0.951,6.902 2.852,9.07c1.902,2.168 4.336,3.251 7.302,3.251Z" style="fill:#fff;fill-rule:nonzero;" />
            <path
              d="M410.412,109.261c-5.324,0 -10.572,-0.57 -15.744,-1.711c-5.172,-1.141 -9.355,-2.624 -12.549,-4.449l7.073,-16.999c2.89,1.749 6.313,3.118 10.268,4.107c3.955,0.989 7.796,1.483 11.522,1.483c2.967,0 5.039,-0.247 6.218,-0.741c1.179,-0.495 1.768,-1.236 1.768,-2.225c0,-0.989 -0.741,-1.673 -2.224,-2.053c-1.483,-0.381 -3.898,-0.761 -7.245,-1.141c-5.095,-0.609 -9.431,-1.388 -13.005,-2.339c-3.575,-0.951 -6.712,-2.795 -9.412,-5.533c-2.7,-2.738 -4.05,-6.655 -4.05,-11.751c-0,-4.031 1.217,-7.644 3.65,-10.838c2.434,-3.194 6.066,-5.742 10.896,-7.644c4.829,-1.901 10.628,-2.852 17.397,-2.852c4.716,0 9.336,0.438 13.862,1.312c4.525,0.875 8.385,2.149 11.579,3.822l-7.073,16.999c-5.476,-3.043 -11.484,-4.564 -18.025,-4.564c-5.628,0 -8.443,0.989 -8.443,2.967c0,0.988 0.761,1.692 2.282,2.11c1.521,0.418 3.917,0.818 7.187,1.198c5.096,0.608 9.412,1.407 12.949,2.396c3.537,0.989 6.655,2.852 9.355,5.59c2.7,2.738 4.05,6.655 4.05,11.751c0,3.879 -1.217,7.415 -3.651,10.61c-2.433,3.194 -6.084,5.742 -10.952,7.643c-4.867,1.902 -10.762,2.852 -17.683,2.852Z"
              style="fill:#fff;fill-rule:nonzero;" />
            <path d="M516.853,76.861c0,0.381 -0.114,2.396 -0.342,6.047l-43.352,-0c0.912,2.358 2.434,4.164 4.563,5.419c2.13,1.255 4.792,1.882 7.986,1.882c2.738,0 5.039,-0.342 6.902,-1.027c1.864,-0.684 3.898,-1.863 6.104,-3.536l13.462,13.576c-6.085,6.693 -15.173,10.039 -27.266,10.039c-7.53,0 -14.147,-1.388 -19.851,-4.164c-5.704,-2.776 -10.116,-6.636 -13.234,-11.579c-3.118,-4.944 -4.678,-10.496 -4.678,-16.657c0,-6.236 1.541,-11.808 4.621,-16.713c3.08,-4.906 7.301,-8.728 12.663,-11.466c5.362,-2.738 11.39,-4.107 18.083,-4.107c6.312,0 12.074,1.255 17.284,3.765c5.209,2.51 9.355,6.199 12.435,11.066c3.08,4.868 4.62,10.686 4.62,17.455Zm-34.111,-14.717c-2.662,0 -4.868,0.723 -6.617,2.168c-1.749,1.445 -2.89,3.499 -3.423,6.161l20.079,-0c-0.532,-2.586 -1.673,-4.621 -3.422,-6.104c-1.749,-1.483 -3.955,-2.225 -6.617,-2.225Z" style="fill:#fff;fill-rule:nonzero;" />
          </g>
        </svg>

      </svg>
    </div>
  </div>
</template>


<style scoped>
  .cover-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .cover-wrapper .graphic-inner {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .cover-wrapper .graphic-inner > svg {
    height: 100%;
    width: 100%;
  }

</style>

<script>
  import EarthGraphic from '@/assets/cover/earth.png';
  import MondGraphic from '@/assets/cover/mond.png';


  export default {
    name: "CoverWork",
    data() {
      return {
        earthGraphic: EarthGraphic,
        mondGraphic: MondGraphic
      };
    }
  }
</script>
