import { render } from "./StoryPolitics.vue?vue&type=template&id=3fb2c1c3&scoped=true"
import script from "./StoryPolitics.vue?vue&type=script&lang=js"
export * from "./StoryPolitics.vue?vue&type=script&lang=js"

import "./StoryPolitics.vue?vue&type=style&index=0&id=3fb2c1c3&scoped=true&lang=css"
import "./StoryPolitics.vue?vue&type=style&index=1&id=3fb2c1c3&scoped=true&lang=css"
import "./StoryPolitics.vue?vue&type=style&index=2&id=3fb2c1c3&scoped=true&lang=css"
import "./StoryPolitics.vue?vue&type=style&index=3&id=3fb2c1c3&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-3fb2c1c3"

export default script