<template>
  <div class="main-nav" :class="{ show: desktopNavShow }">
    <div class="nav-wrapper">
      <div class="logo hide-part">
        <EmblemCreactive :fill-color="logoFillColor" font-color="#fff" c-color="#fff" />
      </div>
      <div class="nav-routes hide-part">
        <ul class="nav-list" ref="navList">
          <li v-for="(item, index) in nav" :key="index" :class="{ active: activeNavDescriptor == item }" :ref="setNavItemRef">
            <div class="label">
              <router-link :to="item.href">{{ item.label }}</router-link>
            </div>
          </li>
        </ul>
        <div class="nav-pointer-wrapper">
          <div v-if="activeNavIndex >= 0" class="nav-pointer" :style="{ left: pointerPos + 'px' }"></div>
        </div>
      </div>
      <div class="contact hide-part">
        <div class="contact-inner">
          <div class="icon">
            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <clipPath id="clip0">
                  <rect width="32" height="32" fill="white"/>
                </clipPath>
              </defs>
              <g clip-path="url(#clip0)">
                <path d="M31.1291 23.4848L26.6634 19.0191C25.0686 17.4242 22.3572 18.0622 21.7193 20.1355C21.2408 21.571 19.6459 22.3684 18.2105 22.0494C15.0207 21.2519 10.7145 17.1052 9.91708 13.7559C9.43862 12.3205 10.3956 10.7256 11.831 10.2472C13.9043 9.60922 14.5423 6.89791 12.9474 5.30301L8.48168 0.837318C7.20577 -0.279106 5.2919 -0.279106 4.17548 0.837318L1.14518 3.86761C-1.88511 7.05739 1.46416 15.5103 8.96015 23.0063C16.4561 30.5023 24.9091 34.0111 28.0988 30.8213L31.1291 27.791C32.2456 26.5151 32.2456 24.6012 31.1291 23.4848Z" fill="white"/>
              </g>
            </svg>
          </div>
          <div class="label">
            {{ content.phone }}
          </div>
        </div>
      </div>
      <div class="burger-menu">
        <div class="btn" @click="desktopNavShow = !desktopNavShow" :class="{ visible: !$route.meta.alwaysShowNav }">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 50" xml:space="preserve">
            <g style="fill: none; stroke: #fff; stroke-width: 3px; stroke-linecap: round;" :class="{ close: desktopNavShow }">
              <g>
                <path :d="'M 5,' + (25 - 13) + ' l 40,0'" style="transform-origin: 25px 12px;" />
              </g>
              <g>
                <path :d="'M 5,25 l 40,0'" style="transform-origin: 25px 25px;" />
              </g>
              <g>
                <path :d="'M 5,' + (25 + 13) + ' l 40,0'" style="transform-origin: 25px 38px;" />
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .main-nav {
    padding: 40px 40px 0;
    height: auto;

  }
  .main-nav .hide-part {
    transform: translateY(-100px);
    --transitionDuration: 0.4s;
    transition-property: all;
    transition-duration: var(--transitionDuration);
  }
  @media screen and (max-width: 1150px) {
    .main-nav {
      padding: 30px 30px 0;
    }
  }
  .main-nav.show .hide-part {
    transform: translateY(0);
  }
  .nav-wrapper {
    display: flex;
    height: 100%;
    z-index: 10;
    /*opacity: 0;
    transition-property: all;
    transition-duration: var(--transitionDuration);*/
  }
  /*.main-nav.show .nav-wrapper {
    opacity: 1;
  }*/
  .nav-wrapper .logo {
    flex: none;
    width: 250px;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));
  }
  .nav-wrapper .logo:before {
    display: inline-block;
    vertical-align: middle;
    content: "";
    width: 0;
    height: 100%;
  }
  .nav-wrapper .logo > svg {
    display: inline-block;
    vertical-align: middle;
  }
  @media screen and (max-width: 1350px) {
    .nav-wrapper .logo {
      width: 220px;
    }
  }
  @media screen and (max-width: 1150px) {
    .nav-wrapper .logo {
      width: 200px;
    }
  }
  @media screen and (max-width: 1100px) {
    .nav-wrapper .logo {
      width: 20vw;
    }
  }
  @media screen and (max-width: 1000px) {
    .nav-wrapper .logo {
      width: 15vw;
    }
  }
  .nav-wrapper .nav-routes {
    flex: 1;
    margin: 13px 0 0 50px;

  }
  @media screen and (max-width: 1150px) {
    .nav-wrapper .nav-routes {
      margin-left: 30px;
    }
  }
  .nav-wrapper .nav-routes .nav-list {
    list-style: none;
    width: 100%;
    max-width: 800px;
    margin: 0;
    padding: 0;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1.5em;
    display: flex;
  }
  @media screen and (max-width: 1350px) {
    .nav-wrapper .nav-routes .nav-list {
      font-size: 1.4em;
    }
  }
  @media screen and (max-width: 1200px) {
    .nav-wrapper .nav-routes .nav-list {
      font-size: 1.3em;
    }
  }
  @media screen and (max-width: 1100px) {
    .nav-wrapper .nav-routes .nav-list {
      font-size: 1.2em;
    }
  }
  @media screen and (max-width: 1000px) {
    .nav-wrapper .nav-routes .nav-list {
      font-size: 1.1em;
    }
  }
  .nav-wrapper .nav-routes .nav-pointer-wrapper {
    height: 2px;
    margin: 7px 0 0;
    width: 100%;
  }
  .nav-wrapper .nav-routes .nav-pointer {
    height: 100%;
    width: 100px;
    display: block;
    background-color: #fff;
    position: relative;
    transform: translateX(-50%);
    transition: all .6s;
    box-shadow: 0pc 0pc 10px rgba(0, 0, 0, 0.15);
  }
  .nav-wrapper .nav-routes .nav-list > li {
    display: inline-block;
    flex: 1;
    text-align: center;

  }
  .nav-wrapper .nav-routes .nav-list > li .label {
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  }
  .nav-wrapper .nav-routes .nav-list > li.active {
    font-weight: 600;
  }
  .nav-wrapper .nav-routes .nav-list > li a {
    text-decoration: inherit;
    color: inherit;

  }
  .nav-wrapper .contact {
    flex: none;
    color: #fff;
    font-size: 1.3em;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));
  }
  @media screen and (max-width: 1450px) {
    .nav-wrapper .contact {
      font-size: 1.2em;
    }
  }
  .nav-wrapper .contact:before {
    vertical-align: middle;
    display: inline-block;
    width: 0;
    height: 100%;
    content: "";
  }
  .nav-wrapper .contact .contact-inner {
    display: inline-block;
    vertical-align: middle;
  }
  .nav-wrapper .contact .contact-inner > * {
    display: inline-block;
    vertical-align: middle;
  }
  .nav-wrapper .contact .contact-inner .icon {
    width: 30px;
    height: 30px;
  }
  .nav-wrapper .contact .contact-inner .label {
    padding: 0 0 0 10px;
  }
  @media screen and (max-width: 1150px) {
    .nav-wrapper .contact {
      font-size: 1em;
    }
    .nav-wrapper .contact .contact-inner .icon {
      width: 25px;
      height: 25px;
    }
  }


  .nav-wrapper .burger-menu {
    padding: 0 0 0 30px;
  }
  .nav-wrapper .burger-menu .btn {
    height: 50px;
    cursor: pointer;
    width: 50px;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3));
  }
  .nav-wrapper .burger-menu .btn.visible {

  }
  .burger-menu .btn:hover {
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.8));
  }
  .burger-menu .btn > svg > g > g path,
  .burger-menu .btn > svg > g > g {
    transition: all 0.5s;
    transform: scale(1) translate(0, 0);
  }
  .burger-menu .btn > svg g.close path {

  }
  .burger-menu .btn > svg g.close g:nth-child(1) path {
    /*transform: rotate(-45deg) translate(0, 13px);*/
    transform: rotate(-45deg);
  }
  .burger-menu .btn > svg g.close g:nth-child(1) {
    transform: translateY(13px);
  }
  .burger-menu .btn > svg g.close g:nth-child(2) path {
    opacity: 0;
    transform: scale(0);
  }
  .burger-menu .btn > svg g.close g:nth-child(3) path {
    /*transform: rotate(45deg) translate(0, -13px);*/
    transform: rotate(45deg);
  }
  .burger-menu .btn > svg g.close g:nth-child(3) {
    transform: translateY(-12px);
  }
</style>

<script>
  import EmblemCreactive from '@/assets/Emblem.vue'
  import { nextTick } from 'vue'

  import content from '@/../content/header.yaml'

  export default {
    name: "PageNavigation",
    components: { EmblemCreactive },
    props: ['nav'],
    computed: {
      logoFillColor() {
        return this.$route?.meta?.logoColor || "rgba(0, 0, 0, 0.2)";
        //return this.$route.meta.logoColor;
      },
      activeNavDescriptor() {
        return this.nav.find(record => record.name.includes(this.$route.name));
      },
      activeNavIndex() {
        return this.nav.indexOf(this.activeNavDescriptor);
      },
      activeNavItem() {
        return this.navItems[this.activeNavIndex];
      },
      pointerPos() {

        if (this.boundingsNavContainer) {
          const itemWidth = this.$refs.navList.offsetWidth / this.nav.length;
          const drag = itemWidth * this.activeNavIndex + itemWidth / 2;
          //const itemWidth = this.boundingsNavContainer.width / this.navItems.length;
          //console.log(itemWidth);
          return drag;
        }
        else {
          return 100;
        }
      }
    },
    methods: {
      setNavItemRef(el) {
        this.navItems.push(el);
      }
    },
    data() {
      return {
        content,
        desktopNavShow: false,
        navItems: [],

        boundingsNavContainer: null
      }
    },
    mounted() {
      this.boundingsNavContainer = this.$refs.navList.getBoundingClientRect();
      window.addEventListener("resize", () => {
        if (this.$refs.navList) {
          this.boundingsNavContainer = this.$refs.navList.getBoundingClientRect();
          if (this.$route.meta.alwaysShowNav) {
            this.desktopNavShow = true;
          }
        }
      });
    },

    created() {

    },
    watch: {
      $route() {
        if (this.$route.meta.alwaysShowNav) {
          this.desktopNavShow = true;
        }

        nextTick(() => {
          if (this.$refs.navList) {
            this.boundingsNavContainer = this.$refs.navList.getBoundingClientRect();
          }
        });
      }
    }
  }
</script>
