<template>
  <svg viewBox="0 0 252 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M152.855 0H111.145C109.408 0 108 1.45201 108 3.24315V44.7569C108 46.548 109.408 48 111.145 48H152.855C154.592 48 156 46.548 156 44.7569V3.24315C156 1.45201 154.592 0 152.855 0Z" :style="{ fill: fillColor }"/>
      <g :style="{ fill: fontColor }">
        <path d="M6.71605 35.3749C4.66355 34.3169 2.9514 32.7068 1.77487 30.7282C0.574603 28.6807 -0.0392251 26.3457 -0.000111428 23.9762C-0.0401686 21.6151 0.5739 19.2887 1.77487 17.2514C2.95406 15.2897 4.65422 13.689 6.68864 12.6251C8.90401 11.4853 11.3699 10.9117 13.864 10.9559C16.1711 10.905 18.4542 11.4297 20.5047 12.4821C22.3667 13.4575 23.8707 14.9933 24.8017 16.8698L20.1963 19.5407C19.533 18.4346 18.5872 17.5226 17.4551 16.8971C16.3404 16.3043 15.0937 15.9998 13.8297 16.0114C12.7952 15.9716 11.7636 16.144 10.7992 16.5179C9.83467 16.8919 8.958 17.4593 8.22376 18.1848C7.47642 18.9483 6.8949 19.8564 6.51529 20.8526C6.13568 21.8488 5.96608 22.912 6.01702 23.9762C5.9696 25.0409 6.14081 26.1042 6.52021 27.101C6.8996 28.0979 7.47923 29.0075 8.22376 29.7743C8.9589 30.4943 9.83393 31.0577 10.7954 31.4302C11.7569 31.8028 12.7847 31.9765 13.816 31.941C15.0791 31.9518 16.325 31.6497 17.4414 31.062C18.5738 30.4338 19.5194 29.5196 20.1826 28.4116L24.788 31.0825C23.8262 32.9578 22.3132 34.4977 20.4499 35.4975C18.4165 36.5583 16.1462 37.0904 13.8503 37.0442C11.3703 37.0796 8.91982 36.5062 6.71605 35.3749Z" />
        <path d="M44.8064 10.9559V16.6314C44.3548 16.5426 43.8961 16.4947 43.4358 16.4883C41.0966 16.4883 39.269 17.1696 37.9532 18.5323C36.6374 19.895 35.9795 21.8368 35.9795 24.3577V36.7103H29.9829V11.242H35.6917V14.9894C37.4187 12.3004 40.4569 10.9559 44.8064 10.9559Z" />
        <path d="M73.6379 25.8907H53.5374C53.8277 27.6779 54.8121 29.2804 56.2787 30.3534C57.8967 31.5014 59.8499 32.088 61.8366 32.0227C63.1254 32.0647 64.409 31.8406 65.6063 31.3645C66.8035 30.8883 67.8885 30.1706 68.7926 29.2565L72.0068 32.9289C70.8366 34.3104 69.3353 35.3765 67.6413 36.029C65.481 36.8157 63.1845 37.1661 60.8863 37.0597C58.5881 36.9532 56.3343 36.392 54.257 35.4089C52.1875 34.3647 50.4631 32.752 49.2884 30.7622C48.106 28.7086 47.5023 26.3761 47.5408 24.0102C47.5064 21.6681 48.0926 19.3583 49.2404 17.3127C50.3495 15.3537 51.9874 13.7419 53.9691 12.6592C56.0573 11.5287 58.4038 10.9537 60.7812 10.9899C63.1192 10.9526 65.4267 11.5208 67.4768 12.6387C69.4212 13.7144 71.0165 15.3193 72.0753 17.265C73.216 19.3806 73.7874 21.7535 73.7338 24.1533C73.7338 24.528 73.6996 25.1208 73.6379 25.8907ZM55.9086 17.4217C54.5704 18.5744 53.7087 20.1786 53.4894 21.9253H68.0251C67.8321 20.1719 66.9769 18.5566 65.6324 17.4058C64.2878 16.255 62.554 15.6543 60.7812 15.7252C59.0025 15.6759 57.2678 16.2799 55.9086 17.4217Z" />
        <path d="M97.2128 13.6267C99.214 15.4436 100.215 18.1689 100.215 21.8027V36.7103H94.5743V33.6102C93.8048 34.7541 92.7096 35.6437 91.4287 36.1652C89.8682 36.7881 88.196 37.0872 86.5149 37.0441C84.7852 37.0806 83.0676 36.7484 81.4778 36.0698C80.1311 35.5027 78.9705 34.573 78.1266 33.3854C77.3447 32.2206 76.9361 30.8475 76.9547 29.4473C76.912 28.3904 77.1199 27.3384 77.5615 26.3762C78.0032 25.414 78.6663 24.5685 79.4972 23.908C81.2105 22.5454 83.9061 21.8527 87.584 21.83H94.2043V21.4484C94.2511 20.6916 94.1312 19.9337 93.8531 19.2276C93.5749 18.5215 93.1451 17.8843 92.5938 17.3604C91.5247 16.4065 89.9256 15.9296 87.7965 15.9296C86.3531 15.9355 84.9195 16.1654 83.5475 16.6109C82.2641 17.0004 81.0681 17.6319 80.0249 18.471L77.7154 14.1036C79.172 13.0348 80.8146 12.2425 82.5606 11.7666C84.5114 11.2183 86.53 10.9454 88.5572 10.9559C92.331 10.9559 95.2163 11.8461 97.2128 13.6267ZM91.7303 31.6548C92.8776 30.9968 93.754 29.9566 94.2043 28.7182V25.7885H88.0364C84.5823 25.7885 82.8553 26.9241 82.8553 29.1952C82.8396 29.6976 82.9497 30.196 83.1758 30.6455C83.4019 31.0951 83.7369 31.4817 84.1506 31.7706C85.2099 32.453 86.4612 32.7801 87.7211 32.704C89.1306 32.7334 90.5214 32.3801 91.744 31.682L91.7303 31.6548Z" />
        <path :style="{ fill: cColor }" d="M126.037 36.3492C123.807 35.2013 121.947 33.4515 120.671 31.3005C119.371 29.0789 118.705 26.5464 118.746 23.9761C118.703 21.4122 119.369 18.8859 120.671 16.6722C121.947 14.538 123.796 12.7993 126.01 11.6508C128.413 10.4133 131.089 9.79047 133.795 9.83846C136.302 9.78265 138.783 10.3518 141.012 11.4941C143.033 12.5548 144.664 14.2243 145.672 16.2634L140.669 19.1659C139.946 17.9617 138.915 16.9696 137.681 16.2907C136.072 15.4793 134.251 15.1806 132.466 15.4354C130.68 15.6902 129.017 16.4861 127.703 17.7147C126.891 18.5453 126.26 19.5325 125.847 20.6153C125.435 21.6981 125.25 22.8536 125.304 24.0102C125.25 25.1635 125.432 26.3158 125.839 27.397C126.247 28.4781 126.872 29.4655 127.675 30.2989C128.999 31.5225 130.671 32.31 132.461 32.5539C134.252 32.7978 136.075 32.4861 137.681 31.6616C138.916 30.9872 139.948 29.9969 140.669 28.7932L145.672 31.6888C144.628 33.7204 142.982 35.3849 140.957 36.4582C138.753 37.6196 136.289 38.2035 133.795 38.1547C131.099 38.2026 128.432 37.582 126.037 36.3492Z" />
        <path d="M182.111 37.3099C181.356 37.9076 180.486 38.3457 179.555 38.5976C178.518 38.8878 177.445 39.0322 176.368 39.0268C173.553 39.0268 171.379 38.2956 169.844 36.833C168.308 35.3704 167.541 33.24 167.541 30.442V18.178H163.319V13.4087H167.541V7.59689H173.538V13.4155H180.391V18.1848H173.538V30.299C173.465 31.327 173.791 32.3436 174.449 33.1401C174.794 33.479 175.208 33.7411 175.663 33.909C176.118 34.077 176.603 34.147 177.087 34.1144C178.295 34.1588 179.481 33.7882 180.446 33.0652L182.111 37.3099Z" />
        <path d="M187.34 8.00568C186.983 7.68309 186.699 7.28806 186.509 6.84728C186.319 6.4065 186.226 5.93025 186.236 5.45068C186.225 4.972 186.317 4.4965 186.508 4.05671C186.698 3.61691 186.982 3.2232 187.34 2.90249C187.706 2.56026 188.137 2.29324 188.607 2.1167C189.078 1.94017 189.578 1.85758 190.081 1.87368C191.087 1.84331 192.067 2.19401 192.822 2.8548C193.181 3.15509 193.468 3.531 193.662 3.95525C193.857 4.3795 193.954 4.84146 193.946 5.3076C193.957 5.79946 193.867 6.28835 193.681 6.74417C193.494 7.19999 193.216 7.61307 192.863 7.95799C192.503 8.31201 192.075 8.58943 191.603 8.77333C191.132 8.95724 190.628 9.04379 190.122 9.02769C189.613 9.04863 189.106 8.96909 188.628 8.79366C188.15 8.61824 187.712 8.35042 187.34 8.00568ZM187.052 13.2247H193.048V38.693H187.052V13.2247Z" />
        <path d="M224.902 13.2247L213.937 38.693H207.769L196.804 13.2247H203.034L210.997 32.1113L219.221 13.2247H224.902Z" />
        <path d="M251.904 27.8666H231.804C232.099 29.6517 233.083 31.2523 234.545 32.3293C236.16 33.4647 238.105 34.0416 240.082 33.9713C241.375 34.0162 242.662 33.7935 243.863 33.3172C245.064 32.841 246.152 32.1218 247.059 31.2051L250.273 34.8775C249.103 36.259 247.602 37.3252 245.908 37.9776C243.747 38.7644 241.451 39.1148 239.153 39.0083C236.854 38.9018 234.601 38.3406 232.523 37.3576C230.455 36.3114 228.732 34.6961 227.561 32.7041C226.374 30.6523 225.768 28.3189 225.807 25.952C225.777 23.61 226.37 21.3017 227.527 19.2613C228.63 17.2996 230.266 15.6868 232.249 14.6078C234.334 13.4829 236.675 12.9103 239.047 12.9454C241.386 12.9071 243.693 13.4754 245.743 14.5942C247.69 15.6694 249.287 17.2742 250.348 19.2204C251.484 21.3378 252.053 23.7098 252 26.1087C252 26.5175 251.973 27.1035 251.904 27.8666ZM234.182 19.4044C232.839 20.5543 231.974 22.1595 231.756 23.908H246.291C246.107 22.1793 245.281 20.5809 243.975 19.4248C242.608 18.2571 240.848 17.6462 239.047 17.7147C237.272 17.6633 235.539 18.2649 234.182 19.4044Z" />
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "Emblem",
    data() {
      return {}
    },
    props: ["fillColor", "fontColor", "cColor"]
  }
</script>
