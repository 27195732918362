<template>
  <div class="gallery-container">
    <div class="title" ref="titleWrapper">
      <svg class="graphic" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <defs>
          <linearGradient id="gradient7" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%"   stop-color="#AFDBE7"/>
            <stop offset="100%" stop-color="#1FA19E"/>
          </linearGradient>
        </defs>
        <path ref="graphicLine1" :d="'M160,80 L 300,80 l 70,-70 70,70 L ' + (Math.min(1000, meta.viewport.width) - 100) + ',80 l 0,90'" style="stroke: url(#gradient7); fill: none; stroke-width: 5px; stroke-linecap: round; stroke-linejoin: round;"/>
        <path class="mobile" :d="'M150,80 L 230,80 l ' + Math.min(60, meta.viewport.width - 360) + ',-' + Math.min(60, meta.viewport.width - 360) + ' ' + Math.min(60, meta.viewport.width - 360) + ',' + Math.min(60, meta.viewport.width - 360) + ' L ' + (Math.min(1000, meta.viewport.width) - 50) + ',80 l 0,90'" style="stroke: url(#gradient7); fill: none; stroke-width: 5px; stroke-linecap: round; stroke-linejoin: round;"/>
      </svg>
      <div class="text" ref="textWrapper">
        <span>{{ content.title[0] }}</span>
        <span>{{ content.title[1] }}</span>
      </div>
    </div>
    <div class="video-wrapper">
      <iframe ref="videoFrame" :src="content.video" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

    </div>

  </div>
</template>

<style scoped>
  .gallery-container {
    max-width: 1000px;
    margin: auto;
  }
  .title {
    font-size: 2em;
    font-weight: 700;
    position: relative;
    padding: 0 20px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 600px) {
    .title {
      font-size: 1.7em;
    }
  }
  .title .graphic {
    position: absolute;
    z-index: 1;
  }
  .title .graphic .mobile {
    opacity: 0;
  }
  @media screen and (max-width: 600px) {
    .title .graphic :not(.mobile) {
      opacity: 0;
    }
    .title .graphic .mobile {
      opacity: 1;
    }
  }
  .title .text {

    position: relative;
    z-index: 2;
    padding: 60px 10px 60px 10px;
  }
  .title .text > span {
    display: block;
  }
  .title .text > span:nth-child(1) {
    color: #7e7e7e;
  }
  .title .text > span:nth-child(2) {
    color: #47A9C9;
  }
  .video-wrapper {
    text-align: center;
  }
  .video-wrapper > iframe {
    display: inline-block;
    max-width: 1000px;
    width: 100%;
    --width: calc(100vw - 60px);
    width: var(--width);
    border-radius: 10px;
    overflow: hidden;
    height: calc(min(1000px, var(--width)) * 0.5625);
  }
  @media screen and (max-width: 600px) {
    .video-wrapper > iframe {
      --width: calc(100vw - 20px);
    }
  }
</style>

<script>
  import anime from 'animejs'
  import TimelineScrolling from '../../helpers/timelineScrolling.js'

  import content from '@/../content/create.yaml'

  export default {
    name: "FilmGallery",
    data() {
      return {
        content: content['gallery'],
        meta: {
          viewport: {
            width: window.innerWidth,
            height: window.innerHeigt
          }
        }
      }
    },
    mounted() {
      const tl = anime.timeline({
        autoplay: false,
        loop: false
      });
      tl.add({
        targets: this.$refs.textWrapper,
        opacity: [0, 1],
        translateX: ['-50%', 0],
        duration: 700,
        easing: 'spring'
      });
      tl.add({
        targets: this.$refs.graphicLine1,
        'stroke-dashoffset': [900, 0],
        'stroke-dasharray': [900, 900],
        duration: 1000,
        easing: 'linear'
      });
      tl.add({
        targets: this.$refs.videoFrame,
        opacity: [0, 1],
        translateX: ['-50%', 0],
        duration: 1000,
        easing: 'spring'
      });


      if (window.innerWidth <= 600) {
        tl.seek(tl.duration);
      }
      else {
        //tl.play();
        //tl.seek(tl.duration);
        const startScrollPos = this.$refs.videoFrame.offsetTop - window.innerHeight / 2;
        const targetScrollPos = this.$refs.videoFrame.offsetTop + this.$refs.videoFrame.offsetHeight - (window.innerHeight * 1) + window.innerHeight;
        console.log(startScrollPos, targetScrollPos, "!");
        new TimelineScrolling(tl, startScrollPos, targetScrollPos, 0, false);
      }
    },
    created() {
      window.addEventListener("resize", () => {
        this.meta.viewport = {
          width: window.innerWidth,
          height: window.innerHeigt
        }
      });
    },
  }
</script>
