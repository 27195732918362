<template>
  <div class="about-detailed-container">
    <div class="header">
      <div class="title">
        <span>{{ content.title }}</span>
      </div>
      <div class="underliner" />
      <div class="company-about">
        <div class="emblem">
          <EmblemCreactive fill-color="#8AD4ED" font-color="#4D4D4D" c-color="#fff" />
        </div>
        <div class="description">
          <article>
            {{ content['emblem-description'] }}
          </article>
        </div>
      </div>
      <div class="underliner underliner-right" />
    </div>
    <div class="body">
      <div class="area" v-for="(articleText, _i) in content.article" :key="_i">
        <article class="article-1">
          {{ articleText }}
        </article>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .about-detailed-container {
    padding: 120px 0 150px 0;
  }
  .about-detailed-container .header {
    display: inline-block;
    width: 100%;
  }
  .about-detailed-container .underliner {
    height: 25px;
    margin: 10px 0 0 0;
    background-image: linear-gradient(to right, #8AD4ED, #FAE191);
    float: left;
    border-radius: 0 15px 15px 0;
    width: calc((100% - 1200px) / 2 + 680px);
    transform: translateY(-0);
    z-index: 0;
    position: relative;
  }
  .about-detailed-container .underliner-right {
    background-image: linear-gradient(to left, #8AD4ED, #FAE191);
    width: calc((100% - 1200px) / 2 + 300px);
    float: right;
    border-radius: 15px 0 0 15px;
    margin: 80px 0 0 0;
  }
  @media screen and (max-width: 1200px) {
    .about-detailed-container .underliner {
      width: calc(680px);
      max-width: calc(100% - 50px);


    }
  }
  .about-detailed-container .header .title {
    max-width: 1200px;
    margin: auto;
    padding: 0 0 0 50px;
    text-align: left;
    z-index: 1;
    position: relative;
    font-weight: 800;
    color: #4D4D4D;
    font-size: 3.5em;
    margin-bottom: 5px;

  }

  @media screen and (max-width: 700px) {
    .about-detailed-container .header .title {
      font-size: 3em;
      padding-left: 30px;
    }
    .about-detailed-container .underliner {
      width: calc(570px);

    }
  }
  @media screen and (max-width: 450px) {
    .about-detailed-container .header .title {
      font-size: 2.5em;
      padding-left: 20px;
    }
  }
  .about-detailed-container .header .title > span {
    display: block;
    max-width: 700px;
  }

  .about-detailed-container .header .company-about {
    margin: 150px 0 0 0;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .about-detailed-container .header .company-about .emblem {
    max-width: 685px;
    margin: auto;
  }
  .about-detailed-container .header .company-about .description {
    max-width: 685px;
    margin: auto;
    text-align: center;
    font-size: 1.3em;
    letter-spacing: 0.01em;
    line-height: 130%;
    padding: 30px 0 0 0;
  }
  .about-detailed-container .body {
    display: flex;
    max-width: 1200px;
    margin: 80px auto 30px;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .about-detailed-container .body .area {
    flex: 1;
    padding: 0 40px;
    font-size: 1.3em;
    font-weight: 300;
    letter-spacing: 0.02em;
    line-height: 120%;
  }
  @media screen and (max-width: 600px) {
    .about-detailed-container .body {
      display: block;
    }
    .about-detailed-container .body .area + .area {
      margin-top: 50px;
    }
  }
</style>


<script>
  import content from '@/../content/purpose.yaml'
  import EmblemCreactive from '@/assets/Emblem.vue'

  export default {
    name: "AboutDetailed",
    data() {
      return {
        content: content['about']
      }
    },
    components: {
      EmblemCreactive
    }
  }
</script>
