<template>
  <div class="page-container">
    <div class="content-inner">
      <Roadmap />
    </div>
    <div class="story-grid">
      <StoryFFFPreview :background="bg1" />
      <StoryMoviesPreview :background="bg2" />
      <ServicesOverview />
      <br><br>
      <StoryPoliticsPreview />
      <StorySciencePreview />


      <PageFooter :offset="-60" :background="footer.background" :logo-fill-color="footer['logo-fill-color']">
        <template v-slot:title>
          {{ footer.title }}
        </template>
        <template v-slot:description>
          <span v-html="footer.description"></span>
        </template>
      </PageFooter>
    </div>
  </div>
</template>

<style scoped>
  @import "../page.css";
  .page-container {
    margin-top: -10vw;
    position: absolute;
    z-index: 5;
    overflow: hidden;
  }
  .content-inner {
    max-width: 1000px;
    margin: auto;
  }
  .story-grid {
    width: 100%;
  }
  .story-grid .story {
    width: 100%;
  }


</style>

<script>
  import Roadmap from './Roadmap.vue';
  import StoryFFFPreview from './StoryFFFPreview.vue';
  import StoryMoviesPreview from './StoryMoviesPreview.vue';
  import StoryPoliticsPreview from './StoryPoliticsPreview.vue';
  import StorySciencePreview from './StorySciencePreview.vue'
  import ServicesOverview from './ServicesOverview.vue';

  import content from '@/../content/work.yaml'

  import PageFooter from '@/components/PageFooter.vue';

  //import StoryView from '@/components/StoryView.vue';
  //import StoryEngagement from '@/stories/StoryEngagement.vue';

  export default {
    name: "Work",
    components: {
      Roadmap,
      StoryFFFPreview,
      StoryMoviesPreview,
      StoryPoliticsPreview,
      StorySciencePreview,
      ServicesOverview,
      PageFooter
    },
    computed: {
    },
    data() {
      return {
        footer: content.footer,
        bg1: {
          svg: `
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
              <g style="fill: rgba(29, 173, 170, 0.1);">
                <text x="-100px" y="170px" style="font-size: 130px; font-family: 'Verdana', 'Arial';">
                  WORK WORK
                </text>
                <text x="-100px" y="320px" style="font-size: 130px; font-family: 'Verdana', 'Arial';">
                  WORK WORK
                </text>
              </g>
            </svg>
          `,
          position: 'left bottom',
          size: '100% 300px'
        },
        bg2: {
          svg: `
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
              <g style="fill: rgba(29, 173, 170, 0.1);">
                <text x="-100px" y="20px" style="font-size: 130px; font-family: 'Verdana', 'Arial';">
                  WORK WORK
                </text>
                <text x="-100px" y="170px" style="font-size: 130px; font-family: 'Verdana', 'Arial';">
                  WORK
                </text>
              </g>
            </svg>
          `,
          position: 'left top',
          size: '100% 300px'
        }
      };
    }
  }
</script>
