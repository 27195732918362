<template>
  <div class="people-power-container">
    <div class="header">
      <h2 class="title">
        <span>
          {{ content.title }}
        </span>
      </h2>
    </div>
    <div class="body">
      <div class="inner">
        <div class="area-avatar">
          <!--<div class="avatar" :style="{ 'background-image': `url('${ data.images.fff1 }')` }" />-->
          <svg class="avatar" xmlns="http://www.w3.org/2000/svg" viewBox="-80 0 380 450">
            <defs>
              <clipPath id="image">
                <rect x="0" y="0" width="300" height="400" rx="15" ry="15"/>
              </clipPath>
              <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" style="stop-color:#8AD4ED;stop-opacity:1" />
                <stop offset="100%" style="stop-color:#FAE191;stop-opacity:1" />
              </linearGradient>
            </defs>

            <rect x="0" y="0" width="300" height="400" fill="url(#grad1)" rx="15" ry="15" style="transform-origin: 150px 200px; transform: translate(-40px, 40px) rotate(-3deg);" />

            <image clip-path="url(#image)" :href="content.image" x="0" y="0" height="400" width="300" />
          </svg>
        </div>
        <div class="area-about">
          <div class="about-text">
            {{ content.description }}

          </div>
          <div class="btn-container">
            <!--<TransitionModifier name="popupmobile" :timeout="500" keyName="routerForcedTransition">

            </TransitionModifier>-->

            <router-link :to="content.button.href">
              <button class="btn">
                {{ content.button.label }}
              </button>
            </router-link>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped>
  .people-power-container {
    padding: 80px 0;
  }
  .people-power-container .header {

  }
  .people-power-container .header .title {
    text-align: right;
    max-width: 1000px;
    margin: auto;
    font-size: 8em;
    font-weight: 900;
    padding: 0 30px;
    color: #BEE1EE;
  }
  .people-power-container .header .title > span {
    display: inline-block;
    max-width: 900px;
  }
  @media screen and (max-width: 1000px) {
    .people-power-container .header .title {
      text-align: right;
      max-width: 1000px;
      margin: auto;
      font-size: 12vw;
      font-weight: 900;
      padding: 0 30px;
    }
  }
</style>

<style scoped>
  .people-power-container .body .inner {
    max-width: 1000px;
    margin: auto;
    padding: 80px 0 100px 0px;
    display: flex;
    background-size: 100% 100%;
  }
  .people-power-container .body .inner > * {
    display: inline-block;

  }
  @media screen and (max-width: 700px) {
    .people-power-container .body .inner {
      display: block;

    }
  }
  @media screen and (max-width: 500px) {
    .story .inner {
      font-size: 0.8em;
    }
  }
  .people-power-container .body .inner .area-avatar {
    margin-top: 50px;
    flex: none;
  }
  .people-power-container .body .inner .area-avatar .avatar {
    width: 400px;
    border-radius: 15px;
    background-position: 25% center;
    background-size: cover;
  }
  @media screen and (max-width: 1000px) {
    .people-power-container .body .inner .area-avatar .avatar {
      width: 350px;
    }
  }
  @media screen and (max-width: 700px) {
    .people-power-container .body .inner .area-avatar {
      width: 100%;
      text-align: center;
    }
    .people-power-container .body .inner .area-avatar  .avatar {
      width: 500px;
      max-width: calc(100% - 50px);
      display: inline-block;
      transform: translateX(-7%);
    }
  }
  .people-power-container .body .inner .area-avatar .avatar {

  }
  .people-power-container .body .inner .area-avatar .shadow {

  }
  .people-power-container .body .inner .area-about {
    color: #4D4D4D;
    flex: 1;
    font-size: 1.3em;
    padding: 20px 20px 0 60px;
  }
  @media screen and (max-width: 900px) {
    .people-power-container .body .inner .area-about {
      padding-left: 30px;
    }
  }

  .people-power-container .body .inner .area-about .quote {
    font-weight: 500;
    display: flex;
  }

  .people-power-container .body .inner .area-about .quote .quote-symbol {
    width: 40px;
    height: 40px;
    flex: none;
  }
  .people-power-container .body .inner .area-about .quote > * {
    display: inline-block;
  }
  .people-power-container .body .inner .area-about .quote .quote-symbol > svg {
    width: 100%;
    height: 100%;
  }
  .people-power-container .body .inner .area-about .quote .quote-text {
    font-size: 1.6em;
    flex: 1;
    letter-spacing: 0.5px;
    padding: 0 0 0 10px;
  }
  .people-power-container .body .inner .area-about .about-text {
    margin: 30px 0 0 0;
    letter-spacing: 0.025em;
    line-height: 140%;
    word-wrap: break-word;
  }
  @media screen and (max-width: 700px) {
    .people-power-container .body .inner .area-about {
      padding-top: 60px;
    }
    .people-power-container .body .inner .area-about > * {
      width: calc(100% - 30px);
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .people-power-container .body .inner .area-about .quote .quote-text {
      font-size: 2.5em;

    }
    .people-power-container .body .inner .area-about .about-text {
      font-size: 1.1em;

    }
  }
  @media screen and (max-width: 400px) {
    .people-power-container .body .inner .area-about .quote .quote-text {
      font-size: 2em;
    }
    .people-power-container .body .inner .area-about .about-text {
      font-size: 1.05em;

    }

  }
  @media screen and (max-width: 350px) {
    .people-power-container .body .inner .area-about .quote .quote-text {
      font-size: 1.5em;
    }
    .people-power-container .body .inner .area-about .about-text {
      font-size: 1em;

    }
  }
  .people-power-container .btn {
    outline: none;
    font-size: 1.6rem;
    background-image: linear-gradient(to left, #58C8ED, #FAE191);
    border: none;
    border-radius: 13px;
    padding: 15px 0;
    text-align: center;
    min-width: 270px;
    font-family: 'Montserrat';
    font-weight: 800;
    display: inline-block;
    cursor: pointer;
    color: #fff;
  }
  .btn {
    transition: all 0.3s;
    transform: scale(1);
  }
  .btn:hover {
    transform: scale(1.05);
  }
  .people-power-container .body .inner .area-about .btn-container {
    padding: 100px 40px 0 0;
    float: right;
  }
  @media screen and (max-width: 700px) {
    .people-power-container .btn {
      font-size: 1.4rem;
    }
    .people-power-container .body .inner .area-about .btn-container {
      padding: 50px 0 0 0;
      float: none;
      text-align: center;
    }
  }
  @media screen and (max-width: 400px) {
    .people-power-container .btn {
      min-width: 100%;
    }
  }
</style>

<script>
  import content from '@/../content/purpose.yaml'

  export default {
    name: "PowerToThePeople",
    data() {
      return {
        content: content['power']
      }
    }
  }
</script>
