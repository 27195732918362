<template>
  <div class="wrapper">
    <div class="title">
      <div class="line">
        {{ content.title }}
      </div>
      <div class="underline" />
    </div>
  </div>
  <article v-html="marked(content.article)"></article>
  <PageFooter :background="content.footer.background" :logo-fill-color="content.footer['logo-fill-color']" :hide-contact="content.footer['hide-contact']">
    <template v-slot:title>
      {{ content.footer.title }}
    </template>
    <template v-slot:description>
      <span v-html="content.footer.description"></span>
    </template>
  </PageFooter>
</template>

<style scoped>
  .wrapper {
    white-space: normal;
  }
  h1, h2, h3 {
    margin: 0;
  }
  .title {
    font-weight: 800;
    color: #333;
    font-size: 3em;
    word-wrap: break-word;
  }
  .title .line {
    margin: 0 0 0 80px;
  }
  .title .line span {
  }
  .title .underline {
    width: 500px;
    max-width: calc(100% - 100px);
    height: 15px;
    border-radius: 0 8px 8px 0;
    background-color: #f00;
    margin: 20px 0;
    background-image: linear-gradient(to left, #58C8ED, #FAE191);
  }
  @media screen and (max-width: 800px) {
    .title {
      font-size: 2.5em;
    }
    .title .line {
      margin: 0 0 0 50px;
    }
  }
  @media screen and (max-width: 600px) {
    .title {
      font-size: 2em;
    }
    .title .line {
      margin: 0 0 0 30px;
    }
  }
  article {
    box-sizing: border-box;
    padding: 10px;
    width: calc(100% - 30px);
    max-width: 1300px;
    margin: auto;
    box-sizing: border-box;
    white-space: normal;
    color: #333;
  }
  article >>> h2 {
    margin: 40px 0 10px;
    font-size: 1.2em;
  }
  article >>> h3 {
    margin: 20px 0 10px;
    font-size: 1.1em;
  }
</style>

<script>
  import marked from 'marked'
  import content from '@/../content/datenschutz.yaml'
  import PageFooter from '@/components/PageFooter.vue';
  export default {
    name: "Datenschutz",
    components: { PageFooter },
    methods: {
      marked() {
        return marked(...arguments);
      }
    },
    data() {
      return {
        content
      }
    }
  }
</script>
