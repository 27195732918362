<template>
  <div class="page-container">
    <Introduction />
    <AboutDescription />
    <AboutDetailed />
    <FlyNiceBanner />
    <PowerToThePeople />

    <PageFooter :offset="-60" :background="footer.background" :logo-fill-color="footer['logo-fill-color']">
      <template v-slot:title>
        {{ footer.title }}
      </template>
      <template v-slot:description>
        <span v-html="footer.description"></span>
      </template>
    </PageFooter>
  </div>
</template>

<style scoped>
  @import "../page.css";
  .page-container {
    padding-top: 40px;
    margin-top: -10vw;
    position: absolute;
    z-index: 2;
  }
  .content-inner {
    max-width: 1000px;
    margin: auto;
  }
</style>

<script>
  import Introduction from './Introduction.vue';
  import AboutDescription from './AboutDescription.vue';
  import AboutDetailed from './AboutDetailed.vue';
  import FlyNiceBanner from './FlyNiceBanner.vue';
  import PowerToThePeople from './PowerToThePeople.vue';

  import content from '@/../content/purpose.yaml'

  import PageFooter from '@/components/PageFooter.vue';


  export default {
    name: "PagePurpose",
    data() {
      return {
        footer: content.footer
      }
    },
    components: {
      Introduction,
      AboutDescription,
      AboutDetailed,
      FlyNiceBanner,
      PowerToThePeople,
      PageFooter
    }
  }
</script>
