<template>
  <div class="test-wrapper" ref="testWrapper"/>
  <PageHeader />
  <div class="page-view">
    <router-view v-if="complexTransitionsLaggy || $route.meta.transition == null"></router-view>
    <transition v-else :name="currentRouteTransition">
      <component :is="$route.meta.page" />
    </transition>

    <!--<router-view></router-view>-->
  </div>
</template>

<script>
  import { detect } from 'detect-browser'
  import PageHeader from './components/PageHeader.vue'
  import Emitter from 'tiny-emitter'

  export default {
    name: 'App',
    computed: {
      currentRouteTransition() {
        if (this.routerForcedTransition) {
          return this.routerForcedTransition;
        }
        return this.$route.meta.transition || ' ';
      },
      complexTransitionsLaggy() {
        return (detect().name == "safari") || (detect().name == "ios");
      }
    },
    components: {
      PageHeader
    },
    methods: {
      updateSoundSettings(value) {
        const videosWithAutoplay = document.querySelectorAll("video");
        console.log(videosWithAutoplay);
        if (videosWithAutoplay[0]) {
          videosWithAutoplay[0].muted = !value;
        }
      }
    },
    data() {
      return {
        events: new Emitter,
        routerForcedTransition: null,
        routerBugCanHappen: false,
        routerCalls: 0
      }
    },
    mounted() {


    },
    watch: {
      $route() {
        /*Sconst testCSSValue = window.getComputedStyle(this.$refs.testWrapper)["content"];
        console.log(testCSSValue);
        if (this.routerBugCanHappen && testCSSValue != '"loaded"') {
          //location.reload();
        }

        if (this.routerCalls >= 4) {
          location.reload();
        }

        setTimeout(() => {
          this.routerBugCanHappen = true;
        }, 1000);

        this.routerCalls++;*/
      }
    }
  }
</script>
<style media="screen">
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  body, html {
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: 'Montserrat', 'Avenir', Helvetica, Arial, sans-serif;
  }
  #app {
    font-family: 'Montserrat', 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>
<style scoped>

  .test-wrapper {
    display: none;
    content: "loaded";
  }

  .page-view {
    width: 100%;
    overflow: hidden;
    display: block;
    white-space: nowrap;
  }
  .page-view > .page-container {
    width: 100%;
    display: inline-block;
    white-space: normal;
    vertical-align: top;
    overflow: hidden;
  }

  .slide-enter-active:not(:only-child),
  .slide-leave-active:not(:only-child) {
    transition: transform 0s ease;
    transform-origin: 50% 50%;
  }
  .slide-leave-active:not(:only-child) {

    /*transform: translateX(0%) rotate(0deg);*/
  }
  .slide-enter-active:not(:only-child) {

    /*transform: translateX(-100%) rotate(90deg);*/

  }
  .slide-leave-to {
    transform: translateX(-100%);
    /*transform: translateX(0%) rotate(-90deg) !important;*/
  }
  .slide-enter-to {
    transform: translateX(-100%);
    /*transform: translateX(-100%) rotate(0deg) !important;*/
  }




  .popup-enter-active:not(:only-child),
  .popup-leave-active:not(:only-child) {
    transition: transform 1s ease;
    transform-origin: 50% 50%;
  }
  .popup-enter-active {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 100;

    /*transform: translateX(-100%) rotate(90deg);*/

  }
  .popup-leave-to {
    /*transform: translateX(-0%);*/
    /*transform: translateX(0%) rotate(-90deg) !important;*/
  }
  .popup-enter-from {
    transform: translateX(105%);
    opacity: 0;
  }
  .popup-enter-to {
    transform: translateX(0%);
    opacity: 1;
    /*transform: translateX(-100%) rotate(0deg) !important;*/
  }



  /*

  .popup-enter-active:not(:only-child),
  .popup-leave-active:not(:only-child) {
    transition: all 10s cubic-bezier(.15,.52,.53,1.14);
    transform-origin: 50% 50%;
    filter: blur(10px);
  }
  .popup-leave-active:not(:only-child) {

  }
  .popup-enter-active:not(:only-child) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 100;
    transform: scale(0);
    opacity: 0;

  }
  .popup-leave-to {

  }
  .popup-enter-to {
    transform: scale(1) !important;
    opacity: 1 !important;
  }

  */




  .popout-enter-active:not(:only-child),
  .popout-leave-active:not(:only-child) {
    transition: all 0.5s cubic-bezier(.15,.52,.53,1.14);
    transform-origin: 50% 50%;
  }
  .popout-leave-active:not(:only-child) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 100;
    transform: translateX(0);
    opacity: 1;
    z-index: 9;
    /*transform: translateX(0%) rotate(0deg);*/
  }
  .popout-enter-active:not(:only-child) {
    /*transform: translateX(-100%) rotate(90deg);*/

  }
  .popout-leave-to {
    transform: translateX(100%) !important;
    opacity: 0 !important;
    /*transform: translateX(0%) rotate(-90deg) !important;*/
  }
  .popout-enter-to {

    /*transform: translateX(-100%) rotate(0deg) !important;*/
  }
</style>
