<template>
  <div class="introduction-container">
    <div class="header">
      <div class="title">
        <span>{{ content.title }}</span>
      </div>
      <div class="underliner" />
    </div>
    <div class="body">
      <div class="item-1">
        <article class="article-1">
          <p>
            {{ content['paragraph-1'] }}
          </p>
        </article>
      </div>
      <div class="item-2">
        <article class="article-2">
          <p>
            {{ content['paragraph-2'] }}
          </p>
        </article>
      </div>
      <div class="item-3">
        <div class="vector-img">
          <img :src="content['image-1']" alt="">
        </div>
      </div>
      <div class="item-4">
        <div class="cover-image" :style="{ 'background-image': `url('${ content['image-2'] }')` }" />
      </div>
      <div class="item-5">
        <article class="article-3">
          <p>
            {{ content['paragraph-3'] }}
          </p>
        </article>
      </div>
      <div class="item-6">
        <div class="btn-container">
          <a :href="content.button.href">
            <button class="btn">
              {{ content.button.label }}
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .introduction-container {
    padding:  20px 0 150px 0;
  }
  .introduction-container .header {
    display: inline-block;
    width: 100%;
  }
  .introduction-container .underliner {
    height: 25px;
    margin: 10px 0 0 0;
    background-image: linear-gradient(to left, #8AD4ED, #FAE191);
    float: left;
    border-radius: 0 15px 15px 0;
    width: calc((100% - 1200px) / 2 + 640px);
    transform: translateY(-0);
    z-index: 0;
    position: relative;
  }
  @media screen and (max-width: 1200px) {
    .introduction-container .underliner {
      width: calc(670px);
      max-width: calc(100% - 50px);

    }
  }
  .introduction-container .header .title {
    max-width: 1200px;
    margin: auto;
    padding: 0 0 0 50px;
    text-align: left;
    z-index: 1;
    position: relative;
    font-weight: 800;
    color: #4D4D4D;
    font-size: 3.5em;
    margin-bottom: 5px;

  }

  @media screen and (max-width: 700px) {
    .introduction-container .header .title {
      font-size: 3em;
      padding-left: 30px;
    }
    .introduction-container .underliner {
      width: calc(570px);

    }
  }
  @media screen and (max-width: 450px) {
    .introduction-container .header .title {
      font-size: 2.5em;
      padding-left: 20px;
    }
  }
  .introduction-container .header .title > span {
    display: block;
    max-width: 700px;
  }
</style>


<style scoped>
  .introduction-container .body {
    display: grid;
    grid-template-columns: auto 100px 500px;
    grid-auto-rows: minmax(min-content, max-content) minmax(min-content, max-content) 100px minmax(min-content, max-content) minmax(min-content, max-content);
    max-width: 1200px;
    margin: auto;
    padding: 0 20px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 900px) {
    .introduction-container .body {
      grid-template-columns: auto 100px 400px;
    }
  }
  @media screen and (max-width: 750px) {
    .introduction-container .body {
      grid-template-columns: auto 100px 350px;
    }
  }
  @media screen and (max-width: 700px) {
    .introduction-container .body {
      grid-template-columns: 100%;
      grid-template-rows: repeat(6, minmax(min-content, max-content));
    }
  }
  .introduction-container .body .item-1 {
    grid-column: 1 / span 2;
    grid-row: 1 / span 1;
  }
  .introduction-container .body .item-2 {
    grid-column: 1 / span 2;
    grid-row: 2 / span 1;
  }
  .introduction-container .body .item-3 {
    grid-column: 3 / span 1;
    grid-row: 1 / span 2;
  }
  .introduction-container .body .item-4 {
    grid-column: 1 / span 1;
    grid-row: 4 / span 2;
  }
  .introduction-container .body .item-5 {
    grid-column: 3 / span 1;
    grid-row: 4 / span 1;
  }
  .introduction-container .body .item-6 {
    grid-column: 3 / span 1;
    grid-row: 5 / span 1;
  }

  @media screen and (max-width: 700px) {
    .introduction-container .body .item-1 {
      grid-column: 1 / span 1;
      grid-row: 1 / span 1;
      max-width: 450px;
      margin: auto;
    }
    .introduction-container .body .item-2 {
      grid-column: 1 / span 1;
      grid-row: 3 / span 1;
      max-width: 450px;
      margin: auto;
    }
    .introduction-container .body .item-3 {
      grid-column: 1 / span 1;
      grid-row: 2 / span 1;
      max-width: 500px;
      margin: auto;
    }
    .introduction-container .body .item-4 {
      grid-column: 1 / span 1;
      grid-row: 4 / span 1;
      padding: 40px 0;
    }
    .introduction-container .body .item-5 {
      grid-column: 1 / span 1;
      grid-row: 5 / span 1;

    }
    .introduction-container .body .item-6 {
      grid-column: 1 / span 1;
      grid-row: 6 / span 1;
    }
  }


  .vector-img {
    width: 100%;
  }
  .vector-img > img {
    width: 100%;
  }

  .cover-image {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 700px) {
    .cover-image {
      width: 100%;
      height: 600px;
      max-width: 400px;
      margin: auto;
    }
  }
  @media screen and (max-width: 400px) {
    .cover-image {
      width: 100%;
      height: 130vw;
    }
  }


  .body > div > article {
    margin-top: 20px;
    font-size: 1.35em;;
  }
  @media screen and (max-width: 900px) {
    .body > div > article {
      margin-top: 30px;
      font-size: 1.25em;;
    }
  }
  .body > div > article p {

  }

  .article-1 {
    padding-top: 50px;
  }
</style>

<style scoped>
  .btn-container {
    text-align: right;
    margin: 60px 0 0 0;
    padding-right: 30px;
  }
  @media screen and (max-width: 600px) {
    .btn-container {
      text-align: center;
      margin: 30px 0 0 0;
    }
  }
  .btn {
    outline: none;
    font-size: 1.6rem;
    color: #fff;
    background-image: linear-gradient(to left, #58C8ED, #FAE191);
    border: none;
    border-radius: 13px;
    padding: 15px 0;
    text-align: center;
    min-width: 270px;
    font-family: 'Montserrat';
    font-weight: 800;
    display: inline-block;
    cursor: pointer;

  }
  .btn {
    transition: all 0.3s;
    transform: scale(1);
  }
  .btn:hover {
    transform: scale(1.05);
  }
  @media screen and (max-width: 400px) {
    .btn {
      min-width: 100%;
    }
  }
</style>

<script>
  import content from '@/../content/purpose.yaml'


  export default {
    name: "Introduction",
    data() {
      return {
        content: content['intro']
      }
    }
  }
</script>
