<template>
  <div class="cover-wrapper" data-name="work" style="z-index: 0;">
    <div class="graphic-inner">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="30 0 130 100">

        <rect style="display: none;" x="30" y="0" width="100%" height="100%" fill="red"/>


        <image x="28" y="5" :href="flowerGraphic" width="65"/>



        <g style="transform-origin: 113.5px 43px; transform: rotate(45deg);">
          <svg x="110px" y="39.5px" width="7px" height="7px" viewBox="0 0 1120 1420" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
            <g>
              <g>
                <path d="M375.639,712.768c-450.621,182.112 -480.488,480.575 -171.537,643.266c442.24,232.881 970.006,-229.773 861.975,-467.44c-128.653,-283.036 -466.755,-266.224 -690.438,-175.826Z" style="fill:#ffb543;"/>
                <path d="M624.625,655.331c-87.692,5.484 -174.656,27.398 -248.986,57.437c-68.314,27.609 -126.958,57.891 -176.18,89.945c0.428,67.881 19.31,328.974 266.237,609.071c2.356,2.672 4.709,5.304 7.062,7.899c161.088,-2.745 314.634,-71.789 427.076,-164.272l-1.006,-2.299c0,-0 -258.465,-216.094 -274.203,-597.781Z"/>
              </g>
              <path d="M401.37,828.556c-0,0 -343.075,34.308 -124.365,-566.073c124.426,-341.563 269.156,-286.678 335.458,-185.181c50.24,76.909 42.667,167.8 20.482,206.623c-53.191,93.085 -68.745,157.402 60.038,-0c192.98,-235.864 749.493,-110.093 171.538,321.633c-355.94,265.883 -308.768,42.884 -463.151,222.998Z" style="fill:#c3eff2;"/>
            </g>
          </svg>
        </g>

        <g>
          <svg x="80px" y="30px" width="7px" height="7px" viewBox="0 0 1120 1420" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
            <g>
              <g>
                <path d="M375.639,712.768c-450.621,182.112 -480.488,480.575 -171.537,643.266c442.24,232.881 970.006,-229.773 861.975,-467.44c-128.653,-283.036 -466.755,-266.224 -690.438,-175.826Z" style="fill:#ffb543;"/>
                <path d="M624.625,655.331c-87.692,5.484 -174.656,27.398 -248.986,57.437c-68.314,27.609 -126.958,57.891 -176.18,89.945c0.428,67.881 19.31,328.974 266.237,609.071c2.356,2.672 4.709,5.304 7.062,7.899c161.088,-2.745 314.634,-71.789 427.076,-164.272l-1.006,-2.299c0,-0 -258.465,-216.094 -274.203,-597.781Z"/>
              </g>
              <path d="M401.37,828.556c-0,0 -343.075,34.308 -124.365,-566.073c124.426,-341.563 269.156,-286.678 335.458,-185.181c50.24,76.909 42.667,167.8 20.482,206.623c-53.191,93.085 -68.745,157.402 60.038,-0c192.98,-235.864 749.493,-110.093 171.538,321.633c-355.94,265.883 -308.768,42.884 -463.151,222.998Z" style="fill:#c3eff2;"/>
            </g>
          </svg>
        </g>

        <g style="transform-origin: 138.5px 25.5px; transform: rotate(20deg);">
          <svg x="135px" y="22px" width="7px" height="7px" viewBox="0 0 1120 1420" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
            <g>
              <g>
                <path d="M375.639,712.768c-450.621,182.112 -480.488,480.575 -171.537,643.266c442.24,232.881 970.006,-229.773 861.975,-467.44c-128.653,-283.036 -466.755,-266.224 -690.438,-175.826Z" style="fill:#ffb543;"/>
                <path d="M624.625,655.331c-87.692,5.484 -174.656,27.398 -248.986,57.437c-68.314,27.609 -126.958,57.891 -176.18,89.945c0.428,67.881 19.31,328.974 266.237,609.071c2.356,2.672 4.709,5.304 7.062,7.899c161.088,-2.745 314.634,-71.789 427.076,-164.272l-1.006,-2.299c0,-0 -258.465,-216.094 -274.203,-597.781Z"/>
              </g>
              <path d="M401.37,828.556c-0,0 -343.075,34.308 -124.365,-566.073c124.426,-341.563 269.156,-286.678 335.458,-185.181c50.24,76.909 42.667,167.8 20.482,206.623c-53.191,93.085 -68.745,157.402 60.038,-0c192.98,-235.864 749.493,-110.093 171.538,321.633c-355.94,265.883 -308.768,42.884 -463.151,222.998Z" style="fill:#c3eff2;"/>
            </g>
          </svg>
        </g>

        <svg x="85px" width="60px" viewBox="0 0 660 171" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g>
            <path d="M659.133,55.264l-653.477,-32.1l-5.656,115.134l653.478,32.101l5.655,-115.135Z" style="fill:#2cc1be;" />
            <g>
              <path d="M253.036,8.059l-42.979,134.309l-48.544,-0l-21.681,-71.76l-22.833,71.76l-48.543,-0l-42.979,-134.309l46.625,-0l23.408,76.364l24.751,-76.364l41.636,-0l23.408,77.323l24.752,-77.323l42.979,-0Z" style="fill:#fff;fill-rule:nonzero;" />
              <path
                d="M309.445,144.287c-11.64,-0 -22.097,-2.335 -31.37,-7.004c-9.274,-4.668 -16.533,-11.128 -21.778,-19.379c-5.244,-8.25 -7.866,-17.62 -7.866,-28.109c-0,-10.489 2.622,-19.858 7.866,-28.109c5.245,-8.25 12.504,-14.678 21.778,-19.283c9.273,-4.605 19.73,-6.907 31.37,-6.907c11.769,0 22.289,2.302 31.563,6.907c9.274,4.605 16.501,11.033 21.682,19.283c5.18,8.251 7.77,17.62 7.77,28.109c0,10.489 -2.59,19.859 -7.77,28.109c-5.181,8.251 -12.408,14.711 -21.682,19.379c-9.274,4.669 -19.794,7.004 -31.563,7.004Zm0,-33.77c4.989,0 9.082,-1.822 12.28,-5.468c3.198,-3.645 4.797,-8.73 4.797,-15.254c-0,-6.523 -1.599,-11.576 -4.797,-15.157c-3.198,-3.582 -7.291,-5.373 -12.28,-5.373c-4.988,0 -9.081,1.791 -12.279,5.373c-3.198,3.581 -4.797,8.634 -4.797,15.157c-0,6.524 1.599,11.609 4.797,15.254c3.198,3.646 7.291,5.468 12.279,5.468Z"
                style="fill:#fff;fill-rule:nonzero;" />
              <path d="M425.335,48.735c7.675,-8.826 18.995,-13.239 33.961,-13.239l0,38.374c-3.453,-0.511 -6.651,-0.767 -9.593,-0.767c-14.838,-0 -22.257,7.547 -22.257,22.64l-0,46.625l-43.363,-0l0,-104.953l41.252,-0l0,11.32Z"
                style="fill:#fff;fill-rule:nonzero;" />
              <path d="M521.846,110.134l-6.907,7.483l-0,24.751l-43.363,-0l0,-142.368l43.363,0l-0,67.922l30.124,-30.507l51.229,-0l-43.363,45.473l46.817,59.48l-52.381,-0l-25.519,-32.234Z" style="fill:#fff;fill-rule:nonzero;" />
            </g>
          </g>
        </svg>




      </svg>
    </div>
  </div>
</template>


<style scoped>
  .cover-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
  }
  .cover-wrapper .graphic-inner {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .cover-wrapper .graphic-inner > svg {
    height: 100%;
    width: 100%;
  }

</style>

<script>
  import FlowerGraphic from '@/assets/cover/flower.png';

  export default {
    name: "CoverWork",
    data() {
      return {
        flowerGraphic: FlowerGraphic
      };
    }
  }
</script>
