<template>
  <div class="services-overview">
    <div class="inner">
      <h2>{{ content.title }}</h2>

      <div class="topic-view">
        <ul class="topic-list" v-for="(list, listIndex) in content.topics" :key="listIndex">
          <li v-for="(item, index) in list" :key="index">
            {{ item.label }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .services-overview {
    background-color: #1FA19E;
  }
  .services-overview .inner {
    max-width: 1000px;
    padding: 60px 0;
    margin: auto;
  }
  h2 {
    margin: 0;
    color: #fff;
    font-family: 'Montserrat';
    font-weight: 900;
    text-align: center;
    font-size: 4em;
    max-width: 600px;
    margin: auto;
  }
  @media screen and (max-width: 600px) {
    h2 {
      font-size: 3em;
    }
  }
  .topic-view {
    margin: 30px 0 0 0;
    color: #fff;
    font-size: 1.3em;
    font-weight: 400;
    text-align: center;
  }
  .topic-view .topic-list {
    list-style: none;
    display: inline-block;
    padding: 0 25px;

  }
  @media screen and (max-width: 800px) {
    .topic-view .topic-list {
      padding: 0 10px;
    }
  }
  @media screen and (max-width: 700px) {
    .topic-view {
      font-size: 1.1em;
    }
  }
  @media screen and (max-width: 500px) {
    .topic-view {
      font-size: 0.9em;
    }
  }
  .topic-view .topic-list > li {

  }
</style>

<script>
  import contentSite from '@/../content/work.yaml'
  const content = contentSite["services-overview"];

  export default {
    name: "ServicesOverview",
    data() {
      return {
        content
      }
    }
  }
</script>
