<template>
  <div class="team-container">
    <div class="header">
      <div class="title">
        <span>{{ content.title }}</span>
      </div>
      <div class="underliner" />
    </div>
    <div class="body">
      <div class="team-view" ref="teamView" @scroll="updateTeamView">
        <ul>
          <li v-for="(member, index) in content.staff" :key="index">
            <div class="avatar">
              <img :src="member.avatar" :alt="member.name" />
            </div>
            <div class="about">
              <div class="subtitle">
                {{ member.subtitle }}
              </div>
              <div class="title">
                <span>{{ member.name }}</span>
              </div>
            </div>
          </li>
        </ul>

      </div>
      <div class="controls controls-left" :class="{ show: showLeftScrollBtn }" @mousedown="scrollTeam(-1)">
        <button>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g>
              <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M313.749,347.584 c8.341,8.341,8.341,21.824,0,30.165c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251L176.917,271.083 c-8.341-8.341-8.341-21.824,0-30.165l106.667-106.667c8.341-8.341,21.824-8.341,30.165,0s8.341,21.824,0,30.165L222.165,256 L313.749,347.584z"/>
            </g>
          </svg>
        </button>
      </div>
      <div class="controls controls-right" :class="{ show: showRightScrollBtn }" @mousedown="scrollTeam(1)">
        <button>
          <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512">
            <g>
              <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M335.083,271.083 L228.416,377.749c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251c-8.341-8.341-8.341-21.824,0-30.165 L289.835,256l-91.584-91.584c-8.341-8.341-8.341-21.824,0-30.165s21.824-8.341,30.165,0l106.667,106.667 C343.424,249.259,343.424,262.741,335.083,271.083z"/>
            </g>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .team-container {
    padding: 140px 0;
  }
  @media screen and (max-width: 600px) {
    .team-container {
      padding: 15px 0 70px;
    }
  }
  .team-container .header {
    display: inline-block;
    width: 100%;
  }
  .team-container .underliner {
    height: 60px;
    background-image: linear-gradient(to right, #F0BDC6, #FAE191);
    float: right;
    border-radius: 15px 0 0 15px;
    width: calc((100% - 1000px) / 2 + 420px);
    transform: translateY(-28px);
    z-index: 0;
    position: relative;
  }
  @media screen and (max-width: 1000px) {
    .team-container .underliner {
      width: calc(420px);
      max-width: calc(100% - 50px);

    }
  }
  .team-container .header .title {
    max-width: 1000px;
    margin: auto;
    padding: 0 50px 0 0;
    text-align: right;
    z-index: 1;
    position: relative;
    font-weight: 900;
    color: #4D4D4D;
    font-size: 4em;

  }

  @media screen and (max-width: 450px) {
    .team-container .header .title {
      font-size: 3.5em;
      padding-right: 30px;
    }
  }
  @media screen and (max-width: 400px) {
    .team-container .header .title {
      font-size: 2.8em;
      padding-right: 30px;
    }
  }
  @media screen and (max-width: 350px) {
    .team-container .header .title {
      font-size: 2.6em;
      padding-right: 20px;
    }
  }
  @media screen and (max-width: 300px) {
    .team-container .header .title {
      font-size: 2.2em;
      padding-right: 20px;
    }
  }
  .team-container .header .title > span {
    display: block;
  }
</style>

<style scoped>
  .team-container .body {

  }
  .team-container .body {
    display: grid;
    grid-template-columns: 60px auto 60px;
    grid-template-rows: 100%;
  }
  .team-container .body .team-view {
    margin: 70px 0 0 0;
    overflow: scroll;
    text-align: center;
    grid-row: 1 / span 1;
    grid-column: 1 / span 3;
  }
  .team-container .body .controls {
    grid-row: 1 / span 1;
    grid-column-end: span 1;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 60px auto;
    z-index: 3;
    transform: scale(0);
    opacity: 0;
    transition: all 0.5s;
  }
  .team-container .body .controls.show {
    transform: scale(1);
    opacity: 1;
  }
  .team-container .body .controls.controls-left {
    grid-column-start: 1;
  }
  .team-container .body .controls.controls-right {
    grid-column-start: 3;
  }
  .team-container .body .controls > button {
    grid-row: 2 / span 1;
    grid-column: 1 / span 1;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
  }
  .team-container .body .controls > button > svg {
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.8));
  }
  .team-container .body .controls > button > svg * {
    fill: #fff;
  }
  .team-container .body .team-view::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .team-container .body .team-view {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }



  .team-container .body .team-view > ul {
    list-style: none;
    margin: 0 15px;
    padding: 0;
    white-space: normal;
    display: inline-block;
    max-width: 100%;

  }
  .team-container .body .team-view > ul > li {
    display: inline-block;
    margin: 10px 15px;
  }
  @media screen and (max-width: 600px) {
    .team-container .body .team-view > ul {
      white-space: nowrap;
      max-width: none;
    }
    .team-container .body .team-view > ul > li {
      margin: 0 15px;
    }
  }
  .team-container .body .team-view > ul > li .avatar {
    width: 250px;
    height: 250px;
    overflow: hidden;
    border-radius: 20px;
  }
  .team-container .body .team-view > ul > li .avatar > img {
    width: 100%;
    height: 100%;
  }
  .team-container .body .team-view > ul > li .about {
    padding: 30px 0 0 0;
  }
  .team-container .body .team-view > ul > li .about .subtitle {
    font-size: 0.9em;
    font-weight: 300;
  }
  .team-container .body .team-view > ul > li .about .title {
    font-size: 1.5em;
    font-weight: 500;
  }
</style>

<script>
  import content from '@/../content/connect.yaml'

  export default {
    name: "Team",
    data() {
      return {
        content: content['team'],
        showLeftScrollBtn: false,
        showRightScrollBtn: true
      };
    },
    methods: {
      scrollTeam(dir) {

        let pressed = true;
        window.addEventListener("mouseup", () => {
          pressed = false;
        });
        const scroll = () => this.$refs.teamView.scrollLeft += dir * 5;
        scroll();
        setTimeout(() => {
          if (pressed) {
            this.scrollTeam(dir);
          }
        }, 10);
      },
      updateTeamView() {
        if (this.$refs.teamView) {
          const maxScrollLeft = this.$refs.teamView.scrollWidth - this.$refs.teamView.offsetWidth;
          const { scrollLeft } = this.$refs.teamView;


          this.showLeftScrollBtn = scrollLeft > (0 + 10);
          this.showRightScrollBtn = scrollLeft < (maxScrollLeft - 10);
        }

      }
    },
    created() {
      window.addEventListener("resize", () => this.updateTeamView());

    },
    mounted() {
      this.updateTeamView();
    }
  }
</script>
