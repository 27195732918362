<template>
  <div class="story-wrapper" ref="wrapper">
    <div class="story-cover">
      <div class="cover-item cover-left">
        <div class="image">
          <img :src="content.background" alt="Robots">
        </div>
        <div class="gradient">
          <div class="title">
            <span class="bold">{{ content.title[0] }}</span>
            <span>{{ content.title[1] }}</span>
          </div>
        </div>
      </div>
      <div class="cover-item cover-right">

      </div>
    </div>
    <div class="story-content" ref="storyContentContainer">
      <div class="cover-space" :style="{ width: offsetLeft + 'px' }" />
      <div class="content content-fff" ref="content">
        <div class="inner">
          <div class="part">
            <article>
              <p v-for="(paragraph, _i) in content['paragraph-1'].split('\n')" :key="_i">
                {{ paragraph }}
              </p>
            </article>
          </div>

          <div class="part part-fixed">
            <div class="image-display">
              <div class="image-container">
                <iframe :src="content['item-1'].video" class="video-frame"></iframe>
                <!--<img :src="content['item-1']." alt="Musikvideo FFF">-->
              </div>
              <div class="about-container">
                <div class="description">
                  {{ content['item-1']['info-1'] }}
                </div>
                <div class="info">
                  <div class="value">
                    {{ content['item-1']['info-2'].streams }}
                  </div>
                  <div class="label">
                    {{ content['item-1']['info-2'].label }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="part flex flex-h">
            <article class="text-top article-contrary">
              {{ content['paragraph-2'] }}
            </article>
            <article class="text-bottom article-contrary">
              {{ content['paragraph-3'] }}
            </article>
          </div>

          <div class="part">
            <div class="image-display">
              <div class="image-container">
                <iframe :src="content['item-2'].video" class="video-frame"></iframe>
              </div>
              <div class="about-container">
                <div class="description">
                  {{ content['item-2']['info-1'] }}
                </div>
                <div class="info">
                  <div class="value">
                    {{ content['item-2']['info-2'].streams }}
                  </div>
                  <div class="label">
                    {{ content['item-2']['info-2'].label }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="part" >
            <article class="article-3">
              <p v-html="content['paragraph-4']"></p>
            </article>
          </div>

          <div class="part">
            <div class="image-display">
              <div class="image-container">
                <iframe :src="content['item-3'].video" class="video-frame"></iframe>
              </div>
              <div class="about-container">
                <div class="description">
                  {{ content['item-3']['info-1'] }}
                </div>
                <div class="info">
                  <div class="value">
                    {{ content['item-3']['info-2'].streams }}
                  </div>
                  <div class="label">
                    {{ content['item-3']['info-2'].label }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cover-space" :style="{ width: offsetRight + 'px' }" />
    </div>
  </div>
</template>


<style scoped>
  .content.content-fff {
    width: 4700px;

  }
  @media screen and (max-width: 600px) {
    .content.content-fff {
      width: 618vw;
    }
  }

  .content .part article {
    max-width: 450px;
  }
  @media screen and (max-width: 600px) {
    .content .part article {
      max-width: calc(100vw - 70px);
    }
  }
</style>

<style scoped>
  @import "./story.css";

  .article-3 {
    max-width: 600px !important;
  }
  @media screen and (max-width: 600px) {
    .article-3 {
      max-width: calc(100vw - 100px) !important;
    }
  }
</style>



<script>
  import content from '@/../content/story/story-fff.yaml'

  function bindBounds(app, refId, storageKey) {
    app[storageKey][refId] = app.$refs[refId].getBoundingClientRect();
    window.addEventListener("resize", () => {
      if (app.$refs[refId]) {
        app[storageKey][refId] = app.$refs[refId].getBoundingClientRect();
      }
    });
  }

  export default {
    name: "StoryFFF",
    computed: {
      showFirstCover() {
        return this.scroll - this.offsetRight >= this.endPos;
      },
      startPos() {
        return this.offsetLeft;
      },
      endPos() {
        const offset = this.bounds.content ? (this.bounds.content.width - this.bounds.wrapper.width) : 0;
        return -offset - this.offsetRight;
      }
    },
    mounted() {
      bindBounds(this, 'wrapper', 'bounds');
      bindBounds(this, 'content', 'bounds');
    },
    data() {
      return {
        content,
        bounds: {},
        offsetLeft: window.innerWidth < 600 ? 250 : 400,
        offsetRight: 0
      }
    }
  }
</script>
