<template>
  <div class="cover-wrapper" data-name="work" style="z-index: 4;">
    <div class="graphic-inner">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="30 -10 100 100">

        <rect x="38" y="32" width="56" height="10" style="fill: #8AD4ED; transform-origin: 66px 37px; transform: translate(0, -5px) rotate(-4deg);" />
        <text style="font-family: 'Montserrat'; font-weight: 900; fill: #fff; font-size: 12px;" x="40" y="30">Impressum</text>

      </svg>
    </div>
  </div>
</template>


<style scoped>
  .cover-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .cover-wrapper .graphic-inner {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .cover-wrapper .graphic-inner > svg {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
  }

</style>

<script>



  export default {
    name: "CoverImpressum",
    data() {
      return {

      };
    }
  }
</script>
