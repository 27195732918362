<template>
  <div class="cover-root">
    <div class="cover-background" :style="{ 'background-image': bg }">
      <video ref="bgVideo" v-if="video" playsinline :autoplay="!isMobile()" :poster="bg" muted class="autoplay-video" loop>
        <source :src="video" type="application/x-mpegURL">
        <!--<source :src="videoMP4" type="video/mp4">-->
      </video>
    </div>
    <div class="cover-array" :class="{ hide: noImage }">
      <transition name="fade">
        <component :is="coverComponent"/>
      </transition>
    </div>
  </div>
</template>

<style scoped>
  .cover-root {
    width: 100%;
    height: 100%;
    position: relative;

  }
  .cover-root > * {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

  }
  .cover-background {
    background-position: center;
    background-size: cover;
  }
  .cover-background > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .cover-root .cover-array {
    white-space: nowrap;
    transition: all 1s;
  }
  .cover-root .cover-array.hide {
    opacity: 0;
  }
  .cover-root .cover-wrapper {
    display: inline-block;
    vertical-align: middle;
    overflow: visible;
    z-index: 3;
  }

  .fade-enter-active:not(:only-child),
  .fade-leave-active:not(:only-child) {
    transition: transform 1s ease;
    transform-origin: 50% 120vw;
  }
  @media screen and (max-width: 600px) {
    .fade-enter-active:not(:only-child),
    .fade-leave-active:not(:only-child) {
      transform-origin: 50% 150%;
    }
  }
  .fade-leave-active:not(:only-child) {
    transform: translateX(0%) rotate(0deg);
    opacity: 1 !important;
  }
  .fade-enter-active:not(:only-child) {
    transform: translateX(-100%) rotate(90deg);
    opacity: 0;
  }
  .fade-leave-to {
    transform: translateX(0%) rotate(-90deg) !important;
    opacity: 0 !important;

  }
  .fade-enter-to {
    transform: translateX(-100%) rotate(0deg) !important;
    opacity: 1 !important;
  }



  /*.fade-enter-active:not(:only-child),
  .fade-leave-active:not(:only-child) {
    transition: transform 10s ease;
    transform-origin: 50% 100%;
  }
  .fade-leave-active:not(:only-child) {
    transform: translateX(0%) rotate(0deg);
  }
  .fade-enter-active:not(:only-child) {
    transform: translateX(0%) rotate(90deg);
  }
  .fade-leave-to {
    transform: translateX(-100%) rotate(-90deg) !important;
  }
  .fade-enter-to {
    transform: translateX(-100%) rotate(0deg) !important;
  }*/
</style>

<script>
  import { nextTick } from 'vue'
  //import supportsHLS from '@/helpers/supportsHLS.js'

  const supportsHLS = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  import content from '@/../content/header.yaml'


  export default {
    name: "Cover",
    props: {
      noImage: Boolean
    },
    computed: {
      coverComponent() {
        return this.$route.meta.cover;
      },
      coversByName() {
        return new Map(this.content.map(coverDescription => [coverDescription.name, coverDescription]));
      },
      bg() {
        return new Map(this.content.map(coverDescription => [coverDescription.name, coverDescription.background])).get(this.$route.name);
      },
      video() {
        if (window.innerWidth < 600) {
          return new Map(this.content.map(coverDescription => [coverDescription.name, coverDescription['video-small']])).get(this.$route.name);
        }
        else {
          return new Map(this.content.map(coverDescription => [coverDescription.name, coverDescription.video])).get(this.$route.name);
        }
      },
      videoMP4() {
        return new Map(this.content.map(coverDescription => [coverDescription.name, coverDescription.videoMP4])).get(this.$route.name);
      }
    },
    methods: {
      isMobile() {
        return window.innerWidth < 600;
      },
      initVideo() {
        if (this.$refs.bgVideo) {
          if (this.$refs.bgVideo.canPlayType('application/vnd.apple.mpegurl')) {
            //console.log("YUHUHU");
          }
          else if (window.Hls.isSupported()) {
            //console.log("NÖÖÖÖ");
            console.log("!!!!");
            var hls = new window.Hls();
            hls.loadSource(this.video);
            hls.attachMedia(this.$refs.bgVideo);
            this.$refs.bgVideo.play();
          }
        }
      }
    },
    created() {
      this.$root.events.on('play-pause-header-video', () => {
        if (this.$refs.bgVideo.paused) {
          this.$refs.bgVideo.play();
          this.$root.events.emit('header-video-state', { playing: true });
        }
        else {
          this.$refs.bgVideo.pause();
          this.$root.events.emit('header-video-state', { playing: false });
        }
      })
    },
    mounted() {
      supportsHLS;

      setTimeout(() => {
        this.initVideo();
      }, 50);


    },
    data() {
      return {
        content: content['covers'],
        player: null
      }
    },
    watch: {
      $route(value) {
        value;
        if (this.coversByName.get(this.$route.name)?.video) {
          nextTick(() => {
            this.initVideo();
            this.$refs.bgVideo.play();
          });
        }
      }
    }
  }
</script>
