<template>
  <footer :style="{ 'margin-top': offset + 'px', 'padding-top': -offset + 'px' }">
    <div class="message-container" v-if="!hideContact" :style="{ 'background-image': background }">
      <div class="inner">
        <div class="area area-left">
          <div class="area-inner">
            <h2>
              <slot name="title"></slot>
            </h2>
            <article>
              <slot name="description"></slot>
            </article>
          </div>
        </div>
        <div class="area area-right">
          <div class="area-inner">
            <h4 class="form-title">{{ content['title-form'] }}</h4>
            <div class="form">
              <div class="input-main">
                <textarea class="msg-textarea" :placeholder="content['message-placeholder']" v-model="message"></textarea>
              </div>
              <div class="input-main">
                <input type="text" :placeholder="content['mail-placeholder']" v-model="mail" />
                <button @click="sendMessage">
                  <svg width="100%" height="100%" viewBox="0 0 29 29" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.9707 21.9306L18.9345 22.0273L12.0833 28.7939L15.1162 20.541L18.9707 21.9306Z" fill="#CCCCCC"/>
                    <path d="M28.822 0.368521C28.5574 0.00722896 28.0692 -0.106354 27.6717 0.101479L0.48417 14.2994C0.168795 14.4649 -0.0209136 14.7984 -0.00158029 15.1537C0.0189614 15.5089 0.24492 15.8195 0.577211 15.9476L9.94179 19.5726C10.0481 19.6136 10.1593 19.6342 10.2693 19.6342C10.5049 19.6342 10.7369 19.5424 10.9097 19.3684L28.7326 1.54544C29.0492 1.23006 29.0878 0.728604 28.822 0.368521Z" fill="white"/>
                    <path d="M28.3934 0.0506316C28.0019 -0.0834934 27.5669 0.0602983 27.3373 0.40709L14.3598 20.0425C14.2063 20.2733 14.1677 20.5633 14.2559 20.8267C14.3441 21.0901 14.5471 21.3004 14.8093 21.3934L25.671 25.3205C25.7713 25.3568 25.8752 25.3749 25.9791 25.3749C26.1495 25.3749 26.3187 25.3265 26.4661 25.2335C26.7029 25.0825 26.8564 24.8299 26.8817 24.5508L28.9963 0.988298C29.0338 0.57384 28.7849 0.187173 28.3934 0.0506316Z" fill="white"/>
                    <path d="M28.0937 0.90625L14.8081 21.3935L12.0833 28.7946L9.66663 19.3333L28.0937 0.90625Z" fill="#E4E4E4"/>
                  </svg>
                </button>
              </div>
              <div class="check-main">
                <ul>
                  <li>
                    <div class="checkbox-container">
                      <input type="checkbox" v-model="acceptsTerms" />
                    </div>
                    <div class="label-container">
                      {{ content['form-info'] }}
                    </div>
                  </li>
                </ul>
              </div>
              <div class="result-messages">
                <div class="message message-success" :class="{ show: success }">
                  Danke für deine Nachricht!
                </div>
                <div class="message message-error" :class="{ show: error }">
                  Du musst die Verarbeitung der Daten akzeptieren, eine Nachricht und eine valide Mail-Adresse eingeben!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info-container">
      <div class="info-inner">
        <div class="emblem-area">
          <svg width="100%" height="100%" viewBox="0 0 277 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
            style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
            <path d="M167.87,11.345l-45.807,0c-1.907,0 -3.454,1.568 -3.454,3.503l0,44.83c0,1.934 1.547,3.502 3.454,3.502l45.807,0c1.907,0 3.454,-1.568 3.454,-3.502l-0,-44.83c-0,-1.935 -1.547,-3.503 -3.454,-3.503Z"
               :style="{ fill: logoFillColor }" style="fill-rule:nonzero;" />
            <path
              d="M7.378,49.547c-2.254,-1.143 -4.135,-2.881 -5.427,-5.018c-1.318,-2.211 -1.992,-4.733 -1.949,-7.291c-0.044,-2.55 0.63,-5.062 1.949,-7.262c1.295,-2.119 3.162,-3.848 5.397,-4.996c2.433,-1.231 5.141,-1.851 7.88,-1.803c2.533,-0.055 5.041,0.512 7.293,1.648c2.045,1.054 3.696,2.712 4.719,4.738l-5.058,2.885c-0.728,-1.195 -1.767,-2.18 -3.01,-2.855c-1.225,-0.64 -2.594,-0.969 -3.982,-0.957c-1.136,-0.043 -2.269,0.144 -3.328,0.547c-1.059,0.404 -2.022,1.017 -2.828,1.801c-0.821,0.824 -1.46,1.805 -1.877,2.88c-0.417,1.076 -0.603,2.224 -0.547,3.374c-0.052,1.149 0.136,2.298 0.553,3.374c0.416,1.077 1.053,2.059 1.871,2.887c0.807,0.777 1.768,1.386 2.824,1.788c1.056,0.402 2.184,0.59 3.317,0.552c1.387,0.011 2.756,-0.315 3.981,-0.949c1.244,-0.679 2.283,-1.666 3.011,-2.863l5.058,2.885c-1.057,2.025 -2.718,3.688 -4.764,4.767c-2.234,1.146 -4.727,1.721 -7.248,1.671c-2.724,0.038 -5.415,-0.581 -7.835,-1.803Z"
              style="fill:#fff;fill-rule:nonzero;" />
            <path d="M49.209,23.177l0,6.129c-0.496,-0.096 -1,-0.148 -1.505,-0.155c-2.569,0 -4.576,0.736 -6.021,2.208c-1.445,1.471 -2.168,3.568 -2.168,6.291l0,13.339l-6.585,0l-0,-27.503l6.269,0l0,4.047c1.897,-2.904 5.233,-4.356 10.01,-4.356Z"
              style="fill:#fff;fill-rule:nonzero;" />
            <path
              d="M80.872,39.305l-22.074,-0c0.318,1.93 1.4,3.66 3.01,4.819c1.777,1.24 3.922,1.873 6.104,1.802c1.415,0.046 2.825,-0.196 4.14,-0.71c1.315,-0.515 2.506,-1.29 3.499,-2.277l3.53,3.966c-1.285,1.492 -2.934,2.643 -4.794,3.348c-2.373,0.849 -4.895,1.228 -7.419,1.113c-2.524,-0.115 -4.999,-0.721 -7.28,-1.783c-2.273,-1.127 -4.167,-2.869 -5.457,-5.018c-1.298,-2.217 -1.961,-4.736 -1.919,-7.291c-0.038,-2.529 0.606,-5.024 1.867,-7.233c1.218,-2.115 3.016,-3.856 5.193,-5.025c2.293,-1.221 4.87,-1.842 7.481,-1.803c2.568,-0.04 5.102,0.574 7.353,1.781c2.136,1.161 3.888,2.895 5.05,4.996c1.253,2.284 1.881,4.847 1.821,7.438c0,0.405 -0.037,1.045 -0.105,1.877Zm-19.47,-9.146c-1.47,1.245 -2.416,2.977 -2.657,4.863l15.963,0c-0.212,-1.893 -1.151,-3.637 -2.627,-4.88c-1.477,-1.243 -3.381,-1.892 -5.328,-1.815c-1.954,-0.053 -3.859,0.599 -5.351,1.832Z"
              style="fill:#fff;fill-rule:nonzero;" />
            <path
              d="M106.763,26.061c2.198,1.962 3.296,4.905 3.296,8.829l0,16.099l-6.194,-0l0,-3.348c-0.845,1.235 -2.048,2.196 -3.454,2.759c-1.714,0.673 -3.551,0.996 -5.397,0.949c-1.899,0.04 -3.786,-0.319 -5.532,-1.052c-1.479,-0.612 -2.754,-1.616 -3.68,-2.899c-0.859,-1.258 -1.307,-2.74 -1.287,-4.253c-0.047,-1.141 0.182,-2.277 0.666,-3.316c0.485,-1.039 1.214,-1.952 2.126,-2.665c1.882,-1.472 4.842,-2.22 8.881,-2.245l7.271,0l-0,-0.412c0.051,-0.817 -0.081,-1.635 -0.386,-2.398c-0.305,-0.762 -0.778,-1.45 -1.383,-2.016c-1.174,-1.03 -2.93,-1.545 -5.269,-1.545c-1.584,0.006 -3.159,0.254 -4.665,0.735c-1.41,0.421 -2.724,1.103 -3.869,2.009l-2.536,-4.716c1.599,-1.154 3.403,-2.01 5.32,-2.524c2.143,-0.592 4.36,-0.887 6.586,-0.875c4.144,-0 7.313,0.961 9.506,2.884Zm-6.022,19.468c1.261,-0.71 2.223,-1.834 2.718,-3.171l-0,-3.164l-6.774,0c-3.793,0 -5.69,1.227 -5.69,3.679c-0.017,0.543 0.104,1.081 0.352,1.566c0.248,0.486 0.616,0.903 1.071,1.215c1.163,0.737 2.537,1.09 3.921,1.008c1.548,0.032 3.075,-0.35 4.418,-1.103l-0.016,-0.03Z"
              style="fill:#fff;fill-rule:nonzero;" />
            <path
              d="M138.418,50.598c-2.449,-1.239 -4.492,-3.129 -5.892,-5.452c-1.429,-2.399 -2.16,-5.134 -2.115,-7.909c-0.047,-2.769 0.684,-5.497 2.115,-7.888c1.401,-2.304 3.431,-4.182 5.862,-5.422c2.64,-1.337 5.578,-2.009 8.55,-1.957c2.753,-0.061 5.478,0.554 7.926,1.788c2.219,1.145 4.011,2.948 5.118,5.15l-5.495,3.134c-0.793,-1.3 -1.926,-2.371 -3.281,-3.105c-1.767,-0.876 -3.767,-1.198 -5.728,-0.923c-1.961,0.275 -3.787,1.134 -5.23,2.461c-0.892,0.897 -1.585,1.963 -2.039,3.132c-0.453,1.17 -0.655,2.418 -0.596,3.667c-0.06,1.245 0.14,2.489 0.588,3.657c0.448,1.167 1.134,2.234 2.016,3.134c1.454,1.321 3.289,2.172 5.257,2.435c1.966,0.263 3.968,-0.073 5.732,-0.964c1.356,-0.728 2.489,-1.797 3.281,-3.097l5.495,3.127c-1.148,2.194 -2.955,3.991 -5.179,5.15c-2.42,1.254 -5.126,1.885 -7.865,1.832c-2.961,0.052 -5.889,-0.618 -8.52,-1.95Z"
              style="fill:#fff;fill-rule:nonzero;" />
            <path
              d="M199.999,51.636c-0.829,0.646 -1.785,1.119 -2.808,1.391c-1.138,0.313 -2.316,0.469 -3.499,0.463c-3.091,0 -5.48,-0.789 -7.165,-2.369c-1.686,-1.579 -2.529,-3.88 -2.529,-6.901l0,-13.244l-4.636,-0l-0,-5.151l4.636,0l0,-6.276l6.585,0l0,6.284l7.527,-0l0,5.15l-7.527,0l0,13.082c-0.079,1.11 0.279,2.208 1.001,3.068c0.38,0.366 0.834,0.649 1.334,0.831c0.499,0.181 1.033,0.257 1.564,0.221c1.326,0.048 2.628,-0.352 3.688,-1.133l1.829,4.584Z"
              style="fill:#fff;fill-rule:nonzero;" />
            <path
              d="M205.741,19.991c-0.392,-0.348 -0.703,-0.775 -0.912,-1.251c-0.209,-0.476 -0.311,-0.99 -0.3,-1.508c-0.012,-0.517 0.09,-1.031 0.299,-1.505c0.209,-0.475 0.52,-0.901 0.913,-1.247c0.403,-0.369 0.876,-0.658 1.392,-0.848c0.517,-0.191 1.067,-0.28 1.619,-0.263c1.105,-0.033 2.181,0.346 3.01,1.06c0.394,0.324 0.709,0.73 0.923,1.188c0.214,0.458 0.32,0.957 0.312,1.46c0.012,0.532 -0.087,1.059 -0.292,1.552c-0.205,0.492 -0.51,0.938 -0.898,1.31c-0.395,0.383 -0.866,0.682 -1.383,0.881c-0.518,0.199 -1.071,0.292 -1.627,0.275c-0.559,0.022 -1.116,-0.064 -1.641,-0.253c-0.524,-0.189 -1.005,-0.479 -1.415,-0.851Zm-0.316,5.636l6.586,-0l-0,27.503l-6.586,0l-0,-27.503Z"
              style="fill:#fff;fill-rule:nonzero;" />
            <path d="M246.993,25.627l-12.042,27.503l-6.773,-0l-12.043,-27.503l6.841,-0l8.746,20.395l9.032,-20.395l6.239,-0Z" style="fill:#fff;fill-rule:nonzero;" />
            <path
              d="M276.647,41.438l-22.075,0c0.325,1.928 1.405,3.656 3.011,4.82c1.774,1.226 3.91,1.849 6.081,1.773c1.419,0.048 2.833,-0.192 4.152,-0.707c1.319,-0.514 2.514,-1.291 3.51,-2.28l3.529,3.965c-1.284,1.492 -2.934,2.644 -4.794,3.348c-2.372,0.85 -4.894,1.228 -7.418,1.113c-2.524,-0.115 -4.999,-0.721 -7.281,-1.782c-2.272,-1.13 -4.163,-2.875 -5.449,-5.026c-1.304,-2.215 -1.97,-4.735 -1.926,-7.291c-0.033,-2.529 0.618,-5.022 1.889,-7.225c1.21,-2.119 3.008,-3.861 5.185,-5.026c2.29,-1.215 4.861,-1.833 7.467,-1.795c2.567,-0.041 5.101,0.572 7.353,1.781c2.137,1.161 3.892,2.894 5.058,4.995c1.246,2.287 1.871,4.848 1.813,7.439c0,0.442 -0.03,1.074 -0.105,1.898Zm-19.463,-9.138c-1.475,1.242 -2.425,2.975 -2.665,4.863l15.964,0c-0.203,-1.866 -1.11,-3.592 -2.544,-4.841c-1.501,-1.261 -3.434,-1.921 -5.411,-1.847c-1.951,-0.055 -3.854,0.594 -5.344,1.825Z"
              style="fill:#fff;fill-rule:nonzero;" />
            <g style="fill-opacity: 0;">
              <path
                d="M10.212,0l-0,13.343l-1.223,0l-0,-1.87c-0.384,0.635 -0.89,1.121 -1.52,1.456c-0.629,0.336 -1.339,0.504 -2.131,0.504c-0.887,-0 -1.69,-0.204 -2.409,-0.611c-0.72,-0.408 -1.283,-0.977 -1.691,-1.709c-0.407,-0.731 -0.611,-1.564 -0.611,-2.499c0,-0.935 0.204,-1.769 0.611,-2.5c0.408,-0.731 0.971,-1.298 1.691,-1.699c0.719,-0.402 1.522,-0.603 2.409,-0.603c0.768,0 1.46,0.159 2.077,0.477c0.618,0.318 1.124,0.782 1.52,1.393l-0,-5.682l1.277,0Zm-4.766,12.3c0.66,0 1.256,-0.153 1.79,-0.458c0.533,-0.306 0.953,-0.741 1.258,-1.304c0.306,-0.564 0.459,-1.205 0.459,-1.924c-0,-0.72 -0.153,-1.361 -0.459,-1.924c-0.305,-0.564 -0.725,-0.999 -1.258,-1.304c-0.534,-0.306 -1.13,-0.459 -1.79,-0.459c-0.671,0 -1.273,0.153 -1.807,0.459c-0.533,0.305 -0.953,0.74 -1.259,1.304c-0.305,0.563 -0.458,1.204 -0.458,1.924c-0,0.719 0.153,1.36 0.458,1.924c0.306,0.563 0.726,0.998 1.259,1.304c0.534,0.305 1.136,0.458 1.807,0.458Z"
                style="fill:#fff;fill-rule:nonzero;" />
              <path
                d="M22.008,9.009l-7.912,0c0.072,0.983 0.45,1.778 1.133,2.383c0.683,0.605 1.546,0.908 2.589,0.908c0.588,0 1.127,-0.105 1.619,-0.315c0.491,-0.209 0.917,-0.518 1.277,-0.926l0.719,0.827c-0.42,0.504 -0.944,0.888 -1.574,1.151c-0.629,0.264 -1.321,0.396 -2.077,0.396c-0.971,-0 -1.831,-0.207 -2.58,-0.62c-0.749,-0.414 -1.334,-0.986 -1.753,-1.718c-0.42,-0.731 -0.63,-1.558 -0.63,-2.481c0,-0.923 0.201,-1.751 0.603,-2.482c0.401,-0.731 0.953,-1.301 1.654,-1.708c0.701,-0.408 1.49,-0.612 2.365,-0.612c0.875,0 1.66,0.204 2.355,0.612c0.696,0.407 1.241,0.974 1.637,1.699c0.395,0.725 0.593,1.556 0.593,2.491l-0.018,0.395Zm-4.567,-4.1c-0.911,0 -1.676,0.291 -2.293,0.872c-0.617,0.582 -0.968,1.34 -1.052,2.275l6.707,0c-0.083,-0.935 -0.434,-1.693 -1.051,-2.275c-0.618,-0.581 -1.388,-0.872 -2.311,-0.872Z"
                style="fill:#fff;fill-rule:nonzero;" />
              <path
                d="M27.169,13.433c-0.767,-0 -1.501,-0.111 -2.203,-0.333c-0.701,-0.221 -1.249,-0.5 -1.645,-0.836l0.575,-1.007c0.396,0.312 0.894,0.567 1.493,0.764c0.599,0.198 1.223,0.297 1.87,0.297c0.863,0 1.502,-0.135 1.915,-0.405c0.414,-0.269 0.621,-0.65 0.621,-1.141c-0,-0.348 -0.114,-0.621 -0.342,-0.819c-0.228,-0.197 -0.515,-0.347 -0.863,-0.449c-0.348,-0.102 -0.809,-0.201 -1.385,-0.297c-0.767,-0.144 -1.384,-0.291 -1.852,-0.44c-0.467,-0.15 -0.866,-0.405 -1.196,-0.765c-0.329,-0.359 -0.494,-0.857 -0.494,-1.492c-0,-0.792 0.329,-1.439 0.989,-1.942c0.659,-0.504 1.576,-0.756 2.751,-0.756c0.612,0 1.223,0.081 1.834,0.243c0.612,0.162 1.115,0.375 1.511,0.638l-0.558,1.025c-0.779,-0.539 -1.708,-0.809 -2.787,-0.809c-0.815,0 -1.43,0.144 -1.843,0.432c-0.414,0.288 -0.621,0.665 -0.621,1.133c0,0.359 0.117,0.647 0.351,0.863c0.234,0.216 0.525,0.375 0.872,0.476c0.348,0.102 0.828,0.207 1.439,0.315c0.755,0.144 1.361,0.288 1.816,0.432c0.456,0.144 0.845,0.389 1.169,0.737c0.324,0.348 0.486,0.827 0.486,1.439c-0,0.827 -0.345,1.483 -1.034,1.969c-0.69,0.485 -1.646,0.728 -2.869,0.728Z"
                style="fill:#fff;fill-rule:nonzero;" />
              <path
                d="M33.427,3.884l1.277,0l0,9.459l-1.277,0l0,-9.459Zm0.648,-2.068c-0.264,0 -0.486,-0.09 -0.666,-0.269c-0.18,-0.18 -0.269,-0.396 -0.269,-0.648c-0,-0.24 0.089,-0.449 0.269,-0.629c0.18,-0.18 0.402,-0.27 0.666,-0.27c0.263,0 0.485,0.087 0.665,0.261c0.18,0.174 0.27,0.38 0.27,0.62c-0,0.264 -0.09,0.486 -0.27,0.666c-0.18,0.179 -0.402,0.269 -0.665,0.269Z"
                style="fill:#fff;fill-rule:nonzero;" />
              <path
                d="M47.022,3.884l0,8.308c0,1.607 -0.393,2.796 -1.178,3.57c-0.785,0.773 -1.969,1.16 -3.551,1.16c-0.875,-0 -1.706,-0.129 -2.491,-0.387c-0.785,-0.258 -1.424,-0.614 -1.915,-1.07l0.647,-0.971c0.456,0.408 1.01,0.725 1.664,0.953c0.653,0.228 1.339,0.342 2.059,0.342c1.199,-0 2.08,-0.279 2.643,-0.836c0.564,-0.558 0.845,-1.424 0.845,-2.599l0,-1.205c-0.395,0.6 -0.914,1.055 -1.555,1.367c-0.642,0.312 -1.352,0.467 -2.131,0.467c-0.887,0 -1.693,-0.194 -2.419,-0.584c-0.725,-0.39 -1.294,-0.935 -1.708,-1.636c-0.414,-0.702 -0.62,-1.496 -0.62,-2.383c-0,-0.887 0.206,-1.678 0.62,-2.374c0.414,-0.695 0.98,-1.235 1.699,-1.618c0.72,-0.384 1.529,-0.576 2.428,-0.576c0.803,0 1.528,0.162 2.176,0.486c0.647,0.324 1.169,0.791 1.564,1.402l0,-1.816l1.223,0Zm-4.837,7.967c0.683,-0 1.301,-0.147 1.852,-0.441c0.551,-0.294 0.98,-0.704 1.286,-1.232c0.305,-0.527 0.458,-1.127 0.458,-1.798c0,-0.671 -0.153,-1.268 -0.458,-1.789c-0.306,-0.522 -0.732,-0.929 -1.277,-1.223c-0.545,-0.294 -1.166,-0.441 -1.861,-0.441c-0.684,0 -1.298,0.144 -1.843,0.432c-0.546,0.288 -0.971,0.695 -1.277,1.223c-0.306,0.527 -0.459,1.127 -0.459,1.798c0,0.671 0.153,1.271 0.459,1.798c0.306,0.528 0.731,0.938 1.277,1.232c0.545,0.294 1.159,0.441 1.843,0.441Z"
                style="fill:#fff;fill-rule:nonzero;" />
              <path
                d="M55.42,3.812c1.187,0 2.131,0.345 2.832,1.034c0.702,0.69 1.052,1.694 1.052,3.012l0,5.485l-1.277,0l0,-5.359c0,-0.983 -0.245,-1.732 -0.737,-2.248c-0.491,-0.515 -1.193,-0.773 -2.104,-0.773c-1.019,0 -1.825,0.303 -2.418,0.908c-0.594,0.606 -0.891,1.442 -0.891,2.509l0,4.963l-1.276,0l-0,-9.459l1.222,0l0,1.745c0.348,-0.576 0.831,-1.022 1.448,-1.34c0.617,-0.318 1.334,-0.477 2.149,-0.477Z"
                style="fill:#fff;fill-rule:nonzero;" />
              <path
                d="M71.047,9.009l-7.912,0c0.071,0.983 0.449,1.778 1.132,2.383c0.684,0.605 1.547,0.908 2.59,0.908c0.587,0 1.127,-0.105 1.618,-0.315c0.492,-0.209 0.917,-0.518 1.277,-0.926l0.719,0.827c-0.419,0.504 -0.944,0.888 -1.573,1.151c-0.629,0.264 -1.322,0.396 -2.077,0.396c-0.971,-0 -1.831,-0.207 -2.581,-0.62c-0.749,-0.414 -1.333,-0.986 -1.753,-1.718c-0.419,-0.731 -0.629,-1.558 -0.629,-2.481c-0,-0.923 0.201,-1.751 0.602,-2.482c0.402,-0.731 0.953,-1.301 1.655,-1.708c0.701,-0.408 1.489,-0.612 2.364,-0.612c0.875,0 1.661,0.204 2.356,0.612c0.695,0.407 1.241,0.974 1.636,1.699c0.396,0.725 0.594,1.556 0.594,2.491l-0.018,0.395Zm-4.568,-4.1c-0.911,0 -1.675,0.291 -2.293,0.872c-0.617,0.582 -0.968,1.34 -1.051,2.275l6.707,0c-0.084,-0.935 -0.435,-1.693 -1.052,-2.275c-0.617,-0.581 -1.388,-0.872 -2.311,-0.872Z"
                style="fill:#fff;fill-rule:nonzero;" />
              <path
                d="M82.304,0l-0,13.343l-1.223,0l0,-1.87c-0.383,0.635 -0.89,1.121 -1.519,1.456c-0.63,0.336 -1.34,0.504 -2.131,0.504c-0.887,-0 -1.691,-0.204 -2.41,-0.611c-0.719,-0.408 -1.283,-0.977 -1.69,-1.709c-0.408,-0.731 -0.612,-1.564 -0.612,-2.499c0,-0.935 0.204,-1.769 0.612,-2.5c0.407,-0.731 0.971,-1.298 1.69,-1.699c0.719,-0.402 1.523,-0.603 2.41,-0.603c0.767,0 1.459,0.159 2.077,0.477c0.617,0.318 1.124,0.782 1.519,1.393l0,-5.682l1.277,0Zm-4.765,12.3c0.659,0 1.255,-0.153 1.789,-0.458c0.533,-0.306 0.953,-0.741 1.259,-1.304c0.305,-0.564 0.458,-1.205 0.458,-1.924c0,-0.72 -0.153,-1.361 -0.458,-1.924c-0.306,-0.564 -0.726,-0.999 -1.259,-1.304c-0.534,-0.306 -1.13,-0.459 -1.789,-0.459c-0.672,0 -1.274,0.153 -1.808,0.459c-0.533,0.305 -0.953,0.74 -1.258,1.304c-0.306,0.563 -0.459,1.204 -0.459,1.924c-0,0.719 0.153,1.36 0.459,1.924c0.305,0.563 0.725,0.998 1.258,1.304c0.534,0.305 1.136,0.458 1.808,0.458Z"
                style="fill:#fff;fill-rule:nonzero;" />
              <path
                d="M95.449,3.812c0.887,0 1.691,0.201 2.41,0.603c0.719,0.401 1.283,0.968 1.69,1.699c0.408,0.731 0.612,1.565 0.612,2.5c-0,0.935 -0.204,1.768 -0.612,2.499c-0.407,0.732 -0.971,1.301 -1.69,1.709c-0.719,0.407 -1.523,0.611 -2.41,0.611c-0.791,-0 -1.501,-0.168 -2.131,-0.504c-0.629,-0.335 -1.136,-0.821 -1.519,-1.456l-0,1.87l-1.223,0l-0,-13.343l1.277,-0l-0,5.682c0.395,-0.611 0.902,-1.075 1.519,-1.393c0.618,-0.318 1.31,-0.477 2.077,-0.477Zm-0.09,8.488c0.66,0 1.259,-0.153 1.799,-0.458c0.539,-0.306 0.962,-0.741 1.267,-1.304c0.306,-0.564 0.459,-1.205 0.459,-1.924c-0,-0.72 -0.153,-1.361 -0.459,-1.924c-0.305,-0.564 -0.728,-0.999 -1.267,-1.304c-0.54,-0.306 -1.139,-0.459 -1.799,-0.459c-0.671,0 -1.273,0.153 -1.807,0.459c-0.533,0.305 -0.953,0.74 -1.259,1.304c-0.305,0.563 -0.458,1.204 -0.458,1.924c-0,0.719 0.153,1.36 0.458,1.924c0.306,0.563 0.726,0.998 1.259,1.304c0.534,0.305 1.136,0.458 1.807,0.458Z"
                style="fill:#fff;fill-rule:nonzero;" />
              <path
                d="M110.447,3.884l-4.73,10.592c-0.383,0.887 -0.827,1.517 -1.33,1.888c-0.504,0.372 -1.109,0.558 -1.817,0.558c-0.455,-0 -0.881,-0.072 -1.276,-0.216c-0.396,-0.144 -0.738,-0.36 -1.025,-0.648l0.593,-0.953c0.48,0.48 1.055,0.72 1.726,0.72c0.432,-0 0.801,-0.12 1.106,-0.36c0.306,-0.24 0.591,-0.647 0.854,-1.223l0.414,-0.917l-4.226,-9.441l1.331,0l3.56,8.038l3.561,-8.038l1.259,0Z"
                style="fill:#fff;fill-rule:nonzero;" />
            </g>
            <defs>
              <linearGradient id="_Linear1" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(3.17398e-15,51.835,-51.835,3.17398e-15,144.967,11.3455)">
                <stop offset="0" style="stop-color:#21b8b4;stop-opacity:1" />
                <stop offset="1" style="stop-color:#21b8b4;stop-opacity:1" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div class="links-area">
          <div class="links">
            <span>
              <router-link to="/impressum" target="_blank">Impressum</router-link>
            </span>
            |
            <span>
              <router-link to="/datenschutz" target="_blank">Datenschutz</router-link>
            </span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
  footer {

    color: #fff;
  }
  footer {

  }
  footer .message-container {
    padding: 130px 0 130px 0;
    background-image: linear-gradient(45deg, #1FA19E, #58C8ED);
  }
  @media screen and (max-width: 700px) {
    footer {
      padding-top: 10px;
    }
    footer .message-container {
      padding: 20px 0 50px;
    }
  }
  footer .message-container .inner {
    max-width: 1000px;
    margin: auto;
    display: flex;
  }
  @media screen and (max-width: 700px) {
    footer .message-container .inner {
      max-width: 1000px;
      margin: auto;
      display: block;
    }
  }

  footer .message-container .inner .area:before,
  footer .message-container .inner .area .area-inner {
    vertical-align: middle;
    display: inline-block;
  }
  @media screen and (max-width: 700px) {
    footer .message-container .inner .area .area-inner {
      width: 100%;
      box-sizing: border-box;
      padding: 0 20px;
    }
  }
  footer .message-container .inner .area:before {
    content: "";
    width: 0;
    background-color: #f00;
    height: 100%;
  }
  footer .message-container .inner .area {

  }
  footer .message-container .inner .area-left {
    flex: 1;
    padding: 0 40px 0 20px;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  }
  @media screen and (max-width: 700px) {
    footer .message-container .inner .area-left {
      text-align: center;
      padding: 20px;
    }
  }
  footer .message-container .inner .area-left h2 {
    margin: 0 0 40px;
    font-size: 2.8em;
  }
  footer .message-container .inner .area-left article {
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.025em;
    line-height: 130%;
    margin-top: 40px;
  }
  footer .message-container .inner .area-right {
    flex: none;
    padding: 0 10px 0 40px;
  }
  @media screen and (max-width: 700px) {
    footer .message-container .inner .area-right {
      text-align: center;
      padding: 50px 20px 0;
    }
  }
  footer .message-container .inner .area-right .form {

  }
  footer .message-container .inner .area-right .form .input-main {

  }
  @media screen and (max-width: 700px) {
    footer .message-container .inner .area-right .form .input-main {
      width: 100%;
    }
  }
  .msg-textarea {
    width: 100%;
    width: calc(100% - 0px);
    resize: none;
    color: rgb(15, 15, 15);
    outline: none;
    border: none;
    font-size: 1rem;
    border-radius: 7px;
    margin: 0 0 10px 0;
    min-height: 100px;
    padding: 13px 13px;
    box-sizing: border-box;
    font-family: 'Montserrat';
  }
  footer .message-container .inner .area-right .form .input-main > input[type="text"],
  footer .message-container .inner .area-right .form .input-main > button {
    height: 45px;
    vertical-align: middle;
    outline: none;

  }
  footer .message-container .inner .area-right .form .input-main > input[type="text"] {
    font-size: 1rem;
    padding: 0px 13px;
    font-family: 'Montserrat';
    border: none;
    border-radius: 7px 0 0 7px;
    width: 100%;
    width: calc(100% - 70px);
    box-sizing: border-box;
  }
  @media screen and (max-width: 700px) {
    footer .message-container .inner .area-right .form .input-main > input[type="text"] {
      width: calc(100% - 70px)
    }
  }

  footer .message-container .inner .area-right .form .input-main > input[type="text"]::placeholder {
    color: #878787;
  }
  footer .message-container .inner .area-right .form .input-main > button {
    width: 70px;
    box-sizing: border-box;
    border: 3px solid #fff;
    background-color: transparent;
    border-radius: 0 7px 7px 0;
    cursor: pointer;
    padding: 7px 0;

  }
  footer .message-container .inner .area-right .form .input-main > button > svg {
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3));
  }
  footer .message-container .inner .area-right .form .check-main {

  }
  footer .message-container .inner .area-right .form .check-main .checks-list {

  }
  footer .message-container .inner .area-right .form .check-main > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    max-width: 400px;
  }
  footer .message-container .inner .area-right .form .check-main > ul > li {
    padding: 10px 0;
  }
  footer .message-container .inner .area-right .form .check-main > ul > li > * {
    display: inline-block;
    font-size: 0;
    vertical-align: top;
  }
  footer .message-container .inner .area-right .form .check-main > ul > li .checkbox-container {
    display: inline-block;
  }
  footer .message-container .inner .area-right .form .check-main > ul > li .checkbox-container > input[type="checkbox"] {
    outline: none;
    background-color: #fff;
    display: block;
    width: 20px;
    height: 20px;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    cursor: pointer;
    border-radius: 4px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #157c79;
    background-size: 200%;
    transition: background-size 0.5s;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj4KICA8Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSIzNSIgc3R5bGU9ImZpbGw6ICNmZmY7IiAvPgo8L3N2Zz4=');
  }
  footer .message-container .inner .area-right .form .check-main > ul > li .checkbox-container > input[type="checkbox"]:checked {
    background-size: 80%;
  }
  footer .message-container .inner .area-right .form .check-main > ul > li .checkbox-container > input[type="checkbox"] {

  }
  footer .message-container .inner .area-right .form .check-main > ul > li .label-container {
    font-size: 1rem;
    display: inline-block;
    max-width: calc(100% - 60px);
    line-height: 160%;
    margin: 1px 0 0 0;
    padding: 0 0 0 4px;
    text-align: left;
  }

  footer .info-container {
    background-color: #4D4D4D;
    padding: 20px 0;
  }
  footer .info-container .info-inner {
    width: 100%;
    padding: 30px 50px;
    box-sizing: border-box;
    max-width: calc(100%);
    margin: auto;
    display: flex;
  }
  @media screen and (max-width: 450px) {
    footer .info-container .info-inner {
      display: block;
      padding: 30px 50px;
    }
  }
  footer .info-container .info-inner > * {
    flex: 1;
  }
  footer .emblem-area {

  }
  footer .emblem-area > svg {
    width: 250px;
  }
  @media screen and (max-width: 500px) {
    footer .emblem-area > svg {
      width: 200px;
    }
  }
  @media screen and (max-width: 450px) {
    footer .emblem-area > svg {
      width: 100%;
    }
  }
  footer .links-area {
    text-align: right;
  }
  @media screen and (max-width: 450px) {
    footer .links-area {
      text-align: center;
      margin-top: 20px;
    }
  }
  footer .links-area:before {
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    content: "";
  }
  footer .links-area .links {
    display: inline-block;
    vertical-align: middle;
    transform: translateY(5px);
  }
  footer .links-area .links a {
    color: inherit;
    text-decoration: inherit;
    font-weight: 500;
  }


  footer .result-messages {
    color: #fff;

  }
  footer .result-messages .message {
    background-color: #fff;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    padding: 7px 12px;
    display: inline-block;
    border: 2px solid rgba(0, 0, 0, 0.2);
    font-weight: 700;
    display: block;
    transition: all 0.4s;
    margin: 10px 0;
    opacity: 0;
    transition-timing-function: ease-in-out;
    transform: scale(0);
    max-width: 350px;
    text-align: center;
    display: none;
  }
  footer .result-messages .message.show {
    opacity: 1;
    transform: scale(1);
    display: block;
  }
  footer .result-messages .message.message-error {
    background-color: rgba(255, 0, 0, 0.4);
    border: 2px solid rgba(255, 0, 0, 0.2);
  }

  footer >>> a {
    text-decoration: underline;
    color: inherit;
    font-weight: 600;
  }
  .form-title {
    margin: 0;
    margin: 0 0 10px;
    font-family: 'Montserrat';
    font-size: 1.4em;
  }
</style>

<script>
  import emailValidator from 'email-validator'
  import content from '@/../content/footer.yaml'

  export default {
    name: "PageFooter",
    props: {
      offset: Number,
      background: String,
      hideContact: Boolean,
      logoFillColor: {
        default: 'rgb(29, 189, 131)',
        type: String
      }
    },
    computed: {
      messageIsValid() {
        return /[a-zA-Z]{3,}/.test(this.message);
      },
      mailIsValid() {
        return emailValidator.validate(this.mail);
      }
    },
    methods: {
      async sendMessage() {
        if (this.mailIsValid && this.messageIsValid && this.acceptsTerms) {
          const body = JSON.stringify({
            message: this.message,
            author: this.mail
          });
          await fetch('/contact/', {
            method: 'POST',
            headers: {
              'Content-Length': body.length,
              'Content-Type': 'application/json'
            },
            body
          });
          this.success = true;
        }
        else {
          console.log("!!!");
          console.log(/[0-9]{5,}/.test(this.phone));
          this.error = true;
        }
        setTimeout(() => {
          this.error = this.success = false;
        }, 3000);
      }
    },
    created() {
      console.log(this.logoFillColor);
    },
    data() {
      return {
        content,
        acceptsTerms: false,
        message: '',
        mail: '',
        error: false,
        success: false
      }
    }
  }
</script>
