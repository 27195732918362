<template>
  <div class="story story-science">
    <div class="header">
      <div class="title">
        <div class="line">
          <span>UND FÜR DIE</span>
        </div>
        <div class="line">
          <span>WISSENSCHAFT</span>
        </div>
      </div>
      <div class="asset-circle" />
    </div>
    <div class="body">
      <div class="grid-1">
        <div class="fixed-image image-2">
          <!--<iframe :src="content.videos[0]" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
          <img :src="content.images[0]" alt="Science 1" />
        </div>
      </div>
      <div class="grid-2">
        <div class="fixed-image image-2">
          <!--<iframe :src="content.videos[1]" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
          <img :src="content.images[1]" alt="Science 1" />
        </div>
        <div class="fixed-image image-3">
          <!--<iframe :src="content.videos[2]" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
          <img :src="content.images[2]" alt="Science 1" />
        </div>
      </div>
      <div class="grid-3">
        <article v-html="content.description"></article>
        <div class="btn-container">
          <router-link :to="content.button.href">
            <button class="btn">
              {{ content.button.label }}
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
  .story-science {

  }
  .story .body {
    display: grid;
    width: 100%;
    max-width: 900px;
    padding: 0 20px;
    box-sizing: border-box;
    margin: auto;
    grid-template-columns: auto auto;
    grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content);
  }
  .story .body > * {
    padding: 20px 0;
  }
  .story .body .grid-1 {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
  }
  .story .body .grid-2 {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
  }
  .story .body .grid-3 {
    grid-column: 2 / span 1;
    grid-row: 1 / span 2;
    padding-left: 40px;

  }

  @media screen and (max-width: 750px) {
    .story .body {
      grid-template-columns: 100%;
      grid-auto-rows: minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
    }
    .story .body .grid-1 {
      grid-column: 1 / span 1;
      grid-row: 1 / span 1;
    }
    .story .body .grid-2 {
      grid-column: 1 / span 1;
      grid-row: 3 / span 1;
    }
    .story .body .grid-3 {
      grid-column: 1 / span 1;
      grid-row: 2 / span 1;
      padding-left: 0;

    }
  }

  .story .body > * article {
    font-size: 1.1em;
    font-weight: 300;
    letter-spacing: 0.02em;
    line-height: 130%;
    margin-top: 200px;
  }
  @media screen and (max-width: 750px) {
    .story .body > * article {
      max-width: calc(100% - 50px);
      display: block;
      margin: auto;
    }
  }
  @media screen and (max-width: 375px) {
    .story .body > * article {
      max-width: calc(100% - 6px);
    }
  }

  .fixed-image {
    background-size: cover;
    background-position: center;
    width: 100%;
    min-width: 300px;
    /*--width: 500px;
    --ratio: 0.6;
    width: var(--width);
    height: calc(var(--width) * var(--ratio));
    object-fit: cover;*/
  }
  .fixed-image iframe,
  .fixed-image img {
    width: 100%;
    max-height: 100%;
  }
  .fixed-image.image-1 {

  }
  .fixed-image.image-2 {

  }
  .fixed-image.image-3 {

    margin:  40px 0 0 0;
  }
  @media screen and (max-width: 800px) {
    .fixed-image {
      --width: 50vw;
    }
  }
  @media screen and (max-width: 750px) {
    .fixed-image {
      --width: 100%;

    }
  }



  .btn-container {
    text-align: right;
    margin: 80px 0 0 0;
    padding: 0 20px 0 0;
    box-sizing: border-box;
  }
  @media screen and (max-width: 600px) {
    .btn-container {
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .btn {
    outline: none;
    font-size: 1.6rem;
    color: #fff;
    background-image: linear-gradient(to left, #58C8ED, #1FA19E);
    border: none;
    border-radius: 13px;
    padding: 15px 0;
    text-align: center;
    min-width: 270px;
    font-family: 'Montserrat';
    font-weight: 800;
    display: inline-block;
    cursor: pointer;
  }
  .btn {
    transition: all 0.3s;
    transform: scale(1);
  }
  .btn:hover {
    transform: scale(1.05);
  }
  @media screen and (max-width: 900px) {
    .btn {
      min-width: calc(100% - 20px);
    }
  }
  @media screen and (max-width: 800px) {
    .btn {
      font-size: 1.4rem;
      padding: 12px 0;
    }
  }
  @media screen and (max-width: 750px) {
    .btn {
      font-size: 1.2rem;
      padding: 10px 0;
    }
  }
  @media screen and (max-width: 600px) {
    .btn-container {

    }
    .btn {
      font-size: 1.6rem;
      min-width: calc(250px);
    }
  }

</style>

<style scoped>
  .story {
    margin-top: 30px;
    padding: 100px 0 50px 0;
  }
  .story .header {
    position: relative;
  }
  .story .header .title {
    font-size: 3em;
    color: #fff;
    font-weight: 900;
  }
  @media screen and (max-width: 600px) {
    .story .header .title {
      font-size: 2.5em;
    }
  }
  @media screen and (max-width: 500px) {
    .story .header .title {
      font-size: 2em;
    }
  }
  @media screen and (max-width: 400px) {
    .story .header .title {
      font-size: 1.6em;
    }
  }
  .story .header .title .line {
    margin: 20px 0 0 0;
  }
  .story .header .title .line > span {
    background-color: #125D5C;
    padding: 5px 15px;
    display: block;


    width: calc(100% - 850px + 400px);
  }
  @media screen and (max-width: 850px) {
    .story .header .title .line > span {
      width: calc(100% - 100px);
    }
  }
  .story .header .title .line:nth-child(odd) {

  }
  .story .header .title .line:nth-child(even) {
    height: 130px;
    position: relative;
    z-index: 10;
  }
  .story .header .title .line:nth-child(odd) > span {
    padding-left: 40px;
    width: calc((100% - 850px) / 2 + 600px) !important;
    text-align: right;
    box-sizing: border-box;
    padding-right: 100px;
  }
  .story .header .title .line:nth-child(even) > span {
    float: right;
    width: calc((100% - 850px) / 2 + 500px);
    border-radius: 10px 0 0 10px;

  }
  @media screen and (max-width: 850px) {
    .story .header .title .line:nth-child(even) > span {
      width: 500px;
    }
  }
  @media screen and (max-width: 600px) {
    .story .header .title .line:nth-child(even) > span {
      width: 450px;
    }
  }
  @media screen and (max-width: 500px) {
    .story .header .title .line:nth-child(even) > span {
      width: 300px;
    }
  }
  @media screen and (max-width: 400px) {
    .story .header .title .line:nth-child(even) > span {
      width: 250px;
    }
  }


  .asset-circle {
    width: 550px;
    height: 550px;
    background-image: linear-gradient(to left, #F0BDC6, #FAE191);
    position: relative;
    border-radius: 50%;
    position: absolute;
    float: right;
    right: calc((100% - 850px) / 2);
    transform: translate(40%, 00px);
    bottom: 00px;
  }

</style>

<script>
  import contentSite from '@/../content/work.yaml'
  const content = contentSite["story-4"];

  export default {
    name: "StorySciencePreview",
    data() {
      return {
        content
      }
    }
  }
</script>
