import { createWebHistory, createRouter } from "vue-router";

import CreatePage from '@/pages/Create';
import WorkPage from '@/pages/Work';
import ConnectPage from '@/pages/Connect';
import PurposePage from '@/pages/Purpose';

import StoryPage from '@/pages/Story.vue';

import CreateCover from '@/covers/Create.vue';
import WorkCover from '@/covers/Work.vue';
import ConnectCover from '@/covers/Connect.vue';
import PurposeCover from '@/covers/Purpose.vue';
import DatenschutzCover from '@/covers/Datenschutz.vue';
import ImpressumCover from '@/covers/Impressum.vue';


import StoryMovies from '@/stories/StoryMovies.vue'
import StoryFFF from '@/stories/StoryFFF.vue'
import StoryPolitics from '@/stories/StoryPolitics.vue'
import StoryScience from '@/stories/StoryScience.vue'
import StoryFlyNice from '@/stories/StoryFlyNice.vue'

import ImpressumPage from '@/pages/Impressum.vue'
import DatenschutzPage from '@/pages/Datenschutz.vue'

const routes = [
  {
    path: "/",
    redirect: "/create"
  },
  {
    path: "/story/:id",
    name: "Story",
    component: StoryPage,
    meta: {
      stories: {
        'flynice': StoryFlyNice,
        'fuer-die-wissenschaft': StoryScience,
        'mit-viel-engagement': StoryFFF,
        'mit-viel-aufwand': StoryMovies,
        'kommunalwahl-aachen': StoryPolitics
      },
      smallHeader: true,
      transition: 'popup',
      page: StoryPage,
      cover: WorkCover,
      logoColor: '#d10871',
      coverOffsetY: 0,
      bubble: {
        d: 'M 583.37 125.316 C 587.959 230.426 408.289 356.545 303.178 361.134C198.068 365.724 8.95898 230.426 4.36977 125.316 C 189.37 ' + (125.316 +        0) + ' 179.26 ' + (17.4049 +        0) + ' 284.37 ' + (12.8157 +       0) + ' C 387.68 ' + (12.8157 +        0) + ' 378.37 ' + (125.316 +        0) + ' ' + (583.37 -        0) + ' 125.316Z',
        scale: 1,
        pos: "20%"
      }
    }
  },
  {
    path: "/create",
    name: "Create",
    component: CreatePage,
    meta: {
      videoWithAutoplay: true,
      coverOffsetY: 0,
      transition: 'slide',
      page: CreatePage,
      cover: CreateCover,
      logoColor: '#b83580',
      coverMobileFullscreen: true,
      bubble: {
        d: 'M 580 125 C 587 230 408 356 303 361 C 198 366 9 210 20 120 C 199.37 108 179.26 97 284 93 C 397 95 388 107 580 120 Z',
        scale: 3.2,
        pos: "45%"
      }
    }
  },
  {
    path: "/work/:topic?",
    name: "Work",
    component: WorkPage,
    meta: {

      alwaysShowNav: true,
      coverOffsetY: 15,
      transition: 'slide',
      cover: WorkCover,
      page: WorkPage,
      logoColor: '#2aaca0',
      bubble: {
        d: 'M 583.37 125.316 C 587.959 230.426 408.289 356.545 303.178 361.134C198.068 365.724 8.95898 230.426 4.36977 125.316 C 189.37 ' + (125.316 +        0) + ' 179.26 ' + (17.4049 +        0) + ' 284.37 ' + (12.8157 +       0) + ' C 387.68 ' + (12.8157 +        0) + ' 378.37 ' + (125.316 +        0) + ' ' + (583.37 -        0) + ' 125.316Z',
        scale: 1,
        pos: "20%"
      }
    }
  },
  {
    path: "/connect",
    name: "Connect",
    component: ConnectPage,
    meta: {

      alwaysShowNav: true,
      coverOffsetY: 150,
      transition: 'slide',
      cover: ConnectCover,
      page: ConnectPage,
      logoColor: '#ff5f80',
      bubble: {
        d: 'M 583.37 125.316 C 587.959 230.426 408.289 356.545 303.178 361.134C198.068 365.724 8.95898 230.426 4.36977 125.316 C 189.37 ' + (125.316 +        0) + ' 179.26 ' + (17.4049 +        0) + ' 284.37 ' + (12.8157 +       0) + ' C 387.68 ' + (12.8157 +        0) + ' 378.37 ' + (125.316 +        0) + ' ' + (583.37 -        0) + ' 125.316Z',
        scale: 1,
        pos: "50%"
      }
    }
  },
  {
    path: "/purpose",
    name: "Purpose",
    component: PurposePage,
    meta: {
      alwaysShowNav: true,
      coverOffsetY: 0,
      transition: 'slide',
      cover: PurposeCover,
      page: PurposePage,
      logoColor: '#217d91',
      bubble: {
        d: 'M 583.37 125.316 C 587.959 230.426 408.289 356.545 303.178 361.134C198.068 365.724 8.95898 230.426 4.36977 125.316 C 189.37 ' + (125.316 +        0) + ' 179.26 ' + (17.4049 +        0) + ' 284.37 ' + (12.8157 +       0) + ' C 387.68 ' + (12.8157 +        0) + ' 378.37 ' + (125.316 +        0) + ' ' + (583.37 -        0) + ' 125.316Z',
        scale: 1,
        pos: "80%"
      }
    }
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: ImpressumPage,
    meta: {
      alwaysShowNav: true,
      smallHeader: false,
      transition: null,
      page: ImpressumPage,
      cover: ImpressumCover,
      logoColor: '#8AD4ED',
      coverOffsetY: 0,
      bubble: {
        d: 'M 580 125 C 587 230 408 356 303 361 C 198 366 9 210 20 120 C 199.37 108 179.26 97 284 93 C 397 95 388 107 580 120 Z',
        scale: 3.2,
        pos: "45%"
      }
    }
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: DatenschutzPage,
    meta: {
      alwaysShowNav: true,
      smallHeader: false,
      transition: null,
      page: DatenschutzPage,
      cover: DatenschutzCover,
      logoColor: '#8AD4ED',
      coverOffsetY: 0,
      bubble: {
        d: 'M 580 125 C 587 230 408 356 303 361 C 198 366 9 210 20 120 C 199.37 108 179.26 97 284 93 C 397 95 388 107 580 120 Z',
        scale: 3.2,
        pos: "45%"
      }
    }
  }


];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {


  /*const meta = to.meta.metaHeaders(to);
  const headElements = Array.from(document.head.getElementsByTagName("*"));


  for (var metaInfo of meta) {
    const existingMeta = headElements.find(e => metaInfo.__exists(e));
    if (existingMeta) {
      document.head.removeChild(existingMeta);
    }

    const e = document.createElement(metaInfo.tagName);
    for (var attrName in metaInfo.attributes) {
      e.setAttribute(attrName, metaInfo.attributes[attrName]);
    }
    if (metaInfo.inner) {
      e.append(metaInfo.inner);
    }

    document.head.append(e);

  }*/

  next();



});


export default router;
