<template>
  <div class="story" ref="storyFFFContainer">
    <div class="title special-title-1">
      <div class="line">
        <span style="min-width: calc(50% - 50px);">{{ content.title[0] }}</span>
      </div>
      <div class="line">
        <span style="min-width: calc(70% - 50px);">{{ content.title[1] }}</span>
      </div>
    </div>
    <div class="story-view" :style="{ 'background-image': `url('${ toDataURI(background.svg, 'image/svg+xml') }')`, 'background-position': background.position, 'background-size': background.size }">
      <div class="inner">
        <div class="area-avatar">
          <!--<div class="avatar" :style="{ 'background-image': `url('${ data.images.fff1 }')` }" />-->
          <svg class="avatar" xmlns="http://www.w3.org/2000/svg" viewBox="-80 0 380 450">
            <defs>
              <clipPath id="image">
                <rect x="0" y="0" width="300" height="400" rx="15" ry="15"/>
              </clipPath>
              <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" style="stop-color:#8AD4ED;stop-opacity:1" />
                <stop offset="100%" style="stop-color:#25C8C4;stop-opacity:1" />
              </linearGradient>
            </defs>

            <rect x="0" y="0" width="300" height="400" fill="url(#grad1)" rx="15" ry="15" style="transform-origin: 150px 200px; transform: translate(-40px, 40px) rotate(-3deg);" />

            <image clip-path="url(#image)" :href="content.image" x="0" y="0" height="400" width="300" />
          </svg>
        </div>
        <div class="area-about">
          <div class="quote">
            <div class="quote-symbol">
              <svg viewBox="0 0 42 34" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.704 16.032C15.304 16.736 16.552 17.792 17.448 19.2C18.344 20.608 18.792 22.272 18.792 24.192C18.792 26.944 17.928 29.184 16.2 30.912C14.472 32.576 12.264 33.408 9.576 33.408C6.888 33.408 4.648 32.544 2.856 30.816C1.128 29.088 0.264 26.88 0.264 24.192C0.264 22.912 0.424 21.632 0.744 20.352C1.064 19.072 1.768 17.152 2.856 14.592L8.424 0.767996H17.928L13.704 16.032ZM36.36 16.032C37.96 16.736 39.208 17.792 40.104 19.2C41 20.608 41.448 22.272 41.448 24.192C41.448 26.944 40.584 29.184 38.856 30.912C37.128 32.576 34.92 33.408 32.232 33.408C29.544 33.408 27.304 32.544 25.512 30.816C23.784 29.088 22.92 26.88 22.92 24.192C22.92 22.912 23.08 21.632 23.4 20.352C23.72 19.072 24.424 17.152 25.512 14.592L31.08 0.767996H40.584L36.36 16.032Z" fill="white"/>
              </svg>
            </div>
            <div class="quote-text">
              {{ content.subtitle }}
            </div>
          </div>
          <div class="about-text" v-html="content.description"></div>
          <div class="btn-container">
            <TransitionModifier name="popupmobile" :timeout="500" keyName="routerForcedTransition">

            </TransitionModifier>

            <router-link :to="content.button.href">
              <button class="btn">
                {{ content.button.label }}
              </button>
            </router-link>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped>


  .title {
    margin: 40px 0 20px 0;
  }
  .title.special-title-1 {
    font-weight: 900;

  }
  .title.special-title-1 .line {
    font-size: 3em;
    color: #fff;
    margin: 30px 0;

  }
  @media screen and (max-width: 600px) {
    .title.special-title-1 .line {
      font-size: 2em;
    }
  }
  .title.special-title-1 .line:nth-child(1) {
    text-align: left;
  }
  .title.special-title-1 .line:nth-child(2) {
    text-align: right;
  }
  .title.special-title-1 .line > span {
    background-color: #125D5C;
    padding: 8px 12px;
    display: inline-block;
  }
  .title.special-title-1 .line:nth-child(1) > span {
    text-align: right;
  }
  .title.special-title-1 .line:nth-child(2) > span {
    text-align: left;
  }

</style>

<style scoped>
  .story-view {
    background-color: #125D5C;
    background-repeat: no-repeat;
  }
  .story-view .inner {
    max-width: 850px;
    margin: auto;
    padding: 80px 0 100px 0px;
    display: flex;
    background-size: 100% 100%;
  }
  .story-view .inner > * {
    display: inline-block;

  }
  @media screen and (max-width: 700px) {
    .story-view .inner {
      display: block;

    }
  }
  @media screen and (max-width: 500px) {
    .story .inner {
      font-size: 0.8em;
    }
  }
  .story-view .inner .area-avatar {
    margin-top: 50px;
    flex: none;
  }
  .story-view .inner .area-avatar .avatar {
    width: 400px;
    border-radius: 15px;
    background-position: 25% center;
    background-size: cover;
  }
  @media screen and (max-width: 1000px) {
    .story-view .inner .area-avatar .avatar {
      width: 350px;
    }
  }
  @media screen and (max-width: 700px) {
    .story-view .inner .area-avatar {
      width: 100%;
      text-align: center;
    }
    .story-view .inner .area-avatar  .avatar {
      width: 500px;
      max-width: calc(100% - 50px);
      display: inline-block;
      transform: translateX(-7%);
    }
  }
  .story-view .inner .area-avatar .avatar {

  }
  .story-view .inner .area-avatar .shadow {

  }
  .story-view .inner .area-about {
    color: #fff;
    flex: 1;
    padding: 100px 20px 0 60px;
  }
  @media screen and (max-width: 900px) {
    .story-view .inner .area-about {
      padding-left: 30px;
    }
  }

  .story-view .inner .area-about .quote {
    font-weight: 500;
    display: flex;
  }

  .story-view .inner .area-about .quote .quote-symbol {
    width: 40px;
    height: 40px;
    flex: none;
  }
  .story-view .inner .area-about .quote > * {
    display: inline-block;
  }
  .story-view .inner .area-about .quote .quote-symbol > svg {
    width: 100%;
    height: 100%;
  }
  .story-view .inner .area-about .quote .quote-text {
    font-size: 1.6em;
    flex: 1;
    letter-spacing: 0.5px;
    padding: 0 0 0 10px;
  }
  .story-view .inner .area-about .about-text {
    margin: 30px 0 0 0;
    letter-spacing: 0.025em;
    line-height: 140%;
  }
  @media screen and (max-width: 700px) {
    .story-view .inner .area-about {
      padding-top: 60px;
    }
    .story-view .inner .area-about > * {
      width: calc(100% - 30px);
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .story-view .inner .area-about .quote .quote-text {
      font-size: 2.5em;

    }
    .story-view .inner .area-about .about-text {
      font-size: 1.3em;

    }
  }
  @media screen and (max-width: 400px) {
    .story-view .inner .area-about .quote .quote-text {
      font-size: 2em;
    }

  }
  @media screen and (max-width: 350px) {
    .story-view .inner .area-about .quote .quote-text {
      font-size: 1.5em;
    }
  }
  .btn {
    outline: none;
    font-size: 1.6rem;
    background-color: #fff;
    border: none;
    border-radius: 13px;
    padding: 15px 0;
    text-align: center;
    min-width: 270px;
    font-family: 'Montserrat';
    font-weight: 800;
    display: inline-block;
    cursor: pointer;
  }
  .btn {
    transition: all 0.3s;
    transform: scale(1);
  }
  .btn:hover {
    transform: scale(1.05);
  }
  .story-view .inner .area-about .btn {
    color: #125D5C;
  }
  .story-view .inner .area-about .btn-container {
    padding: 100px 0 0 0;
    float: right;
  }
  @media screen and (max-width: 700px) {
    .story-view .inner .area-about .btn-container {
      padding: 50px 0 0 0;
      float: none;
      text-align: center;
    }
  }
  @media screen and (max-width: 400px) {
    .btn {
      min-width: 100%;
    }
  }
</style>

<script>
  import contentSite from '@/../content/work.yaml'
  const content = contentSite["story-1"];

  import TransitionModifier from '@/components/TransitionModifier.vue';


  export default {
    name: "StoryFFFPreview",
    methods: {
      toDataURI(str, mime) {
        return `data:${ mime };base64,${ btoa(str) }`;
      },
      routeToStory() {
        //console.log(path);
      }
    },
    props: {
      background: Object
    },
    components: {
      TransitionModifier
      //TransitionModifier
    },
    data() {
      return {
        content
      }
    }
  }
</script>
