<template>
  <div class="story-wrapper" ref="wrapper">
    <div class="story-cover">
      <div class="cover-item cover-left">
        <div class="image">
          <img :src="content.background" alt="Robots">
        </div>
        <div class="gradient">
          <div class="title">
            <span class="bold">Guerilla-Aktion</span>
            <span>flynice.de</span>
          </div>
        </div>
      </div>
      <div class="cover-item cover-right">

      </div>
    </div>
    <div class="story-content" ref="storyContentContainer">
      <div class="cover-space" :style="{ width: offsetLeft + 'px' }" />
      <div class="content content-fff" ref="content">
        <div class="inner">
          <div class="part" style="vertical-align: top;">
            <article>
              <p v-html="content['paragraph-1']"></p>
            </article>
          </div>

          <div class="part part-fixed">
            <div class="image-display">
              <div class="image-container">
                <iframe class="video-frame" :src="content['item-1'].video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="about-container">
                <div class="description" style="max-width: 300px;">
                  <p v-html="content['item-1']['info-1']"></p>
                </div>
                <!--<div class="info">
                  <div class="value">
                    {{ content['item-1']['info-2'].streams }}
                  </div>
                  <div class="label">
                    {{ content['item-1']['info-2'].label }}
                  </div>
                </div>-->
              </div>
            </div>
          </div>

          <div class="part" style="vertical-align: top;">
            <article>
              <p>
                {{ content['message-description'] }}
              </p>
              <img :src="content['message-image']" alt="">
              <p v-html="content['message-about']"></p>
            </article>
          </div>


        </div>
      </div>
      <div class="cover-space" :style="{ width: offsetRight + 'px' }" />
    </div>
  </div>
</template>


<style scoped>
  .content.content-fff {
    width: 1900px;

  }
  @media screen and (max-width: 600px) {
    .content.content-fff {
      width: 310vw;
    }
  }

  .content .part article {
    max-width: 450px;
  }
  @media screen and (max-width: 600px) {
    .content .part article {
      max-width: calc(100vw - 70px);
    }
  }
  .content .part a {
    color: inherit;
    color: #5fa8d7;
  }
</style>

<style scoped>
  @import "./story.css";

</style>



<script>
  import content from '@/../content/story/flynice.yaml'


  function bindBounds(app, refId, storageKey) {
    app[storageKey][refId] = app.$refs[refId].getBoundingClientRect();
    window.addEventListener("resize", () => {
      if (app.$refs[refId]) {
        app[storageKey][refId] = app.$refs[refId].getBoundingClientRect();
      }
    });
  }

  export default {
    name: "StoryFlyNice",
    computed: {
      showFirstCover() {
        return this.scroll - this.offsetRight >= this.endPos;
      },
      startPos() {
        return this.offsetLeft;
      },
      endPos() {
        const offset = this.bounds.content ? (this.bounds.content.width - this.bounds.wrapper.width) : 0;
        return -offset - this.offsetRight;
      }
    },
    mounted() {
      bindBounds(this, 'wrapper', 'bounds');
      bindBounds(this, 'content', 'bounds');
    },
    data() {
      return {
        content,
        bounds: {},
        offsetLeft: window.innerWidth < 600 ? 250 : 400,
        offsetRight: 0
      }
    }
  }
</script>
