<template>
  <div class="support-message-container">
    <div class="header">
      <h2>{{ content.title }}</h2>
    </div>
    <div class="content">
      <article>
        {{ content.description }}
      </article>
    </div>
    <div class="footer">
      <a class="mail-address" :href="'mailto:' + content.mail">
        {{ content.mail }}
      </a>
    </div>
  </div>
</template>

<style scoped>
  .support-message-container {
    padding: 70px 0;
    color: #fff;
    background-image: linear-gradient(to left, #F194A2, #FAE191);
  }
  .support-message-container .header {
    max-width: 800px;
    margin: auto;
  }
  .support-message-container .header > h2 {
    font-size: 5em;
    line-height: 150%;
    font-weight: 900;
    margin: 0;
    padding: 0 20px;
  }
  @media screen and (max-width: 600px) {
    .support-message-container .header > h2 {
      font-size: 2.5em;
    }
  }
  .support-message-container .content {
    background-color: transparent;
    padding: 40px 0 40px 20px;
    font-size: 1.2em;
    max-width: 700px;
    margin: auto;
    display: block;
    color: #2e2e2e;
    letter-spacing: 0.02em;
    line-height: 140%;
  }
  @media screen and (max-width: 600px) {
    .support-message-container .content {
      font-size: 1em;
    }
  }
  .support-message-container .content > article {
    max-width: 500px;
  }
  .support-message-container .footer {
    text-align: right;
    max-width: 1000px;
    margin: auto;
    padding: 40px 20px 0;
  }
  .support-message-container .footer > a {
    text-decoration: inherit;
    color: inherit;
  }
    .support-message-container .footer .mail-address {
    font-size: 2em;
    font-weight: 900;
  }
  @media screen and (max-width: 600px) {
    .support-message-container .footer .mail-address {
      font-size: 1.6em;
    }
  }
</style>

<script>
  import content from '@/../content/connect.yaml'

  export default {
    name: "SupportMessage",
    data() {
      return {
        content: content['contact']
      }
    }
  }
</script>
