<template>
  <div class="roadmap">
    <div class="road-step step-1 step-left">
      <div class="road-step-wrapper">
        <div class="title">
          <h2 class="shadow">
            <span>{{ content[0].number }}</span>
          </h2>
          <h1 class="label">
            <span ref="step1TitleLabel">{{ content[0].title }}</span>
          </h1>
        </div>
        <div class="description">
          {{ content[0].description }}
        </div>
      </div>
    </div>
    <div class="road-step step-2 step-center">
      <div class="road-step-wrapper">
        <div class="title">
          <h2 class="shadow">
            <span>{{ content[1].number }}</span>
          </h2>
          <h1 class="label">
            <span ref="step2TitleLabel">{{ content[1].title }}</span>
          </h1>
        </div>
        <div class="description">
          {{ content[1].description }}
        </div>
      </div>
    </div>
    <div class="road-step step-3 step-right">
      <div class="road-step-wrapper">
        <div class="title">
          <h2 class="shadow">
            <span>{{ content[2].number }}</span>
          </h2>
          <h1 class="label">
            <span ref="step3TitleLabel">{{ content[2].title }}</span>
          </h1>
        </div>
        <div class="description">
          {{ content[2].description }}
        </div>
      </div>
    </div>
    <div class="canvas">
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <defs>
          <linearGradient id="gradient-line-1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%"   stop-color="#05a"/>
            <stop offset="100%" stop-color="#0a5"/>
          </linearGradient>
        </defs>
        <path v-bind:d="canvasLine1" class="canvas-line" style="stroke: url(#gradient-line-1);" />
        <path v-bind:d="canvasLine2" class="canvas-line" style="stroke: url(#gradient-line-1);" />
        <g class="bee bee-1">
          <svg width="50px" height="50px" viewBox="0 0 1120 1420" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
            <g>
              <g>
                <path d="M375.639,712.768c-450.621,182.112 -480.488,480.575 -171.537,643.266c442.24,232.881 970.006,-229.773 861.975,-467.44c-128.653,-283.036 -466.755,-266.224 -690.438,-175.826Z" style="fill:#ffb543;"/>
                <path d="M624.625,655.331c-87.692,5.484 -174.656,27.398 -248.986,57.437c-68.314,27.609 -126.958,57.891 -176.18,89.945c0.428,67.881 19.31,328.974 266.237,609.071c2.356,2.672 4.709,5.304 7.062,7.899c161.088,-2.745 314.634,-71.789 427.076,-164.272l-1.006,-2.299c0,-0 -258.465,-216.094 -274.203,-597.781Z"/>
              </g>
              <path d="M401.37,828.556c-0,0 -343.075,34.308 -124.365,-566.073c124.426,-341.563 269.156,-286.678 335.458,-185.181c50.24,76.909 42.667,167.8 20.482,206.623c-53.191,93.085 -68.745,157.402 60.038,-0c192.98,-235.864 749.493,-110.093 171.538,321.633c-355.94,265.883 -308.768,42.884 -463.151,222.998Z" style="fill:#c3eff2;"/>
            </g>
          </svg>
        </g>

        <g class="bee bee-2">
          <svg width="50px" height="50px" viewBox="0 0 1120 1420" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
            <g>
              <g>
                <path d="M744.329,712.768c450.621,182.112 480.488,480.575 171.537,643.266c-442.24,232.881 -970.006,-229.773 -861.975,-467.44c128.653,-283.036 466.755,-266.224 690.438,-175.826Z" style="fill:#ffb543;"/>
                <path d="M495.343,655.331c87.692,5.484 174.656,27.398 248.986,57.437c68.314,27.609 126.958,57.891 176.18,89.945c-0.428,67.881 -19.31,328.974 -266.237,609.071c-2.356,2.672 -4.709,5.304 -7.062,7.899c-161.088,-2.745 -314.634,-71.789 -427.076,-164.272l1.006,-2.299c-0,-0 258.465,-216.094 274.203,-597.781Z"/>
              </g>
              <path d="M718.598,828.556c0,0 343.075,34.308 124.365,-566.073c-124.426,-341.563 -269.155,-286.678 -335.458,-185.181c-50.24,76.909 -42.667,167.8 -20.482,206.623c53.191,93.085 68.745,157.402 -60.038,-0c-192.98,-235.864 -749.493,-110.093 -171.538,321.633c355.94,265.883 308.768,42.884 463.151,222.998Z" style="fill:#c3eff2;"/>
            </g>
          </svg>

        </g>
      </svg>
    </div>
  </div>
</template>

<style scoped>
.roadmap {
  display: grid;
  grid-template-columns: [all] 100%;
  grid-template-rows: repeat(3, 350px [col-start]);
}
.roadmap .road-step {
  grid-column: all / span 1;
  grid-row-end: span 1;
  display: inline-block;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 20px;
}
.roadmap .road-step.step-1 {
  grid-row-start: 1;
}
.roadmap .road-step.step-2 {
  grid-row-start: 2;
}
.roadmap .road-step.step-3 {
  grid-row-start: 3;
}

.roadmap .road-step.step-left {
  text-align: left;
}
.roadmap .road-step.step-center {
  text-align: center;
}
.roadmap .road-step.step-right {
  text-align: right;
}


.roadmap .canvas {
  display: block;
  grid-column: all / span 1;
  grid-row: 1 / span 3;

}
.roadmap .canvas .canvas-line {
  fill: none;
  stroke-width: 7px;
  stroke: #333;
  stroke-linecap: round;
}
.roadmap .canvas .bee {

}
.roadmap .canvas .bee.bee-1 {
  transform: translate(calc(50% + 150px), 170px);
}
@media screen and (max-width: 800px) {
  .roadmap .canvas .bee.bee-1 {
    transform: translate(calc(50% + 100px), 120px);
  }
}
@media screen and (max-width: 650px) {
  .roadmap .canvas .bee.bee-1 {
    transform: translate(calc(50% + 120px), 120px);
  }
}
@media screen and (max-width: 600px) {
  .roadmap .canvas .bee.bee-1 {
    transform: translate(300px, 120px);
  }
}
@media screen and (max-width: 400px) {
  .roadmap .canvas .bee.bee-1 {
    transform: translate(250px, 120px);
  }
}

.roadmap .canvas .bee.bee-2 {
  transform: translate(180px, 550px);
}
@media screen and (max-width: 1000px) {
  .roadmap .canvas .bee.bee-2 {
    transform: translate(17vw, 550px) !important;
  }
}
@media screen and (max-width: 800px) {
  .roadmap .canvas .bee.bee-2 {
    transform: translate(10vw, 550px) !important;
  }
}
@media screen and (max-width: 600px) {
  .roadmap .canvas .bee.bee-2 {
    transform: translate(12vw, 550px) !important;
  }
}
@media screen and (max-width: 400px) {
  .roadmap .canvas .bee.bee-2 {
    transform: translate(8vw, 550px) !important;
  }
}

.road-step-wrapper {
  display: inline-block;
  text-align: left;

}
.road-step-wrapper .title {
  display: grid;
  grid-template-columns: [all] 100%;
  grid-template-rows: [all] 100%;
}
.road-step-wrapper .title > * {
  grid-column: all / span 1;
  grid-row: all / span 1;
  display: inline-block;
}
.road-step-wrapper .title > *:before {
  content: "";
  width: 1px;
  height: 100%;
}
.road-step-wrapper .title > *:before,
.road-step-wrapper .title > * > * {
  vertical-align: middle;
  display: inline-block;
}
.road-step-wrapper .title .shadow {
  margin: 0;
  font-size: 8em;
  font-weight: 800;
  color: #d7ecec;
}
.road-step-wrapper .title .label {
  margin: 0 0 0 35px;
  font-size: 3.2em;
  font-weight: 700;
  transform: translateY(6px);
  color: #3c3c3c;

}
@media screen and (max-width: 600px) {
  .road-step-wrapper .title .shadow {
    font-size: 6em;
  }
  .road-step-wrapper .title .label {
    font-size: 2.2em;
  }
}
.road-step-wrapper .description {
  padding: 0 0 0 35px;
  max-width: 400px;

}
@media screen and (max-width: 600px) {
  .road-step-wrapper .description {
    max-width: 200px;
    font-size: 0.9em;

  }
}
</style>

<script>
  import contentSite from '@/../content/work.yaml'
  const content = contentSite["roadmap"];

  function bindBounds(app, refId, storageKey) {
    app[storageKey][refId] = app.$refs[refId].getBoundingClientRect();
    window.addEventListener("resize", () => {
      if (app.$refs[refId]) {
        app[storageKey][refId] = app.$refs[refId].getBoundingClientRect();
      }
    });
  }

  export default {
    name: "Roadmap",
    computed: {
      canvasLine1() {
        const start = { x: 370, y: 90 };
        const end = { x: 500, y: 340 };
        const c1 = { x: 650, y: 100 };
        const c2 = { x: 680, y: 300 };

        const offsetDrawX = 30;

        if (this.bounds.step1TitleLabel) {
          const roadmap = this.$refs.step1TitleLabel.closest(".roadmap");
          const outerOffsetX = roadmap.getBoundingClientRect().x;
          const outerOffsetY = roadmap.getBoundingClientRect().y;

          {
            const { x, width, y, height } = this.bounds.step1TitleLabel;
            start.x = (x - outerOffsetX) + width + offsetDrawX;
            start.y = (y - outerOffsetY) + height / 2;
          }
          {
            const { x, width, y, height } = this.bounds.step2TitleLabel;
            end.x = (x - outerOffsetX) + width + offsetDrawX;
            end.y = (y - outerOffsetY) + height / 2;
          }


          c1.x = Math.min(roadmap.getBoundingClientRect().width, start.x + 400);
          c1.y = start.y + (this.isMobile() ? 20 : -50);

          c2.x = Math.min(roadmap.getBoundingClientRect().width, end.x + 350);
          c2.y = end.y - 50;

        }
        const c = coords => coords.x + " " + coords.y;



        return `M ${ c(start) } C ${ c(c1) }, ${ c(c2) }, ${ c(end) }`;
      },
      canvasLine2() {
        const start = { x: 250, y: 350 };
        const end = { x: 520, y: 600 };
        const c1 = { x: 0, y: 350 };
        const c2 = { x: 10, y: 500 };


        if (this.bounds.step2TitleLabel) {
          const roadmap = this.$refs.step1TitleLabel.closest(".roadmap");
          const outerOffsetX = roadmap.getBoundingClientRect().x;
          const outerOffsetY = roadmap.getBoundingClientRect().y;

          {
            const { x, y, height } = this.bounds.step2TitleLabel;
            start.x = (x - outerOffsetX) - 60;
            start.y = (y - outerOffsetY) + height / 2;
          }
          {
            const { x, y, height } = this.bounds.step3TitleLabel;
            end.x = (x - outerOffsetX) - (this.isMobile() ? 50 : 80);
            end.y = (y - outerOffsetY) + height / 2;
          }

          c1.y = c1.y + (this.isMobile() ? 100 : 200);
          c2.y = c2.y - (this.isMobile() ? -200 : -400);



        }
        const c = coords => coords.x + " " + coords.y;

        return `M ${ c(start) } C ${ c(c1) }, ${ c(c2) }, ${ c(end) }`;
      }
    },
    methods: {
      isMobile() {
        return window.innerWidth <= 500;
      }
    },
    mounted() {
      bindBounds(this, 'step1TitleLabel', 'bounds');
      bindBounds(this, 'step2TitleLabel', 'bounds');
      bindBounds(this, 'step3TitleLabel', 'bounds');
    },
    data() {
      return {
        content,
        bounds: {}
      };
    }
  }
</script>
