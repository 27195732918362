<template>
  <div class="page-container">
    <FilmStory />
    <FilmGallery />
    <br />
    <br />
    <PageFooter :offset="-60" :background="footer.background" :logo-fill-color="footer['logo-fill-color']">
      <template v-slot:title>
        {{ footer.title }}
      </template>
      <template v-slot:description>
        <span v-html="footer.description"></span>
      </template>
    </PageFooter>
  </div>
</template>

<style scoped>
  @import "../page.css";
  .page-container {
    margin-top: -10vw;
    position: absolute;
  }
</style>

<script>
  import FilmStory from './FilmStory.vue'
  import FilmGallery from './FilmGallery.vue'

  import content from '@/../content/create.yaml'

  import PageFooter from '@/components/PageFooter.vue';


  export default {
    name: "Create",
    components: { FilmStory, FilmGallery, PageFooter },
    data() {
      return {
        footer: content.footer
      };
    }
  }
</script>
