<template>
  <div class="story-wrapper story-science" ref="wrapper">
    <div class="story-cover">
      <div class="cover-item cover-left">
        <div class="image">
          <img :src="content.background" alt="Robots">
        </div>
        <div class="gradient">
          <div class="title">
            <span class="bold">{{ content.title[0] }}</span>
            <span>{{ content.title[1] }}</span>
          </div>
        </div>
      </div>
      <div class="cover-item cover-right">

      </div>
    </div>
    <div class="story-content" ref="storyContentContainer">
      <div class="cover-space" :style="{ width: offsetLeft + 'px' }" />
      <div class="content content-science" ref="content">
        <div class="part">
          <h3 class="part-title">{{ content['subtitle-1'] }}</h3>
          <article class="article-1 article-spaced">
            <p v-for="(paragraph, _i) in content['paragraph-1'].split('\n')" :key="_i">
              {{ paragraph }}
            </p>
          </article>
        </div>

        <div class="part part-fixed">
          <div class="image-display">
            <div class="image-container">
              <img :src="content['image-1']" alt="Science 1">
            </div>
          </div>
        </div>

        <div class="part">
          <h3 class="part-title">{{ content['subtitle-2'] }}</h3>
          <article class="article-1 article-spaced">
            <p>
              {{ content['paragraph-2'] }}
            </p>
          </article>
        </div>

        <div class="part part-fixed">
          <div class="image-display">
            <div class="image-container">
              <iframe class="video-frame-1" :src="content['item-1'].video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="about-container">
              <div class="description" style="max-width: 300px;">
                <p v-html="content['item-1']['info-1']"></p>
              </div>
              <div class="info">
                <div class="value">
                  {{ content['item-1']['info-2'].streams }}
                </div>
                <div class="label">
                  {{ content['item-1']['info-2'].label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cover-space" :style="{ width: offsetRight + 'px' }" />
    </div>
  </div>
</template>


<style scoped>

  .video-frame-1 {
    width: 600px;
    height: 350px;
  }
  @media screen and (max-width: 600px) {
    .video-frame-1 {
      height: calc(100vw * 0.5);
    }
  }

  .content.content-science {
    width: 3100px;

  }
  @media screen and (max-width: 600px) {
    .content.content-science {
      width: calc(420vw + 50px);
    }
  }

  .content .part article {
    max-width: 450px;
  }
  @media screen and (max-width: 600px) {
    .content .part article {
      max-width: calc(100vw - 70px);
    }
  }

  .story-content .content-science .part-title {
    font-size: 4.5em;
    font-weight: 900;
    margin: 0;
    color: #4D4D4D;
    max-width: 500px;
    white-space: normal;
  }

  .story-content .content-science .article-spaced {
    margin-left: 100px;
    margin-top: 70px;
  }

  .story-content .content-science .article-1 {
    max-width: 600px;
  }
  @media screen and (max-width: 600px) {
    .story-content .content-science .part-title {
      font-size: 3em;
      max-width: calc(100vw - 50px);
    }
    .story-content .content-science .article-spaced {
      margin-left: 50px;
      margin-top: 70px;
    }
    .story-content .content-science .article-1 {
      max-width: calc(100vw - 70px);
    }
  }
  @media screen and (max-height: 800px) {
    .story-content .content-science .part-title {
      font-size: 2.5em;
    }
    .story-content .content-science .article-spaced {
      margin-left: 50px;
      margin-top: 30px;
    }
  }
</style>

<style scoped>
  @import "./story.css";

  .story-science .article-3 {
    max-width: 600px !important;
  }
  @media screen and (max-width: 600px) {
    .story-science .article-3 {
      max-width: calc(100vw - 100px) !important;
    }
  }
</style>



<script>

  import content from '@/../content/story/story-science.yaml'


  function bindBounds(app, refId, storageKey) {
    app[storageKey][refId] = app.$refs[refId].getBoundingClientRect();
    window.addEventListener("resize", () => {
      if (app.$refs[refId]) {
        app[storageKey][refId] = app.$refs[refId].getBoundingClientRect();
      }
    });
  }

  export default {
    name: "StoryFFF",
    computed: {
      showFirstCover() {
        return this.scroll - this.offsetRight >= this.endPos;
      },
      startPos() {
        return this.offsetLeft;
      },
      endPos() {
        const offset = this.bounds.content ? (this.bounds.content.width - this.bounds.wrapper.width) : 0;
        return -offset - this.offsetRight;
      }
    },
    mounted() {
      bindBounds(this, 'wrapper', 'bounds');
      bindBounds(this, 'content', 'bounds');
    },
    data() {
      return {
        content,
        bounds: {},
        offsetLeft: window.innerWidth < 600 ? 250 : 400,
        offsetRight: 0
      }
    }
  }
</script>
