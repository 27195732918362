<template>

  <div class="film-story" ref="filmStoryWrapper">
    <div class="vector-bg">
      <div class="graphic">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -25 100 100" width="100%" height="100%">
          <defs>
            <linearGradient id="gradient1" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%"   stop-color="#F0BDC6"/>
              <stop offset="100%" stop-color="#BEE1EE"/>
            </linearGradient>
          </defs>
          <!--<rect x="0" y="0" width="100%" height="100%"/>-->
          <path ref="transitionLine1" d="M 5,24 L 75,45" style="stroke-linecap: round; stroke: url(#gradient1); stroke-width: 1.9px;" :style="{ 'stroke-width': (Math.max(0, 1000 - (meta.viewport.width - 40)) * 0.0045 + 1.5) + 'px' }" />
        </svg>

      </div>
      <div class="graphic">
        <svg viewBox="0 0 775 500" fill="none" xmlns="http://www.w3.org/2000/svg">
          <svg viewBox="0 0 775 500" width="90%" x="0%" y="40px" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <mask id="lineMask1">
                <path ref="transitionLine2" :style="{ 'stroke-width': (Math.max(0, 1000 - (meta.viewport.width - 40)) * 0.07 + 8) + 'px' }" style="stroke: #fff; stroke-width: 10px; stroke-linecap: round;" d="M750 68.5C750 169 579.4 187.3 421 194.5C223 203.5 25 217 25 324" />
              </mask>
            </defs>
            <path mask="url(#lineMask1)" d="M750 68.5C750 169 579.4 187.3 421 194.5C223 203.5 25 217 25 324" stroke="url(#paint0_linear)" :stroke-width="(Math.max(0, 1000 - (meta.viewport.width - 40)) * 0.07 + 8)" stroke-linecap="round" stroke-linejoin="round" :stroke-dasharray="new Array(2).fill(Math.max(0, 1000 - (meta.viewport.width - 40)) * 0.07 + 25).join(' ')"/>
            <defs>
              <linearGradient id="paint0_linear" x1="17" y1="353" x2="689.5" y2="140" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FAE191"/>
                <stop offset="1" stop-color="#F0BDC6"/>
              </linearGradient>
            </defs>
          </svg>
        </svg>

      </div>

      <div class="graphic graphic-extra-1" ref="graphicExtra1">
        <svg viewBox="0 0 1500 394" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="gradient3" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%"   stop-color="#F0BDC6"/>
              <stop offset="100%" stop-color="#BEE1EE"/>
            </linearGradient>
          </defs>
          <g style="transform: translate(1400px, 55px);">
            <circle cx="0" cy="0" r="40" style="stroke: url(#gradient3); fill: none; stroke-width: 12px; transform-origin: 0px 0px; transform: rotate(45deg);" />
          </g>

        </svg>
      </div>
      <div class="graphic graphic-extra-2" ref="graphicExtra2">
        <svg viewBox="0 0 1500 394" xmlns="http://www.w3.org/2000/svg">
          <svg width="125" height="125" x="40%" y="45%" viewBox="0 0 93 93" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient id="gradient4" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%"   stop-color="#FAE191"/>
                <stop offset="100%" stop-color="#F0BDC6"/>
              </linearGradient>
            </defs>
            <path ref="extraGraphicCircle" style="transform: rotate(-135deg); transform-origin: 46px 46px; fill: none; stroke: url(#gradient4); stroke-width: 5px;" d="M54.2061 21.5227C58.5904 19.1113 63.941 22.3503 63.8372 27.353L62.8137 76.6813C62.7099 81.6839 57.2295 84.6981 52.949 82.1069L10.7412 56.5564C6.4607 53.9652 6.59045 47.7119 10.9748 45.3005L54.2061 21.5227Z" />
          </svg>
        </svg>
      </div>
    </div>
    <div class="slots">
      <div class="slot">
        <div class="image">
          <div class="image-graphic" :style="{ transform: content.slots[0].meta.bgTransform }">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100%" height="100%">
              <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%"   stop-color="#BEE1EE"/>
                  <stop offset="100%" stop-color="#F0BDC6"/>
                </linearGradient>
              </defs>
              <rect ref="graphicLine1" rx="3" ry="3" x="1" y="1" width="97" height="97" style="stroke-dasharray: 388; stroke-dashoffset: 388; stroke-linecap: round; fill: none; stroke-width: 2px; stroke: url(#gradient);" />
            </svg>
          </div>
          <img :src="content.slots[0].image" class="image-original" :style="{ 'border-radius': content.slots[0].meta.radius }" alt="Image" ref="image1">
        </div>
        <div class="description" :style="{ padding: content.slots[0].meta.descriptionPadding }">
          <div class="title" ref="title1">
            <span class="semi">{{ content.title[0] }}</span>
            <span class="strong">{{ content.title[1] }}</span>
          </div>
          <div class="description-inner" ref="description1">
            <div class="wrapper">
              <span>
                {{ content.slots[0].description }}
              </span>
              <div class="actions">
                <router-link :to="content.slots[0].button.href">
                  <button class="btn">{{ content.slots[0].button.label }}</button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="slot">
        <div class="image">
          <div class="image-graphic" :style="{ transform: content.slots[1].meta.bgTransform }">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100%" height="100%">
              <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%"   stop-color="#BEE1EE"/>
                  <stop offset="100%" stop-color="#F0BDC6"/>
                </linearGradient>
              </defs>
              <circle ref="graphicLine2" cx="50" cy="50" r="45" style="stroke-linecap: round; fill: none; stroke: url(#gradient); stroke-width: 2px;" />
            </svg>

          </div>
          <img :src="content.slots[1].image" class="image-original" :style="{ 'border-radius': content.slots[1].meta.radius }" alt="Image" ref="image2">
        </div>
        <div class="description" :style="{ padding: content.slots[1].meta.descriptionPadding }" ref="description2">
          <div class="description-inner">
            <div class="wrapper">
              <span>
                {{ content.slots[1].description }}
              </span>
              <div class="actions">
                <router-link :to="content.slots[1].button.href">
                  <button class="btn">{{ content.slots[1].button.label }}</button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="slot">
        <div class="image image-mobile-smaller">
          <div class="image-graphic" :style="{ transform: content.slots[2].meta.bgTransform }">
            <svg width="100%" height="100%" viewBox="0 100 530 410" fill="none" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <linearGradient id="gradient6" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%"   stop-color="#F0BDC6"/>
                  <stop offset="100%" stop-color="#FAE191"/>
                </linearGradient>
              </defs>
              <path style="stroke: url(#gradient6); stroke-width: 8px; stroke-linecap: round;" ref="graphicLine3" d="M345.597 105.5C349.597 103.191 354.597 106.078 354.597 110.697L354.597 497.294C354.597 501.913 349.597 504.799 345.597 502.49L10.7936 309.191C6.79363 306.882 6.79364 301.109 10.7936 298.799L345.597 105.5Z" />

            </svg>
          </div>
          <img :src="content.slots[2].image" class="image-original" :style="{ 'border-radius': content.slots[2].meta.radius }" alt="Image" ref="image3">
        </div>
        <div class="description" :style="{ padding: content.slots[2].meta.descriptionPadding }" ref="description3">
          <div class="description-inner">
            <div class="wrapper">
              <span>
                {{ content.slots[2].description }}
              </span>
              <div class="actions">
                <router-link :to="content.slots[2].button.href">
                  <button class="btn">{{ content.slots[2].button.label }}</button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .title {
    font-size: 2em;
    position: relative;
    left: -30px;
    z-index: 3;
    line-height: 125%;
    font-family: 'Montserrat', sans-serif;
    padding: 20px 0 20px 40px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 0 0 0 15px;
    padding: 0;
    box-shadow: -40px 20px 0 10px #fff;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 850px) {
    .title {
      font-size: 1.8em;
    }
  }
  @media screen and (max-width: 800px) {
    .title {
      font-size: 1.7em;
    }
  }
  @media screen and (max-width: 500px) {
    .title {
      left: -5px !important;
      font-size: 2.5em;
    }
  }
  @media screen and (max-width: 400px) {
    .title {
      font-size: 2.2em;
    }
  }
  @media screen and (max-width: 350px) {
    .title {
      font-size: 2em;
    }
  }
  @media screen and (max-width: 315px) {
    .title {
      font-size: 1.8em;
    }
  }
  .title > span {
    display: block;
  }
  .title .semi {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 700;
  }
  .title .strong {
    color: #F094A2;
    font-weight: 800;
  }

  .film-story {
    max-width: 1000px;
    margin: auto;
    padding: 0 20px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
  }
  .film-story .vector-bg {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;

    display: grid;
    grid-template-columns: 150px 150px auto 150px 150px;
    grid-template-rows: repeat(6, 16.66%);
  }
  @media screen and (max-width: 600px) {
    .film-story .vector-bg {
      display: none;
    }
  }
  .film-story .vector-bg .graphic {
    grid-row-end: span 2;
    grid-column: 1 / span 1;
    height: 100%;
  }
  .film-story .vector-bg .graphic > svg {
    width: 100%;
    height: 100%;
  }
  .film-story .vector-bg .graphic:nth-child(1) {
    grid-row-start: 2;
    grid-column: 2 / span 2;

  }
  .film-story .vector-bg .graphic:nth-child(2) {
    grid-row-start: 4;
    grid-column: 2 / span 3;
  }
  .film-story .vector-bg .graphic.graphic-extra-1 {
    grid-row-start: 3;
    grid-row-end: span 1;
    grid-column: 2 / span 4;
    transform: translate(0, -50px);

  }
  .film-story .vector-bg .graphic.graphic-extra-2 {
    grid-row-start: 4;
    grid-row-end: span 1;
    grid-column: 1 / span 5;

  }

  .graphic-motion {
    animation: moveRandomly 1s infinite;
    position: relative;
    left: 20px;
  }
  @keyframes moveRandomly {
    0% {
      opacity: 0;
    }
    30% {
      transform: translate(100px, 5px);
    }
    60% {
      transform: translate(15px, -5px);
    }
    100% {
      filter: blur(0px);
      transform: translate(4px, -2px);
      opacity: 1;
    }
  }


  .film-story .slots {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
  }
  .film-story .slot {
    display: flex;
    padding: 50px 0;
    box-sizing: border-box;
  }
  @media screen and (max-width: 600px) {
    .film-story .slot {
      padding: 10px 0;
    }
  }
  .film-story .slot .image {
    flex: none;
    order: 1;

    width: 350px;
    height: 350px;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    margin: 20px auto;

  }
  @media screen and (max-width: 700px) {
    .film-story .slot .image {
      width: 280px;
      height: 280px;

    }
    .film-story .slot:nth-child(1) .image {
      transform: translateX(-16px);
    }
    .film-story .slot:nth-child(2) .image {
      transform: translateX(16px);
    }
  }
  @media screen and (max-width: 600px) {
    .film-story .slot .image {
      width: calc(50vw - 20px);
      height: calc(50vw - 20px);

    }
  }
  @media screen and (max-width: 500px) {
    .film-story .slot .image {
      margin: 40px auto;
      width: calc(100vw - 80px);
      height: calc(100vw - 80px);

    }
    .film-story .slot .image.image-mobile-smaller {
      transform: scale(0.8);
      transform-origin: left center;
    }
  }
  @media screen and (max-width: 350px) {
    .film-story .slot .image {
      margin: 40px auto;
      width: calc(100% - 40px);
      height: calc(100%- 40px);

    }
  }

  .film-story .slot .image > * {
    width: 100%;
    height: 100%;
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;

  }
  .film-story .slot .image .image-graphic {
    z-index: 1;
  }
  .film-story .slot .image .image-original {
    z-index: 2;
  }
  .film-story .slot .description {
    flex: 1;
    order: 2;
    font-size: 1.3em;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;


  }
  .film-story .slot:nth-child(even) .description .description-inner {
    padding: 10px 40px 10px 0;
  }
  .film-story .slot:nth-child(odd) .description .description-inner {
    padding: 10px 0px 10px 40px;
  }

  @media screen and (max-width: 800px) {
    .film-story .slot .description {
      font-size: 1.2em;
    }
  }
  @media screen and (max-width: 700px) {
    .film-story .slot .description {
      font-size: 1.2em;
    }
  }
  @media screen and (max-width: 600px) {
    .film-story .slot .description {
      font-size: 1.1em;
    }
  }
  @media screen and (max-width: 500px) {
    .film-story .slot {
      display: block;
    }
    .film-story .slot .description {
      padding: 0 5px !important;

    }
    .film-story .slot .description .description-inner {
      text-align: left;
    }


  }
  @media screen and (max-width: 350px) {
    .film-story .slot .description {
      font-size: 1em;
    }
  }
  .film-story .slot .description .description-inner:before {
    display: inline-block;
    content: " ";
    vertical-align: middle;
    width: 0;
    height: 100%;
  }
  .film-story .slot .description .title {
    flex: none;
  }
  .film-story .slot .description .description-inner {
    display: inline-block;
    vertical-align: middle;
    word-wrap: break-word;
    box-sizing: border-box;
    max-width: 100%;
    flex: 1;
  }
  .film-story .slot .description .description-inner .actions {
    margin: 20px 0 0;
  }
  .film-story .slot .description .description-inner .btn {
    outline: none;
    padding: 10px 40px;
    font-size: 1.2em;
    font-weight: 800;
    letter-spacing: 1px;
    cursor: pointer;
    color: #fff;
    font-family: 'Montserrat';

    border-radius: 15px;
    border: none;
    background-image: linear-gradient(to right, #F07186, #58C8ED);
    transform: scale(1);
    transition: all 0.25s;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0);
  }
  .film-story .slot .description .description-inner .btn:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  @media screen and (max-width: 500px) {
    .film-story .slot .description .description-inner .btn {
      width: 100%;
    }
  }
  .film-story .slot .description .description-inner .wrapper {
    display: inline-block;
    vertical-align: middle;
  }
  @media screen and (max-width: 500px) {
    .film-story .slot .description:nth-child(1n) .description-inner {
      padding: 10px;
    }
  }
  .film-story .slot .description .description-inner > span {
    word-wrap: break-word;
    display: block;
  }
  .film-story .slot:nth-child(even) .image {
    order: 3;
  }
  .film-story .slot:nth-child(even) .description {
    order: 2;
  }
</style>

<script>
  import anime from 'animejs'
  import TimelineScrolling from '../../helpers/timelineScrolling.js'
  import AnimateOnScroll from '../../helpers/animateOnScroll.js'

  import content from '@/../content/create.yaml'


  export default {
    name: "FilmStory",

    created() {
      window.addEventListener("resize", () => {
        this.meta.viewport = {
          width: window.innerWidth,
          height: window.innerHeigt
        }
      });
    },
    mounted() {

      AnimateOnScroll; TimelineScrolling;

      const timeline1 = anime.timeline({
        autoplay: false,
        loop: false
      }).add({
        targets: this.$refs.graphicLine1,
        'stroke-dashoffset': 0,
        duration: 250,
        easing: 'linear'
      }).add({
        targets: this.$refs.image1,
        opacity: [0, 1],
        scale: [0, 1],
        duration: 500,
        easing: 'spring'
      }, '-=250').add({
        targets: this.$refs.title1,
        opacity: [0, 1],
        translateX: ['-50%', 0],
        duration: 3000,
        easing: 'spring'
      }, '-=1000').add({
        targets: this.$refs.description1,
        opacity: [0, 1],
        translateX: ['50%', 0],
        duration: 5000,
        easing: 'spring'
      }, '-=1000');

      const timeline2 = anime.timeline({
        autoplay: false,
        loop: false
      }).add({
        targets: this.$refs.transitionLine1,
        'stroke-dashoffset': [300, 0],
        'stroke-dasharray': [300, 300],
        duration: 1000,
        easing: 'linear'
      }).add({
        targets: this.$refs.graphicLine2,
        'stroke-dashoffset': [283, 0],
        'stroke-dasharray': [283, 283],
        duration: 500,
        easing: 'linear'
      }, '-=1200').add({
        targets: this.$refs.image2,
        opacity: [0, 1],
        scale: [0, 1],
        duration: 500,
        easing: 'spring'
      }, '-=250').add({
        targets: this.$refs.graphicExtra1,
        opacity: [0, 1],
        translateX: ['50%', 0],
        duration: 250,
        easing: 'spring'
      }, '-=1000').add({
        targets: this.$refs.description2,
        opacity: [0, 1],
        translateX: ['-50%', 0],
        duration: 500,
        easing: 'spring'
      }, '-=1000');
      const timeline3 = anime.timeline({
        autoplay: false,
        loop: false
      }).add({
        targets: this.$refs.transitionLine2,
        'stroke-dashoffset': [840, 0],
        'stroke-dasharray': [840, 840],
        duration: 500,
        easing: 'linear'
      }).add({
        targets: this.$refs.graphicLine3,
        'stroke-dashoffset': [1200, 0],
        'stroke-dasharray': [1200, 1200],
        duration: 500,
        easing: 'linear'
      }, '-=50').add({
        targets: this.$refs.image3,
        opacity: [0, 1],
        scale: [0, 1],
        duration: 500,
        easing: 'spring'
      }, '-=250').add({
        targets: this.$refs.graphicExtra2,
        opacity: [0, 1],
        translateX: ['-50%', 0],
        duration: 250,
        easing: 'spring'
      }, '-=750').add({
        targets: this.$refs.description3,
        opacity: [0, 1],
        translateX: ['-50%', 0],
        duration: 350,
        easing: 'spring'
      }, '-=1500');

      /*if (this.meta.viewport.width > 600) {
        new AnimateOnScroll(timeline1, this.$refs.graphicLine1, 200, false);
        new AnimateOnScroll(timeline2, this.$refs.image2, 200, false);
        new AnimateOnScroll(timeline3, this.$refs.image3, 200, false);
      }
      else {
        timeline1.seek(Infinity);
        timeline2.seek(Infinity);
        timeline3.seek(Infinity);

      }*/

      timeline1.seek(Infinity);
      timeline2.seek(Infinity);
      timeline3.seek(Infinity);

    },
    data() {
      return {
        content: content['story'],
        meta: {
          viewport: {
            width: window.innerWidth,
            height: window.innerHeigt
          }
        }
      }
    },
    watch: {
      $route() {
        const scroller = {
          y: window.scrollY
        }
        anime({
          targets: scroller,
          y: 0,
          duration: 1000,
          easing: 'easeInOutQuad',
          update() {
            window.scrollTo(0, scroller.y);
          }
        });
      }
    }
  }
</script>
