<template>
  <div class="story" :style="{ 'background-image': `url('${ toDataURI(background.svg, 'image/svg+xml') }')`, 'background-position': background.position, 'background-size': background.size }">
    <div class="header">
      <div class="title">
        <span v-for="(line, _i) in content.title" :key="_i">{{ line }}</span>
      </div>
      <div class="underliner" />
    </div>
    <div class="content">
      <div class="story-body">
        <div class="grid-1">
          <div class="flexible-image image-2" :style="{ 'background-image': `url('${ content.images[0] }')` }" />
        </div>
        <div class="grid-2">
          <div class="flexible-image image-1" :style="{ 'background-image': `url('${ content.images[1] }')` }" />
        </div>
        <div class="grid-3">
          <article v-html="content.description"></article>
          <div class="btn-container">
            <router-link :to="content.button.href">
              <button class="btn story-btn" style="transform: translateY(-10px);">
                {{ content.button.label }}
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .story {
    padding: 100px 0;
    background-repeat: no-repeat;
  }
  .story .header {

  }
  .story .underliner {
    height: 50px;
    background-image: linear-gradient(to left, #1FA19E, #BEE1EE);
    float: right;
    border-radius: 15px 0 0 15px;
    width: calc((100% - 1000px) / 2 + 400px);
    transform: translateY(-28px);
    z-index: 0;
    position: relative;
  }
  @media screen and (max-width: 1000px) {
    .story .underliner {
      width: calc(400px);
      max-width: calc(100% - 50px);

    }
  }
  .story .header .title {
    max-width: 1000px;
    margin: auto;
    padding: 0 50px 0 0;
    text-align: right;
    z-index: 1;
    position: relative;
    font-weight: 900;
    color: #4D4D4D;
    font-size: 3.5em;

  }

  @media screen and (max-width: 450px) {
    .story .header .title {
      font-size: 3.2em;
      padding-right: 30px;
    }
  }
  @media screen and (max-width: 350px) {
    .story .header .title {
      font-size: 2.3em;
      padding-right: 20px;
    }
  }
  .story .header .title > span {
    display: block;
  }

</style>


<style scoped>
  .story {

  }
  .story .content {
    width: 100%;
  }
  .story .story-body {
    width: 100%;
    padding: 0 20px;
    max-width: 1000px;
    margin: auto;
    display: grid;
    box-sizing: border-box;
    grid-template-columns: [left] auto [right] 250px;
    grid-auto-rows: minmax(min-content, max-content);
  }
  .story .story-body .grid-1 {
    grid-column: right / span 1;
    grid-row: 1 / span 2;
  }
  .story .story-body .grid-2 {
    padding: 0 50px 0 0;
  }
  .story .story-body .grid-3 {
    padding: 60px 60px 0 0;
    font-size: 1.2em;
    font-weight: 300;
    letter-spacing: 0.015em;
    line-height: 130%;
  }
  @media screen and (max-width: 600px) {
    .story .story-body {
      grid-template-columns: [left] calc(100% - 200px) [right] 200px;
      grid-template-rows: [top] 500px [bottom] auto !important;
    }
    .story .story-body .grid-1 {
      grid-column: right / span 1;
      grid-row: 1 / span 1;
    }
    .story .story-body .grid-3 {
      grid-column: left / span 2;
      grid-row: 2 / span 1;

      padding: 70px 40px 20px;

    }
    .story .story-body .grid-2 {
      padding-right: 35px;
    }
  }
  @media screen and (max-width: 600px) {
    .story .story-body {
      grid-template-columns: [left] calc(100% - 200px) [right] 200px;
      grid-template-rows: [top] 500px [bottom] auto !important;
      box-sizing: border-box;
    }
    .story .story-body .grid-1 {
      grid-column: right / span 1;
      grid-row: 1 / span 1;
    }
    .story .story-body .grid-3 {
      grid-column: left / span 2;
      grid-row: 2 / span 1;

    }
  }
  @media screen and (max-width: 475px) {
    .story .story-body {
      grid-template-columns: [left] calc(100% - 170px) [right] 170px;
      grid-template-rows: [top] 500px [bottom] auto !important;
      box-sizing: border-box;
    }
    .story .story-body .grid-1 {
      grid-column: right / span 1;
      grid-row: 1 / span 1;
    }
    .story .story-body .grid-3 {
      grid-column: left / span 2;
      grid-row: 2 / span 1;
      padding: 70px 15px 20px;
    }
  }


  .flexible-image {
    background-size: cover;
    display: block;

  }
  .flexible-image.image-1 {
    width: 250px;
    height: 400px;
    background-position: center;
    float: right;
  }
  @media screen and (max-width: 600px) {
    .flexible-image.image-1 {
      max-width: 100%;
    }
  }
  @media screen and (max-width: 500px) {
    .flexible-image.image-1 {
      width: 200px;
      height: 320px;
    }
  }
  .flexible-image.image-2 {
    width: 100%;
    height: 100%;
    display: inline-block;
    background-position: center;
  }
  .btn-container {
    text-align: right;
    margin: 80px 0 0 0;
  }
  .btn-container a {
    display: block;
    text-decoration: inherit;
  }
  @media screen and (max-width: 600px) {
    .btn-container {
      text-align: center;
      margin: 30px 0 0 0;
    }
  }
  .btn {
    outline: none;
    font-size: 1.6rem;
    color: #fff;
    background-image: linear-gradient(to left, #58C8ED, #1FA19E);
    border: none;
    border-radius: 13px;
    padding: 15px 0;
    text-align: center;
    min-width: 270px;
    font-family: 'Montserrat';
    font-weight: 800;
    display: inline-block;
    cursor: pointer;
    display: block;

  }
  .btn {
    transition: all 0.3s;
    transform: scale(1);
  }
  .btn:hover {
    transform: scale(1.05);
  }
  @media screen and (max-width: 400px) {
    .btn {
      min-width: 100%;
    }
  }
  .btn.story-btn {
    transform: translateY(30px);
  }
</style>

<script>
  import contentSite from '@/../content/work.yaml'
  const content = contentSite["story-2"];


  export default {
    name: "StoryMoviesPreview",
    props: {
      background: Object
    },
    methods: {
      toDataURI(str, mime) {
        return `data:${ mime };base64,${ btoa(str) }`;
      }
    },
    data() {
      return {
        content
      }
    }
  }
</script>
